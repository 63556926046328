import {
  ArrowSmallLeftIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { updateFinalCalculation } from 'apiClient/rangeBuilder/rangeBuilder';
import Tabs from 'components/molecules/tabs/Tabs';
import BenchmarksTable from 'pages/rangeBuilder/internalRange/internalRangePopUp/BenchmarksTable';
import { Details } from 'pages/rangeBuilder/internalRange/internalRangePopUp/Details';
import { setToastMessage } from 'pages/recognition/redemption/helper';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Employees } from './Employees';
import { VersionHistory } from './VersionHistory';

export const FinalRangePopUp = ({
  setViewDetails,
  defaultTabSelected,
  setDefaultTabSelected,
  pivotedColumns,
  pivotedData,
  detailsTableColumns,
  detailsTableValues,
  versionHistory,
  benchmarkColumns,
  activePopUpId,
  finalRangeData,
  internalRangeCalcId,
}) => {
  const [selectedTab, setSelectedTab] = useState(
    defaultTabSelected ? defaultTabSelected : 'Details',
  );
  const [isEditable, setIsEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [updatedFinalCalc, setUpdatedFinalCalc] = useState({
    planId: id,
    internalCalcId: internalRangeCalcId,
  });
  const [benchmarkTableData, setBenchmarkTableData] = useState([]);
  const [totalWeight, setTotalWeight] = useState();

  useEffect(() => {
    let weight = 0;
    benchmarkTableData?.map((data) => {
      weight = weight + data?.weightage;
    });
    setTotalWeight(weight);
  }, [benchmarkTableData]);

  const tabs = [
    {
      id: '1',
      tabLabel: 'Details',
    },
    {
      id: '2',
      tabLabel: 'Employees',
    },
    {
      id: '3',
      tabLabel: 'Benchmarks',
    },
    {
      id: '4',
      tabLabel: 'Version History',
    },
  ];

  const handleUpdateCalculation = async () => {
    try {
      setLoading(true);
      await updateFinalCalculation(updatedFinalCalc);
      setViewDetails(false);
      setLoading(false);
      setToastMessage('Benchmark updated successfully');
    } catch (error) {
      console.error(error);
      setLoading(false);
      setToastMessage('Something wen wrong!');
    }
  };

  return (
    <div className="fixed z-[53] inset-0 bg-backdrop overflow-y-auto w-full">
      <div
        className={`absolute top-14 right-0 w-3/5 bg-white min-h-screen overflow-y-auto custom-scrollbar`}
      >
        <div className="flex items-center bg-white justify-between w-full gap-4 shadow-md border-b-2 p-2 px-4 fixed top-[54px]">
          <div className="flex flex-row items-center gap-3 justify-start">
            <div
              className="p-2 rounded-lg border-2 cursor-pointer"
              onClick={() => {
                setViewDetails(false);
                setDefaultTabSelected && setDefaultTabSelected('');
              }}
            >
              <ArrowSmallLeftIcon className="w-4 h-4" />
            </div>
            <div className=" flex items-center justify center text-xl font-semibold">
              Pay Range Details
            </div>
          </div>
        </div>
        <div className="p-6 mt-[54px] flex items-center justify-between w-full">
          <p className="font-semibold text-gray-900 w-full">{`${
            pivotedData?.[pivotedColumns?.indexOf('Pay Range Code')]
          } | ${pivotedData?.[pivotedColumns?.indexOf('Pay Range Title')]}`}</p>
          {isEditable && selectedTab === 'Benchmarks' ? (
            <div className="flex itesm-center gap-5">
              <button
                className="text-sm font-semibold text-gray-600"
                onClick={() => setViewDetails(false)}
              >
                Cancel
              </button>
              <button
                className={`text-sm font-semibold text-transparent bg-gradient bg-clip-text ${
                  (loading || totalWeight !== 100) && 'cursor-not-allowed'
                }`}
                disabled={loading || totalWeight !== 100}
                onClick={handleUpdateCalculation}
              >
                {loading ? 'Saving...' : 'Save'}
              </button>
            </div>
          ) : (
            selectedTab === 'Benchmarks' && (
              <PencilSquareIcon
                onClick={() => setIsEditable(!isEditable)}
                className="w-6 h-6 text-gray-500 cursor-pointer"
              />
            )
          )}
        </div>

        <div className="space-y-5 px-6">
          <Tabs
            tabs={tabs}
            selectedTab={selectedTab}
            handleTab={(e) => setSelectedTab(e.tabLabel)}
          />
          {selectedTab === 'Details' && (
            <Details
              pivotedColumns={pivotedColumns}
              pivotedData={pivotedData}
              detailsTableColumns={detailsTableColumns}
              detailsTableValues={detailsTableValues}
              benchmarkColumns={benchmarkColumns}
            />
          )}
          {selectedTab === 'Employees' && (
            <Employees
              activePopUpId={activePopUpId}
              benchmarkColumns={benchmarkColumns}
              employeeCount={pivotedData?.[pivotedData?.length - 1]}
              graphValue={detailsTableValues?.map((value) =>
                value?.slice(1, value?.length),
              )}
              finalRangeData={finalRangeData}
            />
          )}
          {selectedTab === 'Version History' && (
            <VersionHistory versionHistory={versionHistory} />
          )}
          {selectedTab === 'Benchmarks' && (
            <BenchmarksTable
              rowId={activePopUpId}
              setIsEditable={setIsEditable}
              isEditable={isEditable}
              internalRangeCalcId={internalRangeCalcId}
              updatedFinalCalc={updatedFinalCalc}
              setUpdatedFinalCalc={setUpdatedFinalCalc}
              benchmarkTableData={benchmarkTableData}
              setBenchmarkTableData={setBenchmarkTableData}
            />
          )}
        </div>
      </div>
    </div>
  );
};
