import MoveIcon from 'assets/svg/moveIcon';
import { CheckBoxProgram } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import { useState } from 'react';

export default function DashboardChartSorting() {
  const [sortOptionOpen, setSortOptionOpen] = useState(false);

  const options = [
    { value: 'asc', label: 'Ascending' },
    { value: 'desc', label: 'Descending' },
  ];

  return (
    <div>
      <div className="border border-gray-200 rounded-md p-3 bg-gray-25 flex flex-col gap-3">
        <div
          className="flex justify-between items-center"
          onClick={() => setSortOptionOpen(!sortOptionOpen)}
        >
          <div className="flex gap-2">
            <span className="text-primaryActive text-lg font-medium">01</span>
            <span className="text-gray-600 text-lg font-medium">Quantity</span>
          </div>
          <div>
            <MoveIcon />
          </div>
        </div>
        {sortOptionOpen && (
          <div className="flex flex-col justify-between">
            <div className="flex gap-2 items-center pt-2 pb-3">
              <CheckBoxProgram checked={true} />
              <span className="text-gray-900 font-medium text-xs">
                Sort Numerically
              </span>
            </div>
            <div>
              <Select options={options} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
