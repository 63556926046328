import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Colors,
  Filler,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';
import {
  Bar,
  Bubble,
  Doughnut,
  Line,
  Pie,
  PolarArea,
  Radar,
  Scatter,
} from 'react-chartjs-2';

const Chart = ({
  type,
  data,
  chartOptions = {},
  pluginOptions = {},
  chartId,
  displayLegend = false,
  disableTooltip = false,
  plugins,
}) => {
  ChartJS?.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Tooltip,
    ArcElement,
    Filler,
    Colors,
    Legend,
  );

  const options = {
    plugins: {
      legend: {
        display: displayLegend,
      },
      tooltip: {
        mode: 'index',
        // intersect: false,
        enabled: !disableTooltip,
      },
      ...pluginOptions,
    },
    ...chartOptions,
    scales: {
      ...chartOptions?.scales,
    },
  };

  const ChartComponent =
    type === 'line'
      ? Line
      : type === 'doughnut'
      ? Doughnut
      : type === 'pie'
      ? Pie
      : type === 'polarArea'
      ? PolarArea
      : type === 'radar'
      ? Radar
      : type === 'bubble'
      ? Bubble
      : type === 'scatter'
      ? Scatter
      : Bar;

  return (
    <ChartComponent
      id={chartId}
      data={data}
      options={options}
      plugins={plugins}
    />
  );
};

export default Chart;
