import UserNameAvatar from 'components/atoms/userNameAvatar/UserNameAvatar';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { imageURLCheck } from 'utils/ImageUrlCheck';
/**
 * Component to display user data including avatar, full name, job title, department, and business email.
 * @param {Object} props - Component props
 * @param {Object} props.userData - User data object containing avatar, full name, job title, department, and business email
 * @return {JSX.Element} UserData component
 */
const UserData = ({ userData, isClickable = false }) => {
  const [imageError, setImageError] = useState(false);
  const navigate = useNavigate();
  return (
    <div
      className={`flex flex-row items-center flex-grow ${
        isClickable ? 'cursor-pointer' : ''
      }`}
      onClick={() => {
        if (isClickable) {
          navigate(`/profile/${userData?.userId?.value}`);
        }
      }}
    >
      <div className="flex flex-col items-center justify-center w-8 h-8">
        {!imageError && (
          <img
            src={
              imageURLCheck(userData?.avatar?.value)
              // : "https://pixlok.com/wp-content/uploads/2022/02/Profile-Icon-SVG-09856789.png"
            }
            // onError={(e) => {
            //   e.target.src = "https://cdn-icons-png.flaticon.com/512/8004/8004581.png";
            // }}
            alt="user"
            className="border-2 rounded-full w-7 h-7"
            onError={() => setImageError(true)}
          />
        )}
        {imageError && (
          <UserNameAvatar
            userName={userData?.fullName?.value}
            imageSize={32}
            className="border-2 rounded-full w-7 h-7"
          />
        )}
      </div>
      <div className="flex flex-col items-start p-0 pl-1">
        <div className="flex flex-row items-end font-semibold text-sm text-gray-600 w-full p-0 ">
          <p>
            {userData?.fullName?.value?.length > 20 ? (
              <span>{userData?.fullName?.value?.slice(0, 20)}...</span>
            ) : (
              <span>{userData?.fullName?.value}</span>
            )}
            <span id="react-select-hide">
              {userData?.jobTitle?.sourceValue?.value &&
                userData?.fullName?.value &&
                ' • '}
              {userData?.jobTitle?.sourceValue?.value?.length > 20 ? (
                <span>
                  {userData?.jobTitle?.sourceValue?.value?.slice(0, 20)}
                  ...
                </span>
              ) : (
                <span>{userData?.jobTitle?.sourceValue?.value}</span>
              )}
            </span>
          </p>
        </div>
        <div
          className="flex flex-row items-end text-xs text-gray-600 w-full p-0"
          id="react-select-hide"
        >
          {userData?.department?.sourceValue?.value?.length > 20 ? (
            <span>
              {userData?.department?.sourceValue?.value?.slice(0, 20)}...
            </span>
          ) : (
            <span>{userData?.department?.sourceValue?.value}</span>
          )}
          {userData?.businessEmail?.value &&
            userData?.department?.sourceValue?.value && (
              <span className="px-1">|</span>
            )}
          {userData?.businessEmail?.value?.length > 35 ? (
            <span> {userData?.businessEmail?.value?.slice(0, 35)}...</span>
          ) : (
            <span> {userData?.businessEmail?.value}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserData;
