import { ArrowDownTrayIcon, XCircleIcon } from '@heroicons/react/24/outline';
import CircularLoaderSmall from 'assets/svg/CircularLoaderSmall';
import DeleteFormIcon from 'assets/svg/deleteFormIcon';
import TableIcon from 'assets/svg/tableIcon';
import TickIcon from 'assets/svg/tickIcon';
import Button from 'components/atoms/button/Button';
import ProgressBar from 'components/atoms/progressBar/ProgressBarLoader';
import Timeline from 'components/atoms/timeline/Timeline';
import ModalPopup from 'components/layout/modalPopup/ModalPopup';
import FileUploader from 'pages/policy/create/mainPanel/policySteps/FileUploader';
import { useEffect, useState } from 'react';
import { downloadDataToCsv } from 'utils/downloadDataToCsv';
import { onResponse } from 'utils/toastMessages';

export default function FileUploadDownloadPopup({
  setPopupVisible,
  title,
  description,
  downloadTemplateApi,
  downloadFileTemplateName,
  uploadApi,
  onUploadSuccess,
  downloadReport,
  showWithoutPopup,
  uploadButtonLabel = 'Upload  & Refresh Data',
  uploadingButtonLabel = 'Uploading...',
  refreshData,
}) {
  const [data, setData] = useState({
    itemStyle: 'dot',
    bulletDefaultColor: '#D0D5DD',
    bulletActiveColor: 'var(--primaryRight)',
    barColor: '#D0D5DD',
    itemList: [
      {
        id: '1',
        isBulletActive: false,
        title: 'Download Template ',
      },
      {
        id: '2',
        isBulletActive: false,
        title: 'Upload the CSV file for column type upload ',
      },
      {
        id: '3',
        isBulletActive: false,
        title: 'Validating Uploaded File',
      },
    ],
  });
  const [file, setFile] = useState(null);
  const [percentage, setPercentage] = useState(0);
  const [showRefreshButton, setShowRefreshButton] = useState(false);
  const [isDownloading, setIsDownloading] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const [validation, setValidation] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  useEffect(() => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      const totalSize = file.size;
      let uploadedSize = 0;

      const uploadInterval = setInterval(() => {
        if (uploadedSize < totalSize) {
          uploadedSize += 1024;
          const percentage =
            (uploadedSize / totalSize) * 100 > 100
              ? 100
              : (uploadedSize / totalSize).toFixed(2) * 100;
          setPercentage(percentage);
        } else {
          clearInterval(uploadInterval);
        }
      }, 100);
      const updatedItemStatus = data.itemList.map((item) => {
        if (item?.id === '2' || item?.id === '1') {
          return { ...item, isBulletActive: true };
        } else return item;
      });

      setData({ ...data, itemList: updatedItemStatus });
    } else {
      const updatedItemStatus = data.itemList.map((item) => {
        if (item?.id === '2' || item?.id === '3') {
          return { ...item, isBulletActive: false };
        } else return item;
      });

      setData({ ...data, itemList: updatedItemStatus });
    }
  }, [file]);

  useEffect(() => {
    if (percentage === 100) {
      const interval = setInterval(() => {
        clearInterval(interval);
      }, 1000);
    }
  }, [percentage]);
  const onClickDownloadTemplate = () => {
    setIsDownloading({
      isLoading: true,
      data: null,
      error: null,
    });
    downloadTemplateApi()
      .then((response) => {
        downloadDataToCsv(response, downloadFileTemplateName);
        setIsDownloading({
          isLoading: false,
          data: response,
          error: null,
        });
        const updatedItemStatus = data.itemList.map((item) => {
          if (item?.id === '1') {
            return { ...item, isBulletActive: true };
          } else return item;
        });

        setData({ ...data, itemList: updatedItemStatus });
      })
      .catch((error) => {
        setIsDownloading({
          isLoading: false,
          data: null,
          error: true,
        });
        var message;
        if (error?.response?.data?.message) {
          message = error.response?.data?.message;
        } else if (error?.message) {
          message = error.message;
        } else
          message =
            "Oops! Something went wrong. Couldn't download the template.";

        onResponse(message);
      });
  };
  const DownloadTemplateUI = ({ key }) => {
    return (
      <div key={key}>
        <div className="h-auto py-4">
          <Button
            onClick={() => {
              if (!isDownloading?.isLoading) {
                onClickDownloadTemplate();
              }
            }}
            className=" !py-1 !px-2 text-sm !rounded-md "
          >
            {isDownloading?.isLoading ? (
              <span className="flex gap-2 items-center ">
                <CircularLoaderSmall />
                <p>Downloading Template</p>
              </span>
            ) : (
              <div className="flex gap-2 items-center ">
                <ArrowDownTrayIcon className="w-4 h-4 " />{' '}
                <p>Download Template</p>
              </div>
            )}
          </Button>
        </div>
      </div>
    );
  };
  function renderFileUpload() {
    return (
      <div className="py-4">
        {file ? (
          <div>
            <div className="flex p-4 gap-4 rounded-lg gradient-border w-full h-full">
              <div className="w-8 h-8 rounded-full bg-gradient flex items-center justify-center p-2">
                <TableIcon />
              </div>
              <div className="w-full">
                <div className="flex items-center justify-between w-full">
                  <p className="font-medium text-sm text-gray-700">
                    {file?.name}
                  </p>

                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setFile(null);
                      setShowRefreshButton(false);
                      setPercentage(0);
                      setValidation({
                        isLoading: false,
                        data: null,
                        error: null,
                      });
                    }}
                  >
                    <DeleteFormIcon />
                  </div>
                </div>
                <p className="text-sm text-gray-600">{`${
                  file?.size < 1024
                    ? `${file?.size} Bytes`
                    : file?.size < 1024 * 1024
                    ? `${(file?.size / 1024).toFixed(2)} KB`
                    : `${(file?.size / (1024 * 1024)).toFixed(2)} MB`
                }`}</p>
                <div className="flex items-center justify-between w-full gap-3">
                  <div className="w-full">
                    <ProgressBar percentage={percentage?.toFixed(0)} />
                  </div>
                  <p className="font-medium text-sm text-gray-700">{`${percentage}%`}</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <FileUploader
            acceptedTypes={['.csv']}
            maxSize={50 * 1024 * 1024}
            subText="Upload CSV file (max. 6000 rows)"
            onFileSelect={(file) => setFile(file)}
          />
        )}
      </div>
    );
  }

  const validatingFile = () => {
    if (validation?.isLoading) {
      return (
        <div className="flex gap-2 items-center">
          <CircularLoaderSmall />
          Validating your File
        </div>
      );
    } else if (validation?.error) {
      return (
        <div className="max-h-40 overflow-y-auto">
          {downloadReport ? (
            <div>
              <p className="flex items-center gap-2">
                <XCircleIcon className="w-4 h-4 font-semibold text-red-500 " />
                Oops your uploaded file has some errors, please download report
                to see details.
              </p>
              <Button
                onClick={() => {
                  setShowRefreshButton(true);
                  const errorString = String(validation.error);
                  const cleanedError = errorString.replace(/^Error:\s*/, '');

                  downloadDataToCsv(cleanedError, 'Data_Upload_Report');
                  // onUploadSuccess();
                }}
                className=" !py-1 !px-2 text-sm !rounded-md "
              >
                <div className="flex gap-2 items-center ">
                  <ArrowDownTrayIcon className="w-4 h-4 " />{' '}
                  <p>Download Report</p>
                </div>
              </Button>
            </div>
          ) : (
            <div>
              {validation?.error &&
              validation?.error?.includes('Headers are not matching') ? (
                <p>File headers are not matching.</p>
              ) : validation?.error?.includes('empty') ? (
                <p>Upload file is empty</p>
              ) : validation?.error?.includes(
                  'EmployeeId values not matching',
                ) ? (
                <p>
                  Employee Id(s) are not matching as per eligible user in the
                  plan.{' '}
                </p>
              ) : (
                <div>
                  {validation?.error
                    ?.replace(/^Api error - /, '')
                    ?.split(/::|,|\./)
                    ?.map((part, index) => (
                      <p className="text-sm" key={index}>
                        &bull;{part.trim()}
                      </p>
                    ))}
                </div>
              )}
            </div>
          )}
        </div>
      );
    } else if (validation?.data) {
      return (
        <div>
          <div className="flex gap-2 items-center">
            <div
              className={`w-4 h-4 rounded-full bg-lime-500 flex items-center justify-center`}
            >
              <TickIcon />
            </div>
            <p>Validation Completed</p>
          </div>
          <p className="font-semibold">Data Upload Successfully Completed!</p>
        </div>
      );
    }
  };

  const validateAndUploadData = () => {
    if (file && percentage === 100 && !validation?.isLoading) {
      setValidation({
        isLoading: true,
        data: null,
        error: null,
      });

      const formData = new FormData();
      formData.append('excel_file', file);

      const updatedItemStatus = data.itemList.map((item) => {
        if (item?.id === '3') {
          return { ...item, isBulletActive: true };
        } else return item;
      });

      setData({ ...data, itemList: updatedItemStatus });

      uploadApi(formData)
        .then((res) => {
          setData({ ...data, itemList: updatedItemStatus });
          setValidation({
            isLoading: false,
            data: res,
            error: null,
          });
          onUploadSuccess();
        })
        .catch((err) => {
          //   onResponse(err);
          setValidation({
            isLoading: false,
            data: null,
            error: downloadReport ? err : err?.message,
          });
        });
    }
  };

  const renderUI = () => {
    return (
      <div className="p-4">
        {description}
        <Timeline
          data={data}
          bodyData={[
            <DownloadTemplateUI key={'23'} />,
            renderFileUpload(),
            validatingFile(),
          ]}
        />
        <div className="h-0.5 w-full bg-gray-200 my-4 " />
        <div className="re bottom-0 w-full flex gap-4 justify-end  items-center mt-4">
          <div
            className="px-3 py-2  text-sm font-semibold hover:cursor-pointer"
            onClick={() => {
              document.body.classList.remove('overflow-y-hidden');
              setPopupVisible(false);
            }}
          >
            Cancel
          </div>
          {showRefreshButton ? (
            <Button
              onClick={() => {
                setPopupVisible();
                refreshData();
              }}
              type="filled"
            >
              Refresh to see updated Data
            </Button>
          ) : (
            <button
              onClick={() => validateAndUploadData()}
              type="submit"
              className={`${
                file && percentage === 100
                  ? '!bg-gradient'
                  : 'bg-gray-400 disabled hover:cursor-not-allowed'
              } px-3 py-2 text-white text-sm font-semibold rounded-lg hover:cursor-pointer`}
            >
              {validation?.isLoading
                ? `${uploadingButtonLabel}`
                : `${uploadButtonLabel}`}
            </button>
          )}
        </div>
      </div>
    );
  };
  if (showWithoutPopup) {
    return renderUI();
  }
  return (
    <ModalPopup
      title={title}
      height={800}
      isModalOpen={true}
      onClose={() => {
        setPopupVisible(false);
      }}
      body={renderUI()}
    />
  );
}
