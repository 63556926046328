import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import {
  fetchPlanById,
  getFinalRangeBenchmarks,
  getFinalRangeSources,
  getJobCode,
  updateFinalCalculation,
} from 'apiClient/rangeBuilder/rangeBuilder';
import DeleteFormIcon from 'assets/svg/deleteFormIcon';
import { Input } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import Table from 'components/atoms/table/Table';
import TableHeader from 'components/atoms/tableHeader/TableHeader';
import { setToastMessage } from 'pages/recognition/redemption/helper';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import RowSubComponent from './RowSubComponent';

const BenchmarksTable = ({
  rowId,
  setIsEditable,
  isEditable,
  internalRangeCalcId,
  updatedFinalCalc,
  setUpdatedFinalCalc,
  setBenchmarkTableData,
  benchmarkTableData,
}) => {
  const [benchmarkResponse, setBenchmarkResponse] = useState();
  const { id } = useParams();
  const [benchmarkOpenId, setBenchmarkOpenId] = useState();
  const [subComponentVisible, setSubComponentVisible] = useState(false);
  const [sources, setSources] = useState([]);
  const [selectedSourceId, setSelectedSourceId] = useState([]);
  const [jobCodes, setJobCodes] = useState([]);
  const regex = new RegExp(
    '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$',
  );
  const [loading, setDeleteLoading] = useState();
  const [outputMode, setOutputMode] = useState();
  const [selectedFilterTab, setSelectedFilterTab] = useState('Mid');
  const [calculations, setCalculations] = useState();

  const renderRowSubComponent = useCallback(
    ({ row }) => {
      return (
        <RowSubComponent
          row={row}
          isEditable={isEditable}
          rowId={rowId}
          setUpdatedFinalCalc={setUpdatedFinalCalc}
          updatedFinalCalc={updatedFinalCalc}
          outputMode={outputMode}
          selectedFilterTab={selectedFilterTab}
          setSelectedFilterTab={setSelectedFilterTab}
          calculations={calculations}
          setCalculations={setCalculations}
        />
      );
    },
    [isEditable, outputMode],
  );

  useEffect(() => {
    if (isEditable) {
      (async () => {
        try {
          const response = await getFinalRangeSources(id);
          const updatedResponse = response?.map((source) => ({
            ...source,
            label: source?.name,
            value: source?.id,
          }));
          setSources(updatedResponse);
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [isEditable]);

  useEffect(() => {
    if (selectedSourceId) {
      let codes = {};
      selectedSourceId?.map((sourceId) => {
        (async () => {
          try {
            const response = await getJobCode(id, sourceId);
            codes = {
              ...codes,
              [sourceId]: response?.map((jobCode) => ({
                ...jobCode,
                label: jobCode?.code,
                value: jobCode?.id,
              })),
            };
            setJobCodes({ ...codes });
          } catch (error) {
            console.error(error);
          }
        })();
      });
    }
  }, [selectedSourceId]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getFinalRangeBenchmarks(id, rowId);
        setBenchmarkResponse(response);
        let tableData = [];
        Object.entries(response || {}).map((entry) => {
          if (regex.test(entry[0])) {
            let rowData = [];
            rowData = {
              ...rowData,
              id: entry[0],
              dataSource: entry[1]?.uploadedJson?.['Benchmark Provider'],
              jobTitle:
                response?.['Job Details']?.uploadedJson?.['Pay Range Title'],
              jobCode: entry[1]?.uploadedJson?.['Mkt Match'],
              weightage: Number(entry[1]?.uploadedJson?.['Weightage']),
              adjustment: entry[1]?.uploadedJson?.['Adjustment'],
              pivotData: entry[1]?.externalData?.columns,
              selectedPvalueInBenchmark: response?.selectedPvalueInBenchmark,
              aging: response?.agingMultiplier.toFixed(2),
              labels: [
                'Position',
                ...Object.keys(
                  entry[1]?.externalData?.benchmarkPivotCalc?.[
                    Object?.keys(response?.benchmarkMap || {})?.[0]
                  ] || {},
                ),
              ],
              benchmarkMap: response?.benchmarkMap,
              pValueMap: response?.pValueMap,
            };
            setOutputMode(response?.plan?.calcModeOutput);
            Object.entries(response?.benchmarkMap || {}).map((benchmark) => {
              rowData = {
                ...rowData,
                [benchmark[0]]: {
                  value: entry[1]?.calculation?.[`Mid_${benchmark[0]}`],
                  point:
                    response?.pValueMap?.[
                      response?.selectedPvalueInBenchmark?.[benchmark[0]]?.Mid
                    ],
                },
              };
            });
            Object.entries(response?.benchmarkMap || {}).map((benchmark) => {
              rowData = {
                ...rowData,
                [`${benchmark[0]}_pValue`]: {
                  selectedPivot: true,
                  data: Object?.entries(
                    entry[1]?.externalData?.benchmarkPivotCalc?.[
                      benchmark[0]
                    ] || {},
                  ).map((pValue) => {
                    return {
                      value: pValue[1],
                      label: pValue[0],
                    };
                  }),
                },
              };
            });
            tableData = [...tableData, rowData];
          }
        });
        setSelectedSourceId(tableData?.map((data) => data?.id));
        setBenchmarkTableData(tableData);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const handleJobCode = (jobCode, id, dataSource) => {
    setUpdatedFinalCalc({
      ...updatedFinalCalc,
      jobMatchingData: {
        ...updatedFinalCalc?.jobMatchingData,
        updateData: {
          ...updatedFinalCalc?.jobMatchingData?.updateData,
          [id]: {
            ...updatedFinalCalc?.jobMatchingData?.updateData?.[id],
            'Mkt Match': jobCode,
            'Benchmark Provider': dataSource,
          },
        },
      },
    });
  };

  const handleWeightageBlur = (event, id) => {
    let checkWeight = 0;
    const newBenchmarkTableData = benchmarkTableData?.map((data) => {
      if (data?.id === id) {
        checkWeight = checkWeight + Number(event.target.value);
        return { ...data, weightage: Number(event.target.value) };
      } else {
        checkWeight = Number(checkWeight) + Number(data?.weightage);
        return data;
      }
    });
    setBenchmarkTableData(newBenchmarkTableData);
    setUpdatedFinalCalc({
      ...updatedFinalCalc,
      jobMatchingData: {
        ...updatedFinalCalc?.jobMatchingData,
        updateData: {
          ...updatedFinalCalc?.jobMatchingData?.updateData,
          [id]: {
            ...updatedFinalCalc?.jobMatchingData?.updateData?.[id],
            Weightage: event.target.value,
          },
        },
      },
    });

    if (checkWeight !== 100) {
      setToastMessage('Sum of Weight should be 100.');
    }
  };

  const handleDeleteBenchmark = async (benchmarkId) => {
    let weightData = {};
    benchmarkTableData?.map((data) => {
      weightData = {
        ...weightData,
        [data?.id]: {
          'Mkt Match': data?.jobCode,
          Weightage: data?.weightage,
        },
      };
    });
    setDeleteLoading(true);
    await updateFinalCalculation({
      planId: id,
      internalCalcId: internalRangeCalcId,
      jobMatchingData: {
        deleteSources: [benchmarkId],
        updateData: { ...weightData },
      },
    });
    setDeleteLoading(false);
  };

  const handleDataSourceChange = (rowId, option) => {
    setBenchmarkTableData(
      benchmarkTableData?.map((data) => {
        if (data?.id === rowId) {
          return { ...data, dataSource: option?.label };
        }
        return data;
      }),
    );
  };

  // useEffect(() => {
  //   benchmarkTableData?.map((data) => {
  //     setSelectedSourceId([...selectedSourceId, data?.id]);
  //   });
  // }, [benchmarkTableData]);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchPlanById(id);
        setCalculations(response?.data?.calculations);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const columns = [
    {
      id: '1',
      Header: (
        <TableHeader name="Data Source" HeaderKey="dataSource" sort={true} />
      ),
      Cell: ({ row }) => {
        const rowProps = row?.getToggleRowExpandedProps();
        return (
          <span className="flex items-center gap-1">
            {subComponentVisible && benchmarkOpenId === row.original?.id ? (
              <ChevronDownIcon
                className="w-4 h-4 text-gray-900 cursor-pointer"
                onClick={() => {
                  rowProps.onClick();
                  setBenchmarkOpenId();
                  setSubComponentVisible(false);
                }}
              />
            ) : (
              <ChevronRightIcon
                className="w-4 h-4 text-gray-900 cursor-pointer"
                onClick={() => {
                  rowProps.onClick();
                  setBenchmarkOpenId(row.original?.id);
                  setSubComponentVisible(true);
                }}
              />
            )}
            {isEditable && (
              <Select
                options={sources}
                defaultValue={sources?.filter(
                  (source) => source?.label === row.original.dataSource,
                )}
                onChange={(option) =>
                  handleDataSourceChange(row?.original?.id, option)
                }
              />
            )}
            {!isEditable && (
              <p className="text-gray-900 text-sm font-medium">
                {row.original.dataSource || 'N/A'}
              </p>
            )}
          </span>
        );
      },
    },
    {
      id: '2',
      Header: (
        <TableHeader name="Job Details" HeaderKey="jobDetails" sort={true} />
      ),
      Cell: ({ row }) => (
        <div className="flex flex-col">
          {/* <p className="text-gray-900 text-sm">
            {row.original.jobTitle || 'N/A'}
          </p> */}
          {!isEditable ? (
            <p className="text-gray-600 text-sm">
              {row.original.jobCode || 'N/A'}
            </p>
          ) : (
            <Select
              defaultValue={jobCodes?.[row.original?.id]?.filter((jobCode) =>
                updatedFinalCalc?.jobMatchingData?.updateData?.[
                  row?.original?.id
                ]?.['Mkt Match']
                  ? jobCode?.label ===
                    updatedFinalCalc?.jobMatchingData?.updateData?.[
                      row?.original?.id
                    ]?.['Mkt Match']
                  : jobCode?.label === row.original?.jobCode,
              )}
              onChange={(option) =>
                handleJobCode(
                  option?.label,
                  row.original?.id,
                  row?.original?.dataSource,
                )
              }
              options={
                jobCodes?.[
                  sources?.filter(
                    (source) => source?.label === row.original?.dataSource,
                  )?.length
                    ? sources?.filter(
                        (source) => source?.label === row.original?.dataSource,
                      )?.[0]?.value
                    : ''
                ]
              }
            />
          )}
        </div>
      ),
    },
    {
      id: '3',
      Header: <TableHeader name="Weight" HeaderKey="weight" sort={true} />,
      Cell: ({ row }) => {
        const [weight, setWeight] = useState(row.original?.weightage);
        return (
          <div className="flex items-center">
            {!isEditable ? (
              <p className="text-gray-600 text-sm">
                {`${row.original.weightage}%` || 'N/A'}
              </p>
            ) : (
              <Input
                onChange={(event) => setWeight(event.target.value)}
                onBlur={(event) => handleWeightageBlur(event, row.original?.id)}
                value={weight}
              />
            )}
          </div>
        );
      },
    },
    ...Object.entries(benchmarkResponse?.benchmarkMap || {}).map(
      (benchmark) => {
        return {
          id: v4(),
          Header: (
            <TableHeader
              name={benchmark?.[1]}
              HeaderKey={benchmark?.[1]}
              sort={true}
            />
          ),
          Cell: ({ row }) => (
            <div className="flex flex-col">
              <p className="text-gray-900 text-sm">
                {Math.round(
                  ((((row.original?.weightage / 100) *
                    row.original?.adjustment) /
                    100) *
                    row.original?.[`${benchmark[0]}_pValue`]?.data?.filter(
                      (data) =>
                        data?.label ===
                        row.original.selectedPvalueInBenchmark?.[
                          benchmark[0]
                        ]?.['Mid'],
                    )?.[0]?.value *
                    Number(
                      calculations
                        ?.filter(
                          (calculation) =>
                            calculation?.benchmark === benchmark[1],
                        )?.[0]
                        ?.values?.filter((value) => value?.point === 'Mid')?.[0]
                        ?.percentValue,
                    )) /
                    100,
                )}
              </p>
              <p className="text-gray-600 text-sm">
                {row.original?.[benchmark[0]]?.point}
              </p>
            </div>
          ),
        };
      },
    ),
    {
      id: v4(),
      width: isEditable ? '5%' : '0%',
      Header: <TableHeader name="" HeaderKey="" />,
      Cell: ({ row }) => (
        <div className="flex items-center">
          {isEditable && (
            <DeleteFormIcon
              className="cursor-pointer"
              onClick={() => handleDeleteBenchmark(row.original?.id)}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="">
      <Table
        data={benchmarkTableData}
        columns={columns}
        renderRowSubComponent={renderRowSubComponent}
        isExpandable={true}
      />
    </div>
  );
};

export default BenchmarksTable;
