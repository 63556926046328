import VerticalEclipse from 'assets/svg/verticalEclipse';
import Table from 'components/atoms/table/Table';
import TableHeader from 'components/atoms/tableHeader/TableHeader';
import Avatar from 'layouts/header/Avatar';
import { formatDate } from 'pages/recognition/orderHistory/helper';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ExternalActionPopUp } from './externalActionPopUp/ExternalActionPopUp';
import { deleteExternalRanges } from 'apiClient/rangeBuilder/rangeBuilder';
import { onResponse } from 'utils/toastMessages';
export const ACTION_TYPE = {
  Update: 'Update',
  Replace: 'Replace',
  Delete: 'Delete',
  Upload: 'Upload',
};

const ExternalDataTable = ({
  data,
  setSelectedData,
  refetch,
  setIsAddNewPopUp,
  setActionType,
  setRangeFormTypeId,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [actionPopUp, setActionPopUp] = useState(null);
  const performAction = async (actionType, id) => {
    if (actionType === ACTION_TYPE.Delete) {
      const deleteRes = await deleteExternalRanges(id);
      if (deleteRes?.data) {
        onResponse('Deleted Successfully!');
        refetch();
      } else {
        onResponse('Error Occurred!');
      }
    } else if (actionType === ACTION_TYPE.Replace) {
      setIsAddNewPopUp(true);
      setActionType(ACTION_TYPE.Replace);
      setRangeFormTypeId(id);
    } else if (actionType === ACTION_TYPE.Update) {
      setIsAddNewPopUp(true);
      setActionType(ACTION_TYPE.Update);
      setRangeFormTypeId(id);
    }
  };
  const benchmarkPlansHeaders = [
    {
      id: '1',
      Header: <TableHeader name="Data" HeaderKey="title" sort={true} />,
      Cell: ({ row }) => (
        <div>
          <p
            className="text-sm font-medium text-gray-900 cursor-pointer hover:underline"
            onClick={() => setSelectedData(row.original)}
          >
            {row.original?.name}
          </p>
          <p className="text-sm text-gray-600">{`ID ${row.original?.internalId}`}</p>
        </div>
      ),
    },
    {
      id: '2',
      Header: <TableHeader name="Ranges" HeaderKey="noOfRoles" sort={true} />,
      Cell: ({ row }) => (
        <div>
          <p className="text-sm text-gray-600" onClick={() => {}}>
            {Number(row.original?._count?.userData)?.toLocaleString()}
          </p>
        </div>
      ),
    },
    {
      id: '3',
      Header: (
        <div className="text-center" style={{ textAlign: 'center' }}>
          <TableHeader
            name="Last Modified"
            HeaderKey="lastModified"
            sort={true}
          />
        </div>
      ),
      Cell: ({ row }) => (
        <div className="">
          <div className="flex items-center gap-3">
            <div>
              <p className="text-sm text-gray-900">
                {row.original?.modifiedBy?.name}
              </p>
              <p className="text-sm text-gray-600">
                {formatDate(
                  new Date(row.original?.histories?.[0]?.createdDate),
                )}
              </p>
            </div>
          </div>
        </div>
      ),
    },
    {
      id: '5',
      width: '5%',
      Header: <TableHeader name="" />,
      Cell: ({ row }) => {
        return (
          <div>
            <VerticalEclipse
              onClick={() => {
                setActionPopUp(row?.original?.id);
              }}
            />
            {actionPopUp === row?.original?.id && (
              <ExternalActionPopUp
                id={row?.original?.id}
                setActionPopUp={setActionPopUp}
                performAction={performAction}
              />
            )}
          </div>
        );
      },
    },
  ];
  return (
    <div className="px-8 w-full h-full">
      <Table
        headerBackgroundColor="bg-gray-50"
        columns={benchmarkPlansHeaders}
        data={data}
      />
    </div>
  );
};

export default ExternalDataTable;
