// HeatmapChart.js
import React from 'react';
import { ResponsiveHeatMap } from '@nivo/heatmap';

// const getCustomColor = (datum) => {
//   // Define a custom color map based on the key
//   const colorMap = {
//     1: '#22CCEE',
//     2: '#F63D68',
//     3: '#FAC515',
//     4: '#7A5AF8',
//     5: '#16B364',
//     // Add more keys and colors as needed
//   };

//   // Return the color based on the key
//   return colorMap[datum.key] || '#cccccc'; // Default color if the key is not found
// };

const getCustomColor = (value) => {
  // Your custom logic to determine color based on value
  if (value > 100) return '#d73027';
  if (value > 95) return '#fc8d59';
  if (value > 90) return '#fee08b';
  if (value > 80) return '#d9ef8b';
  if (value > 70) return '#91cf60';
  return '#1a9850';
};

const HeatmapChart = ({ data, keys }) => (
  // <ResponsiveHeatMap
  //   data={data}
  //   keys={keys}
  //   indexBy="id"
  //   margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
  //   forceSquare={true}
  //   colors={['#d73027', '#fc8d59', '#fee08b', '#d9ef8b', '#91cf60', '#1a9850']}
  //   // cellColor={getCustomColor}
  //   labelTextColor={{ from: 'color', modifiers: [['darker', 1.8]] }}
  //   axisTop={{
  //     orient: 'top',
  //     tickSize: 2,
  //     tickPadding: 2,
  //     tickRotation: -45,
  //     legend: '',
  //     legendOffset: 36,
  //   }}
  //   axisLeft={{
  //     orient: 'left',
  //     tickSize: 5,
  //     tickPadding: 5,
  //     tickRotation: 0,
  //     legend: '',
  //     legendOffset: -40,
  //   }}
  //   cellOpacity={1}
  //   cellBorderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
  //   defs={[
  //     {
  //       id: 'lines',
  //       type: 'patternLines',
  //       background: 'inherit',
  //       color: 'rgba(0, 0, 0, 0.1)',
  //       rotation: -45,
  //       lineWidth: 4,
  //       spacing: 7,
  //     },
  //   ]}
  //   fill={[{ id: 'lines' }]}
  //   animate={true}
  //   motionStiffness={80}
  //   motionDamping={9}
  //   hoverTarget="cell"
  //   cellHoverOthersOpacity={0.25}
  // />
  <ResponsiveHeatMap
    data={data}
    keys={keys}
    indexBy="id"
    margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
    forceSquare={true}
    // colors={['#d73027', '#fc8d59', '#fee08b', '#d9ef8b', '#91cf60', '#1a9850']} // Array of colors
    colors={(datum) => getCustomColor(datum.value)}
    labelTextColor={{ from: 'color', modifiers: [['darker', 1.8]] }}
    axisTop={{
      orient: 'top',
      tickSize: 2,
      tickPadding: 2,
      tickRotation: -45,
      legend: '',
      legendOffset: 36,
    }}
    axisLeft={{
      orient: 'left',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: '',
      legendOffset: -40,
    }}
    cellOpacity={1}
    cellBorderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
    defs={[
      {
        id: 'lines',
        type: 'patternLines',
        background: 'inherit',
        color: 'rgba(0, 0, 0, 0.1)',
        rotation: -45,
        lineWidth: 4,
        spacing: 7,
      },
    ]}
    fill={[{ id: 'lines' }]}
    animate={true}
    motionStiffness={80}
    motionDamping={9}
    hoverTarget="cell"
    cellHoverOthersOpacity={0.25}
  />
);

export default HeatmapChart;
