import Button from 'components/atoms/button/Button';
import DatePicker from 'components/atoms/FormElements/datepicker/DatePicker';
import { Input } from 'components/atoms/FormElements/input/Input';
import { useEffect, useState } from 'react';

import { FormulaCalculator } from '@talent-monk/formula';
import moment from 'moment';

/**
 * Validates variables based on the provided formula object.
 * @param {Object} props - Component props.
 * @param {Array} props.variables - List of variable IDs.
 * @param {Object} props.formulaObj - Formula object containing the formula and dependencyValueMap.
 * @param {Array} props.dataSource - Data source array.
 * @param {Object} props.selectedColumn - Selected column object.
 * @param {Function} props.onValidationChange - Function to handle validation changes.
 * @param {Function} props.saveAction - Function to save the validation.
 * @param {Array} props.columns - Columns array.
 * @param {Function} props.setValidation - Function to set validation status.
 * @return {JSX.Element} The Validate component JSX.
 */
export default function Validate({
  variables = [],
  formulaObj,
  dataSource,
  // selectedColumn,
  onValidationChange,
  saveAction,
  // type,
  // columns,
  setValidation,
}) {
  const [result, setResult] = useState('');
  const [error, setError] = useState('');
  const onSubmit = async () => {
    try {
      let response = await FormulaCalculator.atomicFormula(formulaObj);
      if (response === null) {
        throw new Error('Null Result');
      }
      setResult(response);
      setError('');
      setValidation(true);
      return response;
    } catch (err) {
      setError(err.message);
    }
  };
  useEffect(() => {
    variables.forEach((id) => {
      // // onValidationChange(id, '');
      // const data = columns.filter((obj) =>
      //   obj?.type === 'payComponent' ? obj?.rowId === id : obj?.id === id,
      // )[0];
      // const masterData = dataSource.filter((obj) => {
      //   // console.log(obj);
      //   return obj?.data?.fieldId === id;
      // })[0];
      // // console.log(data);
      // if (data || masterData) {
      //   if (
      //     data?.source?.data?.dataType === 'Date' ||
      //     masterData?.data?.dataType === 'Date' ||
      //     data?.dataType === 'Date'
      //   ) {
      //     onValidationChange(id, moment(new Date()).format('YYYY-MM-DD'));
      //   } else {
      //     onValidationChange(id, '');
      //   }
      // }
      const data = Object.entries(formulaObj?.dependencyChainMap).find(
        ([key, value]) => key === id,
      )?.[1];
      if (data) {
        if (data?.data?.dataType === 'Date') {
          onValidationChange(id, moment(new Date()).format('YYYY-MM-DD'));
        } else {
          onValidationChange(id, '');
        }
      }
    });
  }, []);

  return (
    <div className="p-4  h-[395px] max-h-[395px]">
      <div className="mb-4 pb-4 border-b-2">
        {/* {variables.map((id) => {
          const data = columns.filter((obj) =>
            obj?.type === 'payComponent' ? obj?.rowId === id : obj?.id === id,
          )[0];
          const masterData = dataSource.filter(
            (obj) => obj?.data?.fieldId === id,
          )[0];
          if (!data && !masterData) {
            return <></>;
          } else if (
            data?.source?.data?.dataType === 'Date' ||
            masterData?.data?.dataType === 'Date' ||
            data?.dataType === 'Date'
          ) {
            return (
              <>
                <DatePicker
                  key={`var-${id}`}
                  label={data?.name || masterData?.data?.alias}
                  onChange={(e) =>
                    onValidationChange(id, moment(e).format('YYYY-MM-DD'))
                  }
                  selected={
                    formulaObj.dependencyValueMap?.[id]
                      ? new Date(formulaObj.dependencyValueMap?.[id])
                      : new Date()
                  }
                  // selected={
                  //   formulaObj[selectedColumn?.id]?.validationData?.[id]
                  //     ? new Date(formulaObj[selectedColumn?.id]?.validationData?.[id])
                  //     : ""
                  // }
                  dateFormat="yyyy-dd-MM"
                />
              </>
            );
          } else {
            return (
              <Input
                key={`var-${id}`}
                label={data?.name || masterData?.data?.alias}
                onChange={(e) => onValidationChange(id, e.target.value)}
              />
            );
          }
        })}{' '} */}
        {variables &&
          variables?.length > 0 &&
          variables?.map((id) => {
            const data = Object.entries(formulaObj?.dependencyChainMap).find(
              ([key, value]) => key === id,
            )?.[1];

            if (!data) {
              return <></>;
            } else if (data?.data?.dataType === 'Date') {
              return (
                <>
                  <DatePicker
                    key={`var-${id}`}
                    label={data?.data?.alias}
                    onChange={(e) =>
                      onValidationChange(id, moment(e).format('YYYY-MM-DD'))
                    }
                    selected={
                      formulaObj.dependencyValueMap?.[id]
                        ? new Date(formulaObj.dependencyValueMap?.[id])
                        : new Date()
                    }
                    dateFormat="yyyy-dd-MM"
                  />
                </>
              );
            } else {
              return (
                <>
                  {console.log(data?.data?.dataType)}
                  <Input
                    key={`var-${id}`}
                    label={data?.data?.alias}
                    type={
                      data?.data?.dataType === 'Amount' ||
                      data?.data?.dataType === 'Number' ||
                      data?.data?.dataType === 'Integer'
                        ? 'number'
                        : 'text'
                    }
                    onChange={(e) => onValidationChange(id, e.target.value)}
                  />
                </>
              );
            }
          })}
      </div>

      <Button variant="filled" className="w-full" onClick={onSubmit}>
        Test
      </Button>

      <div className="w-full mt-3">
        <Input label="Result" value={result} disabled={true} />
      </div>

      <div className="text-error-500">{error}</div>
    </div>
  );
}
