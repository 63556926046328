import VerticalEclipse from 'assets/svg/verticalEclipse';
import { Loader } from 'components/atoms/loader/Loader';
import { renderHistoryCard } from 'pages/rangeBuilder/externalData/viewExternalData/viewDetailsPopUp/versionHistory/VersionHistory';
import { formatDate } from 'pages/recognition/orderHistory/helper';
import { useGetJobMatchVersionHistory } from 'query/rangeBuilder/jobMatching/jobMatching';
import React from 'react';
import { useParams } from 'react-router-dom';

const VersionHistory = ({ isViewDetailsPopUp, pivotMap }) => {
  const { id: planId } = useParams();
  const { data: historyRes, isLoading: isVersionHistoryLoading } =
    useGetJobMatchVersionHistory(planId, isViewDetailsPopUp?.id);
  return (
    <>
      {isVersionHistoryLoading ? (
        <div
          className="flex w-full items-center justify-center"
          style={{
            height: 'calc(100vh - 228px)',
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="space-y-3">
          {historyRes?.map((historyData, i) => {
            if (i < historyRes?.length - 1) {
              return (
                <div
                  key={i}
                  className="flex justify-between border border-gray-200 rounded-xl p-4"
                >
                  <div className="space-y-4">
                    <p className="text-sm font-semibold text-gray-900">
                      {formatDate(historyData?.createdDate)}
                    </p>
                    <div className="space-y-1">
                      {Object.keys(historyData?.uploadedJson)?.map((key) => {
                        if (key === 'Job Details') {
                          const crrJobDetails =
                            historyData?.uploadedJson?.[key];
                          const currData = {};
                          Object.keys(crrJobDetails)?.forEach((key) => {
                            currData[pivotMap?.[key]] = crrJobDetails?.[key];
                          });
                          const prevJobDetails =
                            historyRes?.[i + 1]?.uploadedJson?.[key];
                          const prevData = {};
                          Object.keys(prevJobDetails)?.forEach((key) => {
                            prevData[pivotMap?.[key]] = prevJobDetails?.[key];
                          });
                          return renderHistoryCard(currData, prevData);
                        } else {
                          const currDetails = historyData?.uploadedJson?.[key];
                          const prevDetails =
                            historyRes?.[i + 1]?.uploadedJson?.[key];
                          const currData = {};
                          const prevData = {};
                          Object.keys(currDetails)?.forEach((key) => {
                            if (key !== 'Benchmark Provider') {
                              currData[key] = currDetails?.[key];
                            }
                          });
                          Object.keys(prevDetails)?.forEach((key) => {
                            if (key !== 'Benchmark Provider') {
                              prevData[key] = prevDetails?.[key];
                            }
                          });
                          return renderHistoryCard(currData, prevData);
                        }
                      })}
                    </div>
                  </div>
                  <VerticalEclipse className="w-5 h-5" />
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      )}
    </>
  );
};

export default VersionHistory;
