import { post, get } from 'apiClient/apiCalls';
import * as CONSTANT from '../api.constant';
import { AxiosError } from 'axios';

export const viewDashboardData = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/view-dashboard`,
      payload,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! View dashboard failed');
    }
  }
};

export const updateDashboard = async (payload, id) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/update-dashboard/${id}`,
      payload,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Update dashboard failed');
    }
  }
};

export const createPage = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/create-page`,
      payload,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Create page failed');
    }
  }
};

export const updatePage = async (payload, id) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/update-page/${id}`,
      payload,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Update page failed');
    }
  }
};

export const getPage = async (id, idx) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/get-page/${id}?index=${idx}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Get page failed');
    }
  }
};

export const getAllFiles = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/get-all-files/${id}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Get all files failed');
    }
  }
};

export const getChartTypes = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_DASHBOARD_V3}/get-chart-types?id=${id}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err?.message);
    } else {
      throw new Error('Error! Get charts list failed');
    }
  }
};
