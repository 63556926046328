import DatePicker from 'components/atoms/FormElements/datepicker/DatePicker';
import { Input } from 'components/atoms/FormElements/input/Input';
import { inputFields } from 'config/rangeBuilderData';
import { useEffect } from 'react';

/*
Components being reused are:-
    1. Input
    2. DatePicker
*/

export const RangeDetails = ({
  planDetails,
  setPlanDetails,
  error,
  setError,
}) => {
  const handleChange = (event) => {
    setPlanDetails({ ...planDetails, title: event.target.value });
  };

  const handleDateChange = (event, field) => {
    const date = new Date(event);
    date.setHours(0, 0, 0, 0);
    setPlanDetails({
      ...planDetails,
      [field?.valueKey]: date?.toISOString(),
    });
  };

  useEffect(() => {
    if (planDetails?.title?.length) {
      const titleError = error;
      delete titleError?.title;
      setError(titleError);
    }
  }, [planDetails?.title]);

  return (
    <div className="px-5 pt-6 pb-5 space-y-5 bg-white rounded-xl shadow-md">
      <p className="text-sm font-semibold text-gray-900 pb-[10px]">
        Range Details
      </p>
      <div className="flex items-center gap-8">
        {inputFields?.map((field) => {
          return (
            <div key={field?.id} className="w-1/4 flex flex-col justify-center">
              {field?.type === 'text' && (
                <Input
                  label={field?.label}
                  placeholder="Enter title"
                  className="!py-2 !px-[14px] text-sm font-medium text-gray-900"
                  // labelClassName="text-sm font-medium text-gray-700 my-1"
                  value={planDetails?.title}
                  onChange={handleChange}
                />
              )}
              {field?.type === 'date' && (
                <DatePicker
                  className="!py-2 !px-[14px]"
                  dateFormat="MMM d, yyyy"
                  selected={
                    planDetails?.[field?.valueKey]
                      ? new Date(planDetails?.[field?.valueKey])
                      : new Date()
                  }
                  label={field?.label}
                  onChange={(event) => handleDateChange(event, field)}
                />
              )}
              {error?.title &&
                field?.valueKey === 'title' &&
                !planDetails?.title?.length && (
                  <p className="text-red-500 rounded text-sm">{error?.title}</p>
                )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
