import ColorPicker from 'components/atoms/FormElements/colorPicker/ColorPicker';
import { Input } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import TextBox from 'components/atoms/FormElements/textBox/TextBox';
import Toggle from 'components/atoms/FormElements/toggle/Toggle';
import { graphOptions } from '../DashboadConfiguration/Chartlist';

export default function DashboardChartData({
  configFor,
  layout,
  setLayout,
  selectedPage,
}) {
  const saveLayoutToLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  const updateParentData = (newData) => {
    const selectedPageData = layout?.map((item) => {
      if (item?.pageIndex === selectedPage) {
        return { ...item, data: newData };
      } else return item;
    });
    setLayout(selectedPageData);
    saveLayoutToLocalStorage('dashboardLayout', selectedPageData);
  };

  const handleTextEdit = (key, value) => {
    return layout?.map((item) => {
      if (item?.pageIndex === selectedPage) {
        const updatedData = item?.data.map((dataItem) => {
          if (dataItem?.i === configFor?.i) {
            return {
              ...dataItem,
              data: {
                ...dataItem.data,
                [key]: value,
              },
            };
          }
          return dataItem;
        });
        updateParentData(updatedData);
      }
      return item;
    });
  };

  const setNestedProperty = (obj, path, value) => {
    const keys = path.split('.');
    const lastKey = keys.pop();
    const lastObj = keys.reduce((acc, key) => (acc[key] = acc[key] || {}), obj);
    lastObj[lastKey] = value;
    return obj;
  };

  const handleChartEdit = (key, value) => {
    return layout?.map((item) => {
      if (item?.pageIndex === selectedPage) {
        const updatedData = item?.data.map((dataItem) => {
          if (dataItem?.i === configFor?.i) {
            const newData = setNestedProperty({ ...dataItem.data }, key, value);
            // Ensure legends default to false if not set
            if (
              newData.chartData?.chartOptions?.plugins?.legends === undefined
            ) {
              newData.chartData.chartOptions.plugins.legends = false;
            }
            return {
              ...dataItem,
              data: newData,
            };
          }
          return dataItem;
        });
        updateParentData(updatedData);
      }
      return item;
    });
  };

  const pixelOptions = [
    { value: '12', label: '12px' },
    { value: '14', label: '14px' },
    { value: '16', label: '16px' },
    { value: '18', label: '18px' },
    { value: '20', label: '20px' },
    { value: '24', label: '24px' },
    { value: '28', label: '28px' },
    { value: '32', label: '32px' },
    { value: '40', label: '40px' },
    { value: '56', label: '56px' },
  ];

  const fontOptions = [
    { value: 'Figtree', label: 'Figtree' },
    { value: 'Roboto', label: 'Roboto' },
    { value: 'Open Sans', label: 'Open Sans' },
    { value: 'Lato', label: 'Lato' },
    { value: 'Montserrat', label: 'Montserrat' },
    { value: 'Poppins', label: 'Poppins' },
  ];

  const getGraphOptions = (type) => {
    return graphOptions
      ?.filter((option) => option?.chartData?.type.includes(type))
      .map((option) => ({
        value: option?.value,
        label: option?.label,
        ...option?.chartData,
      }));
  };

  return (
    <div className="flex flex-col min-h-screen pb-6">
      {configFor?.data?.type === 'text' ? (
        <div className="flex flex-col gap-4">
          <div>
            <TextBox
              label="Description"
              placeholder="Enter description"
              rows={4}
              onChange={(e) => handleTextEdit('description', e.target.value)}
              value={configFor?.data?.description}
            />
          </div>
          <div>
            <Select
              label="Font Size"
              options={pixelOptions}
              value={{
                value: configFor?.data?.fontSize || '16',
                label: `${configFor?.data?.fontSize || '16'}px`,
              }}
              onChange={(e) => {
                handleTextEdit('fontSize', Number(e?.value));
              }}
            />
          </div>
          <div>
            <ColorPicker
              label="Font Color"
              value={configFor?.data?.fontColor || '#000000'}
              onChange={(e) => {
                handleTextEdit('fontColor', e.target.value);
              }}
            />
          </div>
          <div>
            <Select
              label="Font Family"
              options={fontOptions}
              value={{
                value: configFor?.data?.fontFamily || 'Lato',
                label: configFor?.data?.fontFamily || 'Lato',
              }}
              onChange={(e) => {
                handleTextEdit('fontFamily', e.value);
              }}
            />
          </div>
        </div>
      ) : configFor?.data?.type === 'table' ? (
        <div className="flex flex-col gap-4">
          <div>
            <Input
              label="Title"
              placeholder="Enter Title"
              onChange={(e) => {
                handleTextEdit('title', e.target.value);
              }}
              value={configFor?.data?.title}
            />
          </div>
          <div>
            <TextBox
              label="Description"
              placeholder="Enter description"
              rows={4}
              onChange={(e) => handleTextEdit('description', e.target.value)}
              value={configFor?.data?.description}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <div>
            <Select
              label="Dataset"
              options={getGraphOptions(configFor?.data?.type)}
              onChange={(e) => {
                handleChartEdit('chartData', e);
              }}
              value={{
                value: configFor?.data?.chartData?.value,
                label: configFor?.data?.chartData?.label,
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Input label="Dimension" />
            <span className="text-xs text-gray-600">Bars</span>
          </div>
          <div className="flex flex-col gap-2">
            <Input label="Measure" />
            <span className="text-xs text-gray-600">Length of Bars</span>
          </div>
          <div>
            <Select label="Aggregation" />
          </div>
          <div className="flex justify-between">
            <span className="text-sm font-medium">Show Legends</span>
            <div className="-mr-2">
              <Toggle
                checked={
                  configFor?.data?.chartData?.chartOptions?.plugins?.legend
                    ?.display || false
                }
                onChange={(e) =>
                  handleChartEdit(
                    'chartData.chartOptions.plugins.legend.display',
                    e.target.checked,
                  )
                }
              />
            </div>
          </div>
          <Input
            label="Title"
            placeholder="Enter Title"
            onChange={(e) => {
              handleChartEdit('title', e.target.value);
            }}
            value={configFor?.data?.title}
          />
          <div>
            <TextBox
              label="Description"
              placeholder="Enter description ( only up to 70 characters)"
              rows={4}
              onChange={(e) => {
                handleChartEdit('description', e.target.value);
              }}
              value={configFor?.data?.description}
            />
          </div>
        </div>
      )}
    </div>
  );
}
