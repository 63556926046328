import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import GetSvgIcon from '../../molecules/svgCollection/SvgCollection';

import ChevronDownLogo from 'assets/svg/chevronDownLogo';
import { rbacFlags } from 'config/rbacFlags';
import { subItemsConditions } from 'config/sideNavConfig';
import { checkRoleAccess } from 'utils/accessControl';

const NavItem = (props) => {
  const [isActive, setIsActive] = useState(false);
  const { isDropDownActive, setDropDownActive } = props;
  const [isSubMenuActive, setIsSubMenuActive] = useState(false);
  const userAccess = useSelector((state) => state.theme?.userAccess);
  const theme = useSelector((state) => state.theme);
  const [navPopUpVisible, setNavPopUpVisible] = useState(false);
  const { isSelectedOption, setIsSelectedOption } = props;
  const [isLinkHovered, setIsLinkHovered] = useState('');
  const [subMenuOptionPopUpVisible, setSubMenuPopUpVisible] = useState(false);

  const isNavLinkStyleApplied = (isActive) =>
    isActive ||
    (!props.subMenu?.length && props.itemName === isSelectedOption) ||
    (props.subMenu &&
      props.subMenu.filter((option) => option.name === isSelectedOption)
        ?.length > 0);

  const navLinkStyles = ({ isActive }) => {
    return {
      // background: isNavLinkStyleApplied(isActive)
      //   ? `linear-gradient(90deg,${theme.primaryColor}  0%,${theme.secoundaryColor} 100%) !important`
      //   : '',
      // ? `linear-gradient(90deg,${theme.primaryColor}  0%,${theme.secoundaryColor} 100%)`
      // : '',
      // boxShadow: isNavLinkStyleApplied(isActive)
      //   ? '0px 1px 2px rgba(16, 24, 40, 0.05)'
      //   : '',
      borderRadius: isNavLinkStyleApplied(isActive) ? '8px' : '',
      color: isNavLinkStyleApplied(isActive) ? '#fff' : '#475467',
      background: '#ddd !important',
    };
  };

  const location = useLocation();
  const isCollapsed = theme.isSidebarVisible;
  // const conditions = [
  //   {
  //     name: 'Manage Recognition',
  //     moduleName: 'Recognition',
  //     roleAccess: 'Managerecognitionprogramsandawards',
  //   },
  //   {
  //     name: 'Redeem Rewards',
  //     moduleName: 'Recognition',
  //     roleAccess: 'Accesstoredeempoints',
  //   },
  //   {
  //     name: 'Manage Policies',
  //     moduleName: 'Policy',
  //     roleAccess: 'Managecompanypolicies',
  //   },
  //   {
  //     name: 'Manage Benefits',
  //     moduleName: 'Benefit',
  //     roleAccess: 'Administerandmanagecompanybenefits',
  //   },
  //   {
  //     name: 'Manage Programs',
  //     moduleName: 'Benefit',
  //     roleAccess: 'Administerandmanagecompanybenefits',
  //   },
  //   {
  //     name: 'View Dashboard',
  //     moduleName: 'Benefit',
  //     roleAccess: 'Accessandviewbenefitsforself',
  //   },
  //   // {
  //   //   name: 'Manage Benefits',
  //   //   moduleName: 'Benefit',
  //   //   roleAccess: 'Accessandviewbenefitsforself',
  //   // },
  //   {
  //     name: 'Manage Compensation Plan',
  //     moduleName: 'Compensation',
  //     roleAccess: 'Managecompensationplans',
  //   },
  //   {
  //     name: 'Manage Forms',
  //     moduleName: 'Recognition',
  //     roleAccess: 'Managerecognitionprogramsandawards',
  //   },
  //   {
  //     name: 'My Compensation',
  //     moduleName: 'Profile',
  //     roleAccess: 'ManageCompensation',
  //   },
  //   {
  //     name: 'Recognition Champions',
  //     moduleName: 'Recognition',
  //     roleAccess: 'RecognitionChampions',
  //   },
  //   {
  //     name: 'Total Rewards Philosophy',
  //     moduleName: 'Compensation',
  //     roleAccess: 'RewardsPhilosophy',
  //   },
  //   {
  //     name: 'View Policies',
  //     moduleName: 'Policy',
  //     roleAccess: 'ViewCompanyPolicies',
  //   },
  //   // {
  //   //   name: 'Compensation Planning',
  //   //   moduleName: 'Compensation',
  //   //   roleAccess: 'CompensationWorksheets',
  //   // },
  //   {
  //     name: 'Increment Philosophy',
  //     moduleName: 'Compensation',
  //     roleAccess: 'IncrementPhilosophy',
  //   },
  //   {
  //     name: 'Bonus Calculations',
  //     moduleName: 'Compensation',
  //     roleAccess: 'ManageCompensationBonus',
  //   },
  //   {
  //     name: 'Transact Now',
  //     moduleName: 'Esop',
  //     roleAccess: 'TransactEquity',
  //   },
  //   {
  //     name: 'Equity Summary',
  //     moduleName: 'Profile',
  //     roleAccess: 'EsopTab',
  //   },
  //   {
  //     name: 'Dashboard',
  //     moduleName: 'Insurance',
  //     roleAccess: 'ManageInsuranceClaim',
  //   },
  //   {
  //     name: 'Summary',
  //     moduleName: 'Insurance',
  //     roleAccess: 'EnrolAndClaimInsurance',
  //   },
  //   {
  //     name: 'Health Insurance',
  //     moduleName: 'Insurance',
  //     roleAccess: 'EnrolAndClaimInsurance',
  //   },
  //   {
  //     name: 'Accidental',
  //     moduleName: 'Insurance',
  //     roleAccess: 'EnrolAndClaimInsurance',
  //   },
  //   {
  //     name: 'Life Insurance',
  //     moduleName: 'Insurance',
  //     roleAccess: 'EnrolAndClaimInsurance',
  //   },
  //   {
  //     name: 'My Claims',
  //     moduleName: 'Insurance',
  //     roleAccess: 'EnrolAndClaimInsurance',
  //   },
  //   {
  //     name: 'Compensation Planning',
  //     moduleName: 'Compensation',
  //     roleAccess: 'CompensationWorksheets',
  //   },
  //   {
  //     name: 'Compensation Planning',
  //     moduleName: 'Compensation',
  //     roleAccess: 'CompensationWorksheetsAdmin',
  //   },
  // ];

  const handleSubMenuItemClick = (item) => {
    setIsSelectedOption(item?.name);
    props.setDropDownOpenList([props.index]);
    setDropDownActive(props.index);
  };

  const isDropDownOpen = () => props.dropDownOpenList?.includes(props.index);

  function renderDropDown(list, isCollapsed, parentName, parentUrl) {
    if (list.length > 0) {
      return list.map((item, index) => {
        if (
          item.name !== 'Compensation Planning' &&
          subItemsConditions.some(
            (nav) =>
              nav.name === item.name &&
              !checkRoleAccess(userAccess, nav.moduleName, nav?.roleAccess),
          )
        ) {
          return null;
        } else if (item.name === 'Compensation Planning') {
          if (
            checkRoleAccess(
              userAccess,
              'Compensation',
              rbacFlags.CompensationWorksheets,
            ) ||
            checkRoleAccess(
              userAccess,
              'Compensation',
              rbacFlags.CompensationWorksheetsAdmin,
            )
          ) {
            return (
              <>
                {item?.name !== 'Letter Templates' &&
                  item?.name !== 'Create Offer' && (
                    <Link
                      to={
                        item?.isLandingPageItem
                          ? `${parentUrl}/${item.id}`
                          : `/${item.url}`
                      }
                      isActive={item?.name === isSelectedOption}
                      onClick={() => handleSubMenuItemClick(item)}
                      onMouseEnter={() => setIsLinkHovered(item?.name)}
                      onMouseLeave={() => setIsLinkHovered('')}
                    >
                      <div
                        className={`w-full  rounded-b-md hover:border-3 rounded hover:border-gray-200  py-2`}
                      >
                        <div key={index} className="flex justify-start">
                          <div className="flex w-full justify-between items-center">
                            <div
                              className={`text-sm  font-semibold ${
                                item?.name === isSelectedOption
                                  ? 'text-primaryActive'
                                  : 'text-gray-600 '
                              }  `}
                            >
                              {isCollapsed ? (
                                <>
                                  <div
                                    onMouseEnter={() =>
                                      setSubMenuPopUpVisible(item?.name)
                                    }
                                    onMouseLeave={() =>
                                      setSubMenuPopUpVisible(false)
                                    }
                                    className="w-16"
                                  >
                                    <GetSvgIcon
                                      iconName={item?.name}
                                      isSubDropDown={
                                        parentName === item?.name ? true : false
                                      }
                                      isActive={item?.name === isSelectedOption}
                                      isHovered={isLinkHovered === item?.name}
                                    />
                                  </div>
                                  {/* {subMenuOptionPopUpVisible === item?.name && (
                                    <div className="absolute z-[99999] right-[-9.7rem] w-48 p-2">
                                      <div className="absolute bg-gray-50 border border-gray-200 rounded top-[-1.4rem] flex items-center gap-2 p-2">
                                        <p className={`text-gray-600`}>{item?.name}</p>
                                      </div>
                                    </div>
                                  )} */}
                                </>
                              ) : (
                                <div className="flex items-center w-full gap-3">
                                  <div className="w-5 h-5">
                                    <GetSvgIcon
                                      iconName={item?.name}
                                      isSubDropDown={
                                        parentName === item?.name ? true : false
                                      }
                                      isActive={item?.name === isSelectedOption}
                                      isHovered={item?.name === isLinkHovered}
                                    />
                                  </div>
                                  <p
                                    className={`text-sm font-semibold  font-figtree w-[156px] ${
                                      !isCollapsed ? 'ml-[0]' : ''
                                    } ${
                                      item?.name === isLinkHovered
                                        ? 'text-primaryHover'
                                        : ''
                                    } truncate hover:whitespace-normal whitespace-normal `}
                                  >
                                    {item?.name}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )}
              </>
            );
          } else {
            return null;
          }
        } else
          return (
            <>
              {item?.name !== 'Letter Templates' &&
                item?.name !== 'Create Offer' && (
                  <Link
                    to={
                      item?.isLandingPageItem
                        ? `${parentUrl}/${item.id}`
                        : `/${item.url}`
                    }
                    isActive={item?.name === isSelectedOption}
                    onClick={() => handleSubMenuItemClick(item)}
                    onMouseEnter={() => setIsLinkHovered(item?.name)}
                    onMouseLeave={() => setIsLinkHovered('')}
                  >
                    <div
                      className={`w-full  rounded-b-md hover:border-3 rounded hover:border-gray-200  py-2`}
                    >
                      <div key={index} className="flex justify-start">
                        <div className="flex w-full justify-between items-center">
                          <div
                            className={`text-sm  font-semibold ${
                              item?.name === isSelectedOption
                                ? 'text-primaryActive'
                                : 'text-gray-600 '
                            }  `}
                          >
                            {isCollapsed ? (
                              <>
                                <div
                                  onMouseEnter={() =>
                                    setSubMenuPopUpVisible(item?.name)
                                  }
                                  onMouseLeave={() =>
                                    setSubMenuPopUpVisible(false)
                                  }
                                  className="w-16"
                                >
                                  <GetSvgIcon
                                    iconName={item?.name}
                                    isSubDropDown={
                                      parentName === item?.name ? true : false
                                    }
                                    isActive={item?.name === isSelectedOption}
                                    isHovered={isLinkHovered === item?.name}
                                  />
                                </div>
                                {/* {subMenuOptionPopUpVisible === item?.name && (
                                <div className="absolute z-[99999] right-[-9.7rem] w-48 p-2">
                                  <div className="absolute bg-gray-50 border border-gray-200 rounded top-[-1.4rem] flex items-center gap-2 p-2">
                                    <p className={`text-gray-600`}>{item?.name}</p>
                                  </div>
                                </div>
                              )} */}
                              </>
                            ) : (
                              <div className="flex items-center w-full gap-3">
                                <div className="w-5 h-5">
                                  <GetSvgIcon
                                    iconName={item?.name}
                                    isSubDropDown={
                                      parentName === item?.name ? true : false
                                    }
                                    isActive={item?.name === isSelectedOption}
                                    isHovered={item?.name === isLinkHovered}
                                  />
                                </div>
                                <p
                                  className={`text-sm font-semibold  font-figtree w-[156px] ${
                                    !isCollapsed ? 'ml-[0]' : ''
                                  } ${
                                    item?.name === isLinkHovered
                                      ? 'text-primaryHover'
                                      : ''
                                  } truncate hover:whitespace-normal whitespace-normal `}
                                >
                                  {item?.name}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                )}
            </>
          );
      });
    }
  }

  useEffect(() => {
    if (
      // location.pathname === props.path ||
      props.subMenu &&
      props.subMenu.filter((option) => option.name === isSelectedOption)
        ?.length > 0
    ) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [location.pathname, props.path, isSelectedOption]);

  // useEffect(() => {
  //   if (props.isCollapsed && isDropDownActive) {
  //     dispatch(ToggleSidebar(!isCollapsed));
  //   }
  // }, [isDropDownActive]);

  // useEffect(() => {
  //   if (props.isCollapsed === true) {
  //     setDropDownActive(false);
  //   }
  // }, [props.isCollapsed]);

  // const isNavLinkActive = (match, location) => {
  //   const pathname = location.pathname;
  //   return (
  //     pathname === props.link ||
  //     pathname.startsWith(props.link + "?") ||
  //     (props.subMenu &&
  //       props.subMenu.filter((option) => option.name === isSelectedOption)?.length > 0)
  //   );
  // };
  const path =
    !props.subMenu && props.itemName.toLowerCase().replace(/ /g, '-');

  const handleSelect = () => {
    props.setDropDownOpenList([props.index]);
    setDropDownActive(props.index);
    if (props.subMenu?.length > 0) {
      setIsSelectedOption(props?.subMenu[0]?.name);
    } else {
      setIsSelectedOption(props.itemName);
    }
  };

  const handleDropDownOpen = (e) => {
    // e.preventDefault();
    props.dropDownOpenList.includes(props.index)
      ? props.setDropDownOpenList(
          props.dropDownOpenList?.filter((item) => item !== props.index),
        )
      : props.setDropDownOpenList([...props.dropDownOpenList, props.index]);
    if (isDropDownActive === props.index) {
      setDropDownActive(null);
    } else {
      setDropDownActive(props.index);
    }
    setIsSubMenuActive(false);
  };

  const isDropDownOpenActive = () =>
    // isDropDownActive === props.index ||
    props.itemName === isSelectedOption ||
    props?.subMenu?.filter((option) => option?.name === isSelectedOption)
      ?.length > 0;

  const isParentActive = () =>
    location.pathname.includes(path) || isNavLinkStyleApplied(isActive);

  return (
    <>
      <div
        className={`flex ${
          props.isCollapsed ? 'w-[46px]' : 'w-[232px]'
        } hover:bg-white min-h-9  h-9 max-h-14 py-1 px-3 items-center gap-2 hover:border border border-gray-50 whitespace-break-spaces hover:border-gray-200 rounded-md cursor-pointer ${
          isParentActive() ? 'bg-gradient h-auto' : ''
        }`}
      >
        <div
          className={`${props.isCollapsed ? 'w-5' : 'w-[180px] '}`}
          onClick={handleSelect}
        >
          <NavLink
            style={navLinkStyles}
            to={`${
              props?.subMenu?.length > 0 ? props?.subMenu?.[0]?.url : props.link
            }`}
            // toDo:: This should be depending upon newTab variable only, Backend data needs to be seeded correctly for sideNav
            target={props?.isQuickLinks ? '_blank' : '_self'}
            className=" flex items-center justify-between hover:border-3 min-h-9 h-9 max-h-14  w-full hover:border-gray-200 !bg-transparent "
            isActive={isParentActive()}
            onMouseEnter={() => setNavPopUpVisible(true)}
            onMouseLeave={() => setNavPopUpVisible(false)}
          >
            <div className="w-full min-h-9 h-9 max-h-14 flex items-center">
              {props.isCollapsed ? (
                <div onClick={handleSelect} className="w-5 h-5">
                  <GetSvgIcon
                    iconName={props?.itemName}
                    isActive={
                      isParentActive() || props.itemName === isSelectedOption
                    }
                    isQuickLinks={props?.isQuickLinks}
                  />
                </div>
              ) : (
                <div
                  onClick={handleSelect}
                  className="flex items-center w-full gap-3"
                >
                  <div className="w-5 h-5">
                    <GetSvgIcon
                      iconName={props?.itemName}
                      isActive={
                        isParentActive() || props.itemName === isSelectedOption
                      }
                      isQuickLinks={props?.isQuickLinks}
                    />
                  </div>

                  <p
                    className={`text-sm font-semibold  font-figtree w-[148px] ${
                      !isCollapsed ? '' : ''
                    } truncate hover:whitespace-normal whitespace-normal`}
                  >
                    {props?.itemName}
                  </p>
                  {/* {props.notificationCount && (
                    <span className="bg-gray-100 h-5 w-fit py-[2px]  flex items-center justify-center rounded-2xl font-medium text-xs text-gray-700 leading-[18px]">
                      {props?.notificationCount}
                    </span>
                  )} */}
                </div>
              )}
              {/* {((navPopUpVisible &&
                props.subMenu?.length > 0 &&
                !isCollapsed) ||
                (navPopUpVisible && isCollapsed)) &&
                isDropDownActive !== props?.index && 
                 
             (
                <div
                  className={`absolute ${
                    isCollapsed ? 'right-[-12.3rem]' : 'right-[-12.5rem]'
                  } z-100`}
                >
                  <NavItemPopUp
                    itemName={props.itemName}
                    subMenu={props.subMenu}
                    isCollapsed={isCollapsed}
                    setDropDownActive={setDropDownActive}
                    setIsSubMenuActive={setIsSubMenuActive}
                    index={props.index}
                    isDropDownActive={isDropDownActive}
                    isLinkHovered={isLinkHovered}
                    isSelectedOption={isSelectedOption}
                    setIsSelectedOption={setIsSelectedOption}
                    setIsLinkHovered={setIsLinkHovered}
                  />
                </div>
              )} */}
            </div>
          </NavLink>
          {/* {props?.subMenu?.length > 0 && !isCollapsed && (
          <div
            className={`${
              isDropDownActive === props?.index
                ? " flex justify-center items-center  w-8"
                : " flex justify-center items-center w-8"
            }`}
            onClick={() => {
              if (isDropDownActive === props.index) {
                setDropDownActive(null);
              } else {
                setDropDownActive(props.index);
              }
              setIsSubMenuActive(false);
            }}
          >
            {props?.subMenu?.length > 0 ? props.subMenuIcon : ""}
          </div>
        )} */}
        </div>
        <div className="flex h-5 w-5 items-center justify-center">
          {props?.subMenu?.length > 0 && !props.isCollapsed && (
            <div
              className={`${
                // isDropDownActive === props?.index
                isDropDownOpen() ? 'rotate-180 ' : '   '
              } flex h-5 w-5 items-center justify-center `}
              onClick={handleDropDownOpen}
            >
              {props?.subMenu?.length > 0 ? (
                <ChevronDownLogo isActive={isDropDownOpenActive()} />
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>
      {props?.subMenu?.length > 0 &&
      // isDropDownActive === props?.index

      isDropDownOpen() ? (
        <div
          className={`${
            props?.isCollapsed ? 'px-3 w-[46px]' : 'px-6 w-[232px]'
          } bg-white border-l border-r  border-b  border-gray-200 py-2 rounded-b-lg`}
        >
          {renderDropDown(
            props.subMenu,
            props.isCollapsed,
            props?.itemName,
            props?.link,
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default NavItem;
