import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import SearchIconInput from 'assets/svg/searchIconInput';
import Select from 'components/atoms/FormElements/select/Select';
import React from 'react';

const DashboardAdminFilters = () => {
  return (
    <div className="border border-gray-200 bg-gray-25 p-4 rounded-lg ">
      <Disclosure>
        <Disclosure.Button className="group flex w-full items-center justify-between">
          <span className="text-gray-600 text-base font-semibold">Filter</span>
          <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
        </Disclosure.Button>
        <Disclosure.Panel className="flex flex-col gap-2 mt-3">
          <hr className="bg-gray-200"></hr>
          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-2">
              <span className="text-xs text-gray-700">Field</span>
              <div className="flex border rounded-lg border-gray-300 bg-white items-center">
                <div className="pl-2">
                  <SearchIconInput />
                </div>
                <Select
                  className="w-15 border-none"
                  placeholder={'Select'}
                  id="remove-border-select"
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-xs text-gray-700">Value</span>
              <div className="border px-3 py-2 rounded-lg border-partition flex ">
                <div
                  style={{
                    background:
                      'linear-gradient(89.75deg, var(--primaryLeft50) 0%, var(--primaryRight50) 98.02%)',
                  }}
                  className="py-[2px] px-[8px] rounded-2xl "
                >
                  <p className="bg-gradient text-transparent bg-clip-text text-center">
                    filter
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Disclosure.Panel>
      </Disclosure>
    </div>
  );
};

export default DashboardAdminFilters;
