// DashboardChartList.js
import { ChartBarIcon, PlusIcon } from '@heroicons/react/24/outline';
import { v4 } from 'uuid';
// import { chartList } from './Chartlist'; // Assuming you have a chartList imported or defined somewhere
import { useEffect, useState } from 'react';
import { useGetChartTypes } from 'query/dashboard/ManageDashboard';
import TableSkeleton from 'components/atoms/skeletonLoaderTypes/TableSkeleton';

const DashboardChartList = ({
  dashboardCards,
  setDashboardCards,
  selectedPage,
  dashboardSettings,
  setDashboardSettings,
}) => {
  const [chartList, setChartList] = useState([]);

  const { data, isLoading, isError } = useGetChartTypes(
    dashboardSettings?.dashboard?.id,
  );

  useEffect(() => {
    if (data) {
      setChartList(data);
    }
  }, [data]);

  const updateParentData = (newData) => {
    const selectedPageData = dashboardCards.map((item) => {
      if (item?.pageIndex === selectedPage) {
        return { ...item, data: newData };
      } else return item;
    });
    setDashboardCards(selectedPageData);
  };

  const onAdd = (item) => {
    const selectedPageData = dashboardCards?.find(
      (item) => item?.pageIndex === selectedPage,
    );
    var newSeparator = {
      i: v4(), // Generate a unique id
      x: 0, // Adjust x position based on the length of the array
      y: 0,
      w: 12,
      h: 2,
      data: { type: 'separator', name: 'Esops', id: 1335 },
    };

    var newText = {
      i: v4(), // Generate a unique id
      x: 0, // Adjust x position based on the length of the array
      y: 0,
      w: 8,
      h: 4,
      data: { type: 'text', description: 'Enter Description', id: 1357 },
    };

    var newCard = {
      i: v4(), // Generate a unique id
      x: 0, // Adjust x position based on the length of the array
      y: 0,
      w: 6,
      h: 15,

      data: { ...item, title: 'Chart Name', description: 'Chart Description' },
    };

    var newTable = {
      i: v4(), // Generate a unique id
      x: 0, // Adjust x position based on the length of the array
      y: 0,
      w: 4,
      h: 20,
      data: { type: 'table', id: 1231 },
    };

    const newLayout = [
      ...selectedPageData.data,
      item === 'addSeparator'
        ? newSeparator
        : item === 'text'
        ? newText
        : item === 'table'
        ? newTable
        : newCard,
    ];
    updateParentData(newLayout);
    //setDashboardCards(newLayout);
  };

  return (
    <div className="relative h-full flex flex-col justify-between">
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <div className="flex flex-col gap-4 mb-12  h-full">
          <div className="border justify-start items-center gap-4 rounded-xl py-1  divide-x border-gray-200 flex">
            <PlusIcon
              className="w-5 h-5 cursor-pointer ml-3"
              onClick={() => onAdd('addSeparator')}
            />

            <div className="p-2 flex justify-between items-center gap-2">
              <ChartBarIcon className="w-5 h-5" />
              Separator
            </div>
          </div>
          <div className="border justify-start items-center gap-4 rounded-xl py-1  divide-x border-gray-200 flex">
            <PlusIcon
              className="w-5 h-5 cursor-pointer ml-3"
              onClick={() => onAdd('text')}
            />

            <div className="p-2 flex justify-between items-center gap-2">
              <ChartBarIcon className="w-5 h-5" />
              Text
            </div>
          </div>
          <div className="border justify-start items-center gap-4 rounded-xl py-1  divide-x border-gray-200 flex">
            <PlusIcon
              className="w-5 h-5 cursor-pointer ml-3"
              onClick={() => onAdd('table')}
            />

            <div className="p-2 flex justify-between items-center gap-2">
              <ChartBarIcon className="w-5 h-5" />
              Table
            </div>
          </div>
          {chartList.map((item, index) => (
            // <Draggable draggableId="item" index={0}>
            //  {(provided) => (
            <div
              key={item?.id}
              //   {...provided.draggableProps}
              //   {...provided.dragHandleProps}
              //  ref={provided.innerRef}
              className="border justify-start items-center gap-4 rounded-xl py-1  divide-x border-gray-200 flex"
            >
              {/* <Button
            className="text-xl flex justify-center items-center ml-2 w-8 "

            // variant="filled"
          > */}
              <PlusIcon
                className="w-5 h-5 cursor-pointer ml-3"
                onClick={() => onAdd(item)}
              />
              {/* <span className='ml-2' onClick={() => onAdd(item)}>
              +
            </span> */}
              {/* </Button> */}

              <div className="p-2 flex justify-between items-center gap-2">
                <ChartBarIcon className="w-5 h-5" />
                {item?.name}
              </div>
            </div>
            //   )}
            // </Draggable>
          ))}
        </div>
      )}
    </div>
  );
};

export default DashboardChartList;
