import Button from 'components/atoms/button/Button';
import { Input } from 'components/atoms/FormElements/input/Input';
import ModalDialogue from 'components/atoms/modalDialogue/ModalDialogue';
import React from 'react';

const ValidateModal = ({ isValidateSelected, setIsValidateSelected }) => {
  return (
    <ModalDialogue
      isModalOpen={isValidateSelected}
      title={
        <span className="text-base text-gray-900 font-semibold">
          Upload Data
        </span>
      }
      onClose={() => setIsValidateSelected(false)}
      width="70%"
    >
      <div className="flex flex-col gap-6">
        <div className="flex flex-col w-1/2">
          <Input label="Data Name" />
        </div>
        <hr className="w-full h-1"></hr>
        <div className="flex flex-col gap-2 -mt-2">
          <span className="text-gray-900 text-xs font-medium leading-7">
            Uploaded Data Preview
          </span>
          <div className="h-72 overflow-auto">
            <table className="w-full">
              <thead className=" bg-gray-50">
                <tr>
                  <th className="text-gray-600 font-figtree text-xs font-medium py-2 px-4 border border-l-0 border-r-0 border-gray-300 text-left">
                    Uploaded Columns
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-gray-900 font-figtree text-sm-13 font-normal py-2 px-4 border border-r-0 border-gray-300  border-l-0">
                    Value 1
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex items-center justify-end gap-2">
          <Button onClick={() => setIsValidateSelected(false)}>Cancel</Button>
          <Button variant="filled">Submit</Button>
        </div>
      </div>
    </ModalDialogue>
  );
};

export default ValidateModal;
