import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import EditIcon from 'assets/svg/editIcon';
import { useEffect, useState } from 'react';
import ConfigureDashboard from './Configuration/ConfigureDashboard';
import DashboardCanvas from './DashboardCanvas';
import {
  useCreatePage,
  useGetPage,
  useUpdateDashboardData,
  useUpdatePage,
} from 'query/dashboard/ManageDashboard';
import { onResponse } from 'utils/toastMessages';
import { Input } from 'components/atoms/FormElements/input/Input';
import { on } from 'events';

export default function CreateDashboard({
  module,
  containerHeight,
  dashboardCards,
  setDashboardCards,
  dashboardSettings,
  setDashboardSettings,
  refetchDashboardData,
}) {
  const [selectedPage, setSelectedPage] = useState(0);
  const [configFor, setConfigFor] = useState('default');
  const [isSidePanelOpen, setSidePanelOpen] = useState(false);
  const [isPagePanelOpen, setPagePanelOpen] = useState(false);
  const [editingPageId, setEditingPageId] = useState(null);
  const [deletedPageId, setDeletedPageId] = useState(null);
  const [individualPageData, setIndividualPageData] = useState({});
  const [selectedPageId, setSelectedPageId] = useState(null);

  const maxPageIndex = Math.max(
    ...(dashboardSettings?.pages?.map((page) => page?.index) ?? []),
    0,
  );

  const { mutateAsync: addNewPage } = useCreatePage({
    index: `${maxPageIndex + 1}`,
    name: `Page ${maxPageIndex + 1}`,
    id: dashboardSettings?.dashboard?.id,
  });

  const { mutateAsync: updateDashboard } = useUpdateDashboardData(
    { deletedPages: [deletedPageId] },
    dashboardSettings?.dashboard?.id,
  );

  const { mutateAsync: updatePage } = useUpdatePage();

  const {
    data: pageData,
    isLoading,
    isError,
    refetch: refetchPageData,
  } = useGetPage(dashboardSettings?.dashboard?.id, selectedPage);

  useEffect(() => {
    if (pageData) {
      setIndividualPageData(pageData);
      setSelectedPageId(pageData?.id);
    }
  }, [selectedPage, pageData]);

  const deletePage = async (pageId) => {
    setDeletedPageId(pageId);
  };

  useEffect(() => {
    const deletePageEffect = async () => {
      if (deletedPageId !== null) {
        try {
          await updateDashboard();
          refetchDashboardData();
          setDeletedPageId(null);
          onResponse('Page deleted successfully');
        } catch (err) {
          onResponse(err?.message);
        }
      }
    };

    deletePageEffect();
  }, [deletedPageId]);

  useEffect(() => {
    if (configFor !== 'default') {
      const selectedChart = dashboardCards
        ?.find((item) => item?.pageIndex === selectedPage)
        ?.data.find((item) => item?.i === configFor?.i);
      setConfigFor(selectedChart);
    }
  }, [dashboardCards]);
  //{ i: 'chart1', x: 0, y: 0, w: 6, h: 4, data: null }

  // Function to handle drag end event
  // Function to handle drag end event
  const onDragEnd = (result) => {
    //console.log(result);
    // Check if the drag was completed successfully
    // if (!result.destination) {
    //   return;
    // }
    // const { source, destination } = result;
    // // Ensure source and destination are valid
    // if (!destination) {
    //   return;
    // }
    // // If dropped outside the valid drop area
    // if (
    //   source.droppableId === destination.droppableId &&
    //   source.index === destination.index
    // ) {
    //   return;
    // }
    // // Reorder the dashboardCards array based on drag and drop result
    // const items = Array.from(dashboardCards);
    // const [reorderedItem] = items.splice(source.index, 1);
    // items.splice(destination.index, 0, reorderedItem);
    // // Append a new item after the drag operation
    // setDashboardCards((prevCards) => {
    //   const newCard = {
    //     i: `chart${prevCards.length + 1}`, // Generate a unique id
    //     x: prevCards.length * 6, // Adjust x position based on the length of the array
    //     y: Infinity,
    //     w: 6,
    //     h: 4,
    //   };
    //   return [...items, newCard];
    // });
  };

  const handlePageNameChange = (e, pageIndex) => {
    const newPageName = e.target.value;
    setDashboardCards((prevCards) =>
      prevCards.map((card) =>
        card.pageIndex === pageIndex
          ? { ...card, pageName: newPageName }
          : card,
      ),
    );
  };

  const addPage = async () => {
    try {
      await addNewPage();
      refetchDashboardData();
    } catch (error) {
      onResponse(error?.message);
    }
  };

  return (
    <div className="relative">
      {/* <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}> */}
      <div className={` flex w-full relative h-screen ${containerHeight} `}>
        <div className=" flex absolute top-0 z-50 h-full left-30">
          {isPagePanelOpen && (
            <div className="bg-white shadow-lg w-72 p-6 ">
              <p className="text-base font-semibold pb-4 flex justify-between w-full items-center">
                Pages{' '}
                <PlusIcon
                  className="w-5 h-5 cursor-pointer"
                  onClick={addPage}
                />
              </p>
              <div className=" p-4  pl-0 h-[87%] overflow-y-auto">
                <div className="flex flex-col gap-6 justify-between">
                  {dashboardSettings?.pages?.map((item, index) => (
                    <div
                      key={index}
                      className={
                        selectedPage === item?.index
                          ? 'border-b-2 border-black cursor-pointer flex justify-between'
                          : 'flex justify-between'
                      }
                    >
                      <div onClick={() => setSelectedPage(item?.index)}>
                        {editingPageId === item?.id ? (
                          <Input
                            type="text"
                            value={item?.name}
                            onChange={(e) => handlePageNameChange(e, item?.id)}
                            onBlur={() => setEditingPageId(null)}
                            autoFocus
                          />
                        ) : (
                          <span className="cursor-pointer">{item?.name}</span>
                        )}
                      </div>
                      <div className="flex justify-between">
                        <EditIcon onClick={() => setEditingPageId(item?.id)} />
                        <div onClick={() => deletePage(item?.id)}>
                          <TrashIcon className="w-5 h-5 cursor-pointer text-gray-500" />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div
            onClick={() => setPagePanelOpen(!isPagePanelOpen)}
            className="bg-white right-0 justify-center border border-l-0 h-8 w-8 shadow flex items-center border-gray-300 rounded-r-md cursor-pointer relative top-4"
          >
            {isPagePanelOpen ? (
              <ChevronLeftIcon className="h-6 w-6" />
            ) : (
              <ChevronRightIcon className="w-5 h-5" />
            )}
          </div>
        </div>
        <div className="w-full overflow-auto">
          <DashboardCanvas
            configFor={configFor}
            setConfigFor={setConfigFor}
            selectedPage={selectedPage}
            layout={dashboardCards}
            setSidePanelOpen={setSidePanelOpen}
            setLayout={setDashboardCards}
            dashboardSettings={dashboardSettings?.pages || []}
            individualPageData={individualPageData}
          />
        </div>

        <div
          className={`flex absolute top-0 h-full right-0`}
          // ${
          //     module === 'default' ? 'top-[56px] right-0' : 'right-0'
          //   }
        >
          <div
            onClick={() => setSidePanelOpen(!isSidePanelOpen)}
            className="bg-white justify-center border border-r-0 h-8 w-8 shadow flex items-center border-gray-300 rounded-l-md cursor-pointer relative top-4"
          >
            {isSidePanelOpen ? (
              <ChevronRightIcon className="w-5 h-5" />
            ) : (
              <ChevronLeftIcon className="h-6 w-6" />
            )}
          </div>
          {isSidePanelOpen && (
            <div className=" bg-white h-full">
              <ConfigureDashboard
                dashboardCards={dashboardCards}
                setDashboardCards={setDashboardCards}
                selectedPage={selectedPage}
                configFor={configFor}
                dashboardSettings={dashboardSettings || []}
                setDashboardSettings={setDashboardSettings}
                individualPageData={individualPageData}
                setIndividualPageData={setIndividualPageData}
                selectedPageId={selectedPageId}
              />
            </div>
          )}
        </div>
      </div>

      {/* {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext> */}
    </div>
  );
}
