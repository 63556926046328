import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { fetchPlanById } from 'apiClient/rangeBuilder/rangeBuilder';
import { Input, RadioButton } from 'components/atoms/FormElements/input/Input';
import { FilterTabs } from 'pages/rangeBuilder/finalRange/benchmarks/FilterTabs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const RowSubComponent = ({
  row,
  isEditable,
  rowId,
  setUpdatedFinalCalc,
  updatedFinalCalc,
  outputMode,
  selectedFilterTab,
  setSelectedFilterTab,
  calculations,
  setCalculations,
}) => {
  const { id } = useParams();
  const [filterTabs, setFilterTabs] = useState(['Min', 'Mid', 'Max']);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchPlanById(id);
        setCalculations(response?.data?.calculations);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const handleCalculations = (event, type, benchmarkName) => {
    setCalculations(
      calculations?.map((calculation) => {
        if (calculation?.benchmark === benchmarkName) {
          return {
            ...calculation,
            values: calculation?.values?.map((value) => {
              if (value?.point === type) {
                return { ...value, percentValue: event.target.value };
              }
              return value;
            }),
          };
        }
        return calculation;
      }),
    );
  };

  useEffect(() => {
    setUpdatedFinalCalc({ ...updatedFinalCalc, calculations });
  }, [calculations]);

  return (
    <>
      {outputMode === 'IndividualPercentilePointReferences' && (
        <FilterTabs
          filterTabs={filterTabs}
          selectedFilterTab={selectedFilterTab}
          setSelectedFilterTab={setSelectedFilterTab}
        />
      )}
      <div className="px-[14px] flex items-start gap-3">
        <div className="p-1 border border-gray-200 rounded-md w-2/3">
          {Object.entries(row.original?.pivotData || {}).map((entry) => {
            return (
              <div
                key={entry?.[0]}
                className="border-b border-gray-200 flex items-center"
              >
                <p className="text-sm font-medium text-gray-900 px-4 w-1/2">
                  {entry[0]}
                </p>
                <p className="text-sm text-gray-600 px-4 py-2 w-1/2">
                  {entry[1]}
                </p>
              </div>
            );
          })}
          <div className=" flex items-center border-b border-gray-200">
            <p className="text-sm font-medium text-gray-900 px-4 w-1/2">
              Aging
            </p>
            <p className="text-sm text-gray-600 px-4 py-2 w-1/2">
              {row.original?.aging}
            </p>
          </div>
          <div className=" flex items-center">
            <p className="text-sm font-medium text-gray-900 px-4 w-1/2">
              Adjustment
            </p>
            {!isEditable && (
              <p className="text-sm text-gray-600 px-4 py-2 w-1/2">
                {row.original?.adjustment}
              </p>
            )}
            {isEditable && (
              <div className="w-1/2">
                <Input defaultValue={row.original?.adjustment} />
              </div>
            )}
          </div>
        </div>
        <div className="p-1 flex items-center border border-gray-200 rounded-md w-full">
          <div className="flex flex-col justify-center grow">
            {row.original?.labels?.map((label) => {
              return (
                <p
                  key={label}
                  className="py-2 font-medium text-sm text-gray-900 border-b border-gray-200 pl-4"
                >
                  {row.original?.pValueMap?.[label] || label}
                </p>
              );
            })}
            {isEditable && (
              <div className="space-y-2">
                <div className="flex flex-col py-2 ">
                  <p className="font-medium text-sm text-gray-900 pl-4">Mid</p>
                  <p className="text-xs text-gray-600 pl-4">Adjustments</p>
                </div>
                <div className="flex flex-col py-2 ">
                  <p className="font-medium text-sm text-gray-900 pl-4">Min</p>
                  <p className="text-xs text-gray-600 pl-4">{`(as % of Mid)`}</p>
                </div>
                <div className="flex flex-col py-2 ">
                  <p className="font-medium text-sm text-gray-900 pl-4">Max</p>
                  <p className="text-xs text-gray-600 pl-4">{`(as % of Mid)`}</p>
                </div>
              </div>
            )}
          </div>

          {Object.entries(row.original.benchmarkMap || {}).map((benchmark) => {
            return (
              <div
                key={benchmark[0]}
                className="grow flex flex-col justify-center"
              >
                <p
                  className={`py-2 border-b border-gray-200 font-medium text-gray-900 ${
                    isEditable ? 'pl-4' : ''
                  }`}
                >
                  {benchmark[1]}
                </p>
                {row.original?.[`${benchmark[0]}_pValue`]?.data?.map((data) => {
                  return (
                    <div
                      key={data?.label}
                      className="flex items-center border-b border-gray-200 gap-1"
                    >
                      {isEditable && (
                        <RadioButton
                          checked={
                            data?.label ===
                            row.original.selectedPvalueInBenchmark?.[
                              benchmark[0]
                            ]?.[selectedFilterTab]
                          }
                          // onChange={(event) => handleRadioButtonChange(event)}
                        />
                      )}
                      <p key={data?.point} className="py-2 ">
                        {data?.value}
                      </p>
                      {data?.label ===
                        row.original.selectedPvalueInBenchmark?.[
                          benchmark[0]
                        ]?.[selectedFilterTab] &&
                        !isEditable && (
                          <CheckCircleIcon className="w-4 h-4 text-success-500" />
                        )}
                    </div>
                  );
                })}

                {isEditable && (
                  <div className="w-1/2 py-[10px] space-y-3 pl-4">
                    <Input
                      defaultValue={
                        calculations
                          ?.filter(
                            (calculation) =>
                              calculation?.benchmark === benchmark[1],
                          )?.[0]
                          ?.values?.filter(
                            (value) => value?.point === 'Mid',
                          )?.[0]?.percentValue
                      }
                      onChange={(event) =>
                        handleCalculations(event, 'Mid', benchmark[1])
                      }
                    />
                    <Input
                      defaultValue={
                        calculations
                          ?.filter(
                            (calculation) =>
                              calculation?.benchmark === benchmark[1],
                          )?.[0]
                          ?.values?.filter(
                            (value) => value?.point === 'Min',
                          )?.[0]?.percentValue
                      }
                      onChange={(event) =>
                        handleCalculations(event, 'Min', benchmark[1])
                      }
                    />
                    <Input
                      defaultValue={
                        calculations
                          ?.filter(
                            (calculation) =>
                              calculation?.benchmark === benchmark[1],
                          )?.[0]
                          ?.values?.filter(
                            (value) => value?.point === 'Max',
                          )?.[0]?.percentValue
                      }
                      onChange={(event) =>
                        handleCalculations(event, 'Max', benchmark[1])
                      }
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RowSubComponent;
