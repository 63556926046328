import {
  CloudArrowDownIcon,
  DocumentCheckIcon,
} from '@heroicons/react/24/outline';
import Button from 'components/atoms/button/Button';
import ModalDialogue from 'components/atoms/modalDialogue/ModalDialogue';
import React, { useState } from 'react';
import ValidateModal from './ValidateModal';

const PreviewModal = ({ setIsPreviewSelected, isPreviewSelected }) => {
  const [isValidateSelected, setIsValidateSelected] = useState(false);

  return isValidateSelected ? (
    <ValidateModal
      isValidateSelected={isValidateSelected}
      setIsValidateSelected={setIsValidateSelected}
    />
  ) : (
    <ModalDialogue
      isModalOpen={isPreviewSelected}
      onClose={() => setIsPreviewSelected(false)}
      width="70%"
    >
      <div className="flex flex-col justify-between gap-5 mt-5">
        <div className="flex justify-between items-center">
          <span className="text-base text-gray-900 font-semibold">
            Data Preview
          </span>
          <div className="flex justify-between items-center gap-2">
            <Button className="flex justify-between gap-2">
              <CloudArrowDownIcon className="w-5 h-5 text-gray-900" />
              <span>Download</span>
            </Button>
            <Button
              className="flex justify-between gap-2"
              onClick={() => setIsValidateSelected(true)}
            >
              <DocumentCheckIcon className="w-5 h-5 text-gray-900" />
              <span>Validate</span>
            </Button>
          </div>
        </div>
        <div className="w-full">
          <table className="w-full">
            <thead className=" bg-gray-50">
              <tr>
                <th className="text-gray-600 font-figtree text-xs font-medium py-3 pl-2.5 pr-4 border border-l-0 border-gray-300 text-left">
                  Uploaded Columns
                </th>
                <th className=" text-gray-600 font-figtree text-xs font-medium py-3 pl-2.5 pr-4 border border-l-0 border-gray-300 text-left">
                  Sample Data
                </th>
                <th className=" text-gray-600 font-figtree text-xs font-medium py-3 pl-2.5 pr-4 border border-gray-300 border-r-0 border-l-0 text-left">
                  Uploaded DataType
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-gray-600 font-figtree text-xs font-normal py-3 pl-2.5 pr-4 border border-gray-300  border-l-0">
                  Value 1
                </td>
                <td className=" text-gray-600 font-figtree text-xs font-medium py-3 pl-2.5 pr-4 border border-gray-300 border-l-0">
                  Value 2
                </td>
                <td className=" text-gray-600 font-figtree text-xs font-medium py-3 pl-2.5 pr-4 border border-gray-300 border-r-0 border-l-0">
                  Value 3
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ModalDialogue>
  );
};

export default PreviewModal;
