import DashboardContent from './dashboardContent/DashboardContent';
import ViewDashboardHeader from './viewDashboardHeader/ViewDashboardHeader';

export default function ViewDashboard({ dashboardSettings, layout }) {
  return (
    <div className="flex flex-col gap-4 p-3">
      <ViewDashboardHeader />
      <DashboardContent dashboardSettings={dashboardSettings} layout={layout} />
    </div>
  );
}
