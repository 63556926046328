import {
  CloudArrowDownIcon,
  DocumentTextIcon,
  PencilSquareIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import CircleIcon from 'assets/svg/CircleIcon';
import GradientCloudDownloadIcon from 'assets/svg/GradientCloudDownloadIcon';
import ScalesIcon from 'assets/svg/ScalesIcon';
import Button from 'components/atoms/button/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ViewDashboardHeader = () => {
  const navigate = useNavigate();

  return (
    <div className="border bg-white rounded-xl flex flex-col shadow">
      <div className="px-6 py-3 justify-between flex items-center">
        <span className="text-xl text-gray-900 font-semibold">Report Name</span>
        <div className="flex justify-between items-center gap-2">
          <Button className="flex justify-between gap-2 items-center">
            <PencilSquareIcon className="w-5 h-5 text-gray-700 stroke-2" />
            <span className="text-sm font-semibold text-gray-700">
              Edit Dashboard
            </span>
          </Button>
          <Button
            className="flex justify-between gap-2 items-center"
            onClick={() => navigate('/compareCharts')}
          >
            <ScalesIcon className="w-5 h-5 text-gray-900 items-center" />
            <span className="text-sm font-semibold text-gray-700">Compare</span>
          </Button>
          <Button className="flex justify-between gap-2 items-center">
            <DocumentTextIcon className="w-5 h-5 text-gray-700 stroke-2" />
            <span className="text-sm font-semibold text-gray-700">
              Go To Report
            </span>
          </Button>
          <Button
            variant="outlined"
            className="flex justify-between gap-2 items-center"
          >
            <GradientCloudDownloadIcon />
            <span className="text-sm font-semibold text-gray-700">
              Download
            </span>
          </Button>
        </div>
      </div>
      <div className="flex items-center gap-2 px-6 bg-gray-50 border border-gray-100 rounded-xl rounded-t-none border-r-0 border-l-0">
        <div className="flex items-center gap-2 py-2">
          <span className="text-transparent cursor-pointer bg-clip-text bg-gradient-to-br from-primaryLeft to-primaryRight text-sm font-medium">
            X
          </span>
          <span className="text-transparent cursor-pointer bg-clip-text bg-gradient-to-br from-primaryLeft to-primaryRight font-medium text-sm">
            Clear All
          </span>
          <span className="text-base text-gray-900 font-medium pl-2">
            Filter:
          </span>
        </div>
        <div className="bg-white h-full flex gap-3 px-4 items-center">
          <CircleIcon />
          <div className="flex flex-col justify-between">
            <span className="pt-1.5 pb-0.5 text-gray-900 text-xs font-medium">
              hello
            </span>
            <span className="pb-1.5 text-gray-600 text-xs">xsn</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDashboardHeader;
