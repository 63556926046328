import React from 'react';

const UploadCloud = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M2.33341 9.47463C1.62992 9.00373 1.16675 8.20179 1.16675 7.29167C1.16675 5.92458 2.21179 4.80159 3.5466 4.67797C3.81964 3.01708 5.26189 1.75 7.00008 1.75C8.73827 1.75 10.1805 3.01708 10.4536 4.67797C11.7884 4.80159 12.8334 5.92458 12.8334 7.29167C12.8334 8.20179 12.3702 9.00373 11.6667 9.47463M4.66675 9.33333L7.00008 7M7.00008 7L9.33342 9.33333M7.00008 7V12.25"
        stroke="#475467"
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadCloud;
