export const queryBuilderFormatterForAPI = (query) => {
  if (query?.combinator === 'or' || query?.combinator === 'and') {
    const rules = {
      [query?.combinator?.toUpperCase()]: [],
    };
    query?.rules?.forEach((child) => {
      if (child?.rules) {
        const condition = queryBuilderFormatterForAPI(child);
        if (condition) {
          if (rules[query?.combinator?.toUpperCase()]) {
            rules[query?.combinator?.toUpperCase()].push(condition);
          }
        }
      } else if (child?.field && child?.operator && child?.value) {
        const criteria = child?.operator;

        const operatorCode = child?.operator?.code || '';

        if (criteria) {
          const condition = {
            [operatorCode]: [child?.field, child?.value?.value || child?.value],
          };
          if (rules[query?.combinator?.toUpperCase()]) {
            rules[query?.combinator?.toUpperCase()].push(condition);
          }
        }
      }
    });
    return rules;
  } else {
    return {};
  }
};
export function queryBuilderFormatterForUI(
  formatObject,
  parsedData,
  operatorsList,
) {
  if (!parsedData) {
    return null;
  }

  function findObjectByValue(formatObject, targetValue) {
    // if (targetValue === 'employeeId') {
    //   return {
    //     info: {
    //       sourceName: 'initial',
    //       canSelect: true,
    //       canExpand: false,
    //     },
    //     data: {
    //       fieldId: 'employeeId',
    //       alias: 'Employee ID',
    //       chainAlias: ['Employee', 'Employee ID'],
    //       dataType: 'Text',
    //     },
    //   };
    // } else {
    const result = Object?.values(formatObject)?.find((obj) => {
      // if (obj?.id?.includes(':')) {
      //   const [firstPart, secondPart] = obj?.id?.split(':');
      //   // console.log(secondPart, targetValue);
      //   return secondPart === targetValue;
      // } else {
      return obj?.uuidNew === targetValue;
      // }
    });

    return result || null;
    // }
  }
  function findOperatorObject(list, value) {
    // const operator = value?.startsWith('worksheet-')
    //   ? value?.substring(10)
    //   : value;
    return list?.find((item) => item?.code === value);
    // const operator = value?.startsWith('worksheet-')
    //   ? value?.substring(10)
    //   : value;
    // return list?.find((item) => item?.code === operator);
  }

  const getFormattedData = (object, data) => {
    const type = Object.keys(data)[0]; // Get the type (OR or AND)
    if (type === 'OR' || type === 'AND') {
      const children = data?.[type]?.map((child) => {
        const [keys, values] = Object?.entries(child || {})[0] || [null, null];

        if (keys === 'OR' || keys === 'AND') {
          // Recursive call for nested OR/AND object
          return getFormattedData(object, child);
        } else {
          const fieldData = findObjectByValue(object, values[0]);
          // const valueData = findObjectByValue(object, values[1]);
          const operatorObject = findOperatorObject(operatorsList, keys);
          // const deparsedValue = valueData
          //   ? {
          //       ...valueData,
          //       name: valueData?.id,
          //       label: valueData?.value,
          //     }
          //   : values[1];
          return {
            operator: operatorObject,
            field: fieldData?.field,
            // field: {
            //   ...fieldData,
            //   // name: fieldData?.id,
            //   // dataType: fieldData?.columnType,
            //   // label: fieldData?.value,
            //   // alias: fieldData?.value,
            // },
            uuidNew: values[0],
            value: fieldData?.value,
          };
        }
      });
      return {
        combinator: type?.toLowerCase(),
        rules: children,
      };
    }
  };

  const finalData = parsedData?.map((data) => {
    const transformedData = {};
    for (const [key, value] of Object.entries(data)) {
      if (key === 'If' || key === 'Else/If' || key === 'Else') {
        transformedData.conditionType = key;
        transformedData.queryData = getFormattedData(formatObject, value);
      } else if (key === 'then') {
        transformedData.description = value;
      }
    }
    return transformedData;
  });

  // console.log('Your deparsed data is', finalData);
  return finalData;
}
