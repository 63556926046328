import { useEffect, useState } from 'react';
import CreateDashboard from './createDashboard/CreateDashboard';
import ViewDashboard from './viewDashboard/ViewDashboard';
import { useGetDashboardData } from 'query/dashboard/ManageDashboard';
import { useParams } from 'react-router-dom';

export default function Dashboard({ module = 'default', containerHeight }) {
  const [dashboardCards, setDashboardCards] = useState([]);
  const [dashboardSettings, setDashboardSettings] = useState([]);
  const [mode, setMode] = useState('create');

  const payload = {
    sourceId: 'b773ef40-b8ec-4acf-bd10-ff25507ad6f7',
    sourceName: 'reportBuilder',
  };

  const {
    data,
    isLoading,
    isError,
    refetch: refetchDashboardData,
  } = useGetDashboardData(payload);

  useEffect(() => {
    if (data) {
      setDashboardSettings(data);
    }
  }, [data]);

  return (
    <div className="p-1 w-full h-full">
      {mode === 'create' ? (
        <CreateDashboard
          module={module}
          containerHeight={containerHeight}
          dashboardCards={dashboardCards}
          setDashboardCards={setDashboardCards}
          dashboardSettings={dashboardSettings || []}
          setDashboardSettings={setDashboardSettings}
          refetchDashboardData={refetchDashboardData}
        />
      ) : (
        <ViewDashboard
          module={module}
          dashboardSettings={dashboardSettings}
          layout={dashboardCards}
        />
      )}
    </div>
  );
}
