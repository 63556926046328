import React, { useCallback, useEffect, useState } from 'react';
import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import Button from 'components/atoms/button/Button';
import Select from 'components/atoms/FormElements/select/Select';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getCompensationFields,
  getOfferTemplate,
  getReleaseOfferDetails,
  previewHiringTemplate,
  releaseOffer,
} from 'apiClient/hiringOffer/hiringOffer';
import moment from 'moment';
import { onResponse } from 'utils/toastMessages';
import ButtonLoader from 'assets/svg/buttonLoader';
import PdfViewer from 'components/atoms/pdfViewer/PDFViewer';
import { imageURLCheck } from 'utils/ImageUrlCheck';
import { convertIntoSpace, convertUnderscoreToSpace } from 'utils/jsUtils';

const ReleaseOffer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const versionId = queryParams.get('versionId');
  const [offerDetailsData, setOfferDetailsData] = useState();
  const [releaseLoading, setReleaseLoading] = useState(false);
  const [isPreviewed, setIsPreviewed] = useState(false);
  const [previewData, setPreviewData] = useState();
  const [previewLoading, setPreviewLoading] = useState(false);
  const [filteredCompensationData, setFilteredCompensationData] = useState([]);
  const [selectedOfferTemplate, setSelectedOfferTemplate] = useState({});
  const [offerTemplateList, setOfferTemplateList] = useState([]);
  const [compFields, setCompFields] = useState([]);
  const hiringId = queryParams.get('hiringId');
  // const fetchReleaseOfferDetails = async (vId, hId) => {
  //   try {
  //     const data = await getReleaseOfferDetails(vId, hId);
  //     return data;
  //   } catch (err) {
  //     console.log(err);
  //     return [];
  //   }
  // };
  const fetchOfferTemplate = async () => {
    try {
      const data = await getOfferTemplate();
      return data;
    } catch (err) {
      console.log(err);
      return [];
    }
  };
  const fetchCompensationFields = async () => {
    try {
      const data = await getCompensationFields();
      return data;
    } catch (err) {
      console.log(err);
      return [];
    }
  };
  // useEffect(() => {
  //   if (versionId && hiringId) {
  //     fetchReleaseOfferDetails(versionId, hiringId)
  //       ?.then((res) => {
  //         setOfferDetailsData(res?.data);
  //       })
  //       ?.catch((err) => console.log(err));
  //   }
  // }, [versionId, hiringId]);
  const fetchReleaseOfferDetails = useCallback(async (vId, hId) => {
    try {
      const data = await getReleaseOfferDetails(vId, hId);
      return data;
    } catch (err) {
      console.log(err);
      return [];
    }
  }, []);

  useEffect(() => {
    if (versionId && hiringId) {
      fetchReleaseOfferDetails(versionId, hiringId)
        .then((res) => {
          setOfferDetailsData(res?.data);
        })
        .catch((err) => console.log(err));
    }
  }, [versionId, hiringId]);
  useEffect(() => {
    fetchCompensationFields()
      .then((res) => {
        if (res?.data && res?.data?.length > 0) {
          setCompFields(res?.data);
        }
      })
      .catch((err) => console.log(err));
    fetchOfferTemplate()
      .then((res) => {
        if (res?.data && res?.data?.length > 0) {
          setOfferTemplateList(getFormattedList(res?.data));
        }
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    if (offerDetailsData) {
      const newData = compFields
        ?.map((field) => {
          const headerData =
            offerDetailsData?.compSideNavDetails[field?.codeId];
          const bodyItem =
            offerDetailsData?.hiringProposal?.[0]?.componsationProposal?.body[
              field?.codeId
            ];
          if (bodyItem) {
            return {
              fieldData: field,
              compData: { body: bodyItem, header: headerData },
            };
          }
          return null;
        })
        ?.filter(Boolean);
      setFilteredCompensationData(newData);
    }
  }, [offerDetailsData, compFields]);
  const getFieldName = (code) => {
    switch (code) {
      case 'B':
        return 'Benefits';
      case 'FS':
        return 'Base Pay';
      case 'V':
        return 'Variable Pay';
      case 'LTI':
        return 'Total Grant Value';
      case 'OneTimePayout':
        return 'OneTime Payout';
      default:
        break;
    }
  };
  const getFormattedList = (list) => {
    return (
      list &&
      list?.map((option) => {
        return {
          value: option.id,
          label: option?.name,
          ...option,
        };
      })
    );
  };
  const handleReleaseClick = () => {
    setReleaseLoading(true);
    const storedOrgDetails = localStorage.getItem('orgDetails');
    const parsedOrgDetails = storedOrgDetails
      ? JSON.parse(storedOrgDetails)
      : null;
    const body = {
      hiringId: hiringId,
      versionId: versionId,
      organizationName: parsedOrgDetails?.orgName,
      organizationLogoUrl: parsedOrgDetails?.organizationDetails?.logo,
    };
    releaseOffer(body)
      .then((res) => {
        if (res?.statusCode === 200 || res?.statusCode === 201) {
          onResponse('Offer Released Successfully');
          navigate('/offer');
        }
      })
      .catch((err) => onResponse(err?.message))
      .finally(() => setReleaseLoading(false));
  };
  const handlePreviewClick = async () => {
    setPreviewLoading(true);
    try {
      if (selectedOfferTemplate?.id && hiringId && versionId) {
        const res = await previewHiringTemplate(
          selectedOfferTemplate?.id,
          hiringId,
          versionId,
        );
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }

        const blob = await res.blob();
        const url = URL.createObjectURL(blob);
        setPreviewData(url);
        setPreviewLoading(false);
        setIsPreviewed(true);
      }
    } catch (err) {
      setPreviewLoading(false);
      onResponse(err?.message);
    }
  };

  return (
    <React.Fragment>
      <div className="p-4 bg-white flex flex-col gap-4 shadow-md border-b">
        {' '}
        <div className="flex gap-4 items-center justify-between">
          <div className="flex items-center gap-3">
            <div
              className="border-2 rounded-md p-2 w-fit cursor-pointer"
              onClick={() => navigate('/offer')}
            >
              <ArrowSmallLeftIcon className="w-4 h-4" />
            </div>
            <div className="flex flex-col">
              <p className="text-xl text-gray-900 font-semibold">
                Release Offer Letter
              </p>
              <p className="text-sm text-gray-700">
                Offer Id:{' '}
                {offerDetailsData?.applicationId
                  ? offerDetailsData?.applicationId
                  : '-'}{' '}
                | Position Id:{' '}
                {offerDetailsData?.positionId
                  ? offerDetailsData?.positionId
                  : '-'}{' '}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <Button
              variant="filled"
              onClick={handleReleaseClick}
              disabled={
                releaseLoading || !isPreviewed || !selectedOfferTemplate?.id
              }
            >
              {releaseLoading ? (
                <>
                  <ButtonLoader /> Releasing{' '}
                </>
              ) : (
                'Release Offer'
              )}
            </Button>
          </div>
        </div>
      </div>
      <div className="w-full flex h-full">
        <div className="w-2/5 bg-gray-50 p-4 flex flex-col gap-4">
          <div className="bg-white p-4 flex flex-col gap-2 border shadow-md rounded-lg">
            <p className="text-xl font-semibold">Candidate Details</p>
            <div className="grid grid-cols-2 items-start gap-2">
              <div className="flex flex-col items-start justify-start w-full">
                <p className="text-gray-600">Application Id</p>
                <p className="font-semibold truncate w-full">
                  {offerDetailsData?.candidateDetails?.applicationId
                    ? offerDetailsData?.candidateDetails?.applicationId
                    : '-'}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <p className="text-gray-600">Applicant Name</p>
                <p className="font-semibold truncate w-full">
                  {' '}
                  {offerDetailsData?.candidateDetails?.applicationFullName
                    ? offerDetailsData?.candidateDetails?.applicationFullName
                    : '-'}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start">
                <p className="text-gray-600">Experiance</p>
                <p className="font-semibold">
                  {' '}
                  {offerDetailsData?.candidateDetails?.experiance
                    ? offerDetailsData?.candidateDetails?.experiance
                    : '-'}{' '}
                  Years
                </p>
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <p className="text-gray-600">Applicant Email</p>
                <p className="font-semibold truncate w-full">
                  {' '}
                  {offerDetailsData?.candidateDetails?.applicationEmail
                    ? offerDetailsData?.candidateDetails?.applicationEmail
                    : '-'}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white p-4 flex flex-col gap-2 border shadow-md rounded-lg">
            <p className="text-xl font-semibold">Job Details</p>
            <div className="grid grid-cols-2 items-start gap-2">
              <div className="flex flex-col items-start justify-start w-full">
                <p className="text-gray-600">Hiring Manager</p>
                <p className="font-semibold w-full truncate">
                  {offerDetailsData?.jobDetails?.hiringManager
                    ? offerDetailsData?.jobDetails?.hiringManager?.label
                    : '-'}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start">
                <p className="text-gray-600">Date Of Joining</p>
                <p className="font-semibold">
                  {' '}
                  {offerDetailsData?.jobDetails?.doj
                    ? moment(offerDetailsData?.jobDetails?.doj)?.format(
                        'DD MMM, YYYY',
                      )
                    : '-'}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <p className="text-gray-600">Employment Type</p>
                <p className="font-semibold w-full truncate">
                  {' '}
                  {offerDetailsData?.jobDetails?.employmentType
                    ? offerDetailsData?.jobDetails?.employmentType?.label
                    : '-'}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <p className="text-gray-600">Job Code</p>
                <p className="font-semibold w-full truncate">
                  {' '}
                  {offerDetailsData?.jobDetails?.jobCode
                    ? offerDetailsData?.jobDetails?.jobCode?.label
                    : '-'}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <p className="text-gray-600">Job Title</p>
                <p className="font-semibold w-full truncate">
                  {' '}
                  {offerDetailsData?.jobDetails?.jobFamily
                    ? offerDetailsData?.jobDetails?.jobFamily?.label
                    : '-'}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <p className="text-gray-600">Currency</p>
                <p className="font-semibold w-full truncate">
                  {' '}
                  {offerDetailsData?.jobDetails?.currency
                    ? offerDetailsData?.jobDetails?.currency?.label
                    : '-'}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <p className="text-gray-600">Job Location</p>
                <p className="font-semibold w-full truncate">
                  {' '}
                  {offerDetailsData?.jobDetails?.worklocation
                    ? offerDetailsData?.jobDetails?.worklocation?.label
                    : '-'}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <p className="text-gray-600">Work Arrangement</p>
                <p className="font-semibold w-full truncate">
                  {' '}
                  {offerDetailsData?.jobDetails?.workArrangement
                    ? offerDetailsData?.jobDetails?.workArrangement?.label
                    : '-'}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white p-4 flex flex-col gap-2 border shadow-md rounded-lg">
            <p className="text-xl font-semibold">Version Details</p>
            <div className="grid grid-cols-2 items-start gap-2">
              <div className="flex flex-col items-start justify-start">
                <p className="text-gray-600">Version</p>
                <p className="font-semibold">
                  Version{' '}
                  {offerDetailsData?.hiringProposal?.[0]?.versionName || '-'}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start">
                <p className="text-gray-600">Proposal</p>
                <p className="font-semibold capitalize">
                  {' '}
                  {convertUnderscoreToSpace(
                    offerDetailsData?.hiringProposal?.[0]?.proposalName,
                  ) || '-'}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white p-4 flex flex-col gap-2 border shadow-md rounded-lg w-full">
            <p className="text-xl font-semibold">Compensation Details</p>
            <div className=" p-4 flex items-center justify-between bg-gray-50 border rounded-lg w-full">
              <div className=" flex items-center justify-center w-1/2">
                <p className="font-semibold text-start">Pay Component</p>
              </div>
              <div className=" flex items-center justify-center w-1/2">
                <p className="font-semibold text-start">Amount/Year</p>
              </div>
            </div>
            {filteredCompensationData &&
            filteredCompensationData?.length > 0 ? (
              filteredCompensationData?.map((item, index) => (
                <div
                  className=" p-4 flex flex-col bg-white border rounded-lg gap-2 w-full"
                  key={index}
                >
                  <div className="flex items-center justify-between w-full">
                    <div className=" flex items-center justify-center w-1/2">
                      <p className="font-semibold text-start">
                        {getFieldName(item?.fieldData?.code)}
                      </p>
                    </div>
                    <div className=" flex items-center justify-center w-1/2">
                      <p className="font-semibold text-start">
                        {item?.compData?.body?.total || '0'}
                      </p>
                    </div>
                  </div>
                  {item?.compData?.body?.value &&
                    item?.compData?.body?.value?.length > 0 &&
                    item?.compData?.body?.value?.map((dataItem, index) => (
                      <div
                        className="p-4 border rounded-lg flex flex-col gap-2"
                        key={index}
                      >
                        <div className="flex items-center justify-between w-full">
                          <div className=" flex items-center justify-center w-1/2">
                            <p className=" text-start">
                              {dataItem?.componentname ||
                                item?.compData?.header?.value[index]?.option
                                  ?.name ||
                                '-'}
                            </p>
                          </div>
                          <div className=" flex items-center justify-center w-1/2">
                            <p className=" text-start">
                              {' '}
                              {dataItem?.value || '0'}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ))
            ) : (
              <p className="flex items-center justify-center">
                No Compensation Data
              </p>
            )}
          </div>
        </div>
        <div className="w-3/5 bg-white p-4 flex flex-col gap-4">
          <div className="flex items-center justify-between gap-4">
            <p className="text-xl font-semibold">Template</p>
            {/* <div className="flex items-center text-gray-700">+ Create New</div> */}
          </div>
          <div className="flex items-center justify-between gap-4 w-full">
            <div className="flex flex-col w-1/2">
              <p className="text-sm font-medium  block text-gray-700">
                Select Offer Template
              </p>
              <div className="w-full flex gap-4">
                <Select
                  options={offerTemplateList}
                  className="focus:outline-none rounded-lg w-full pt-1"
                  placeholder="Select option"
                  label=""
                  isSearchable={true}
                  // value={selectedOfferTemplate}
                  isClearable={true}
                  onChange={(selectedOption) => {
                    setSelectedOfferTemplate(selectedOption);
                  }}
                />
                <Button
                  variant="outlined"
                  onClick={() => handlePreviewClick()}
                  disabled={previewLoading || !selectedOfferTemplate?.id}
                >
                  Preview
                </Button>
              </div>
            </div>
            {/* <div className="flex flex-col w-1/2">
              <p className="text-sm font-medium  block text-gray-700">
                Select Mailer Template
              </p>
              <div className="w-full flex gap-4">
                <Select
                  //   options={getFormattedList(countriesData)}
                  options={[
                    { value: 'chocolate', label: 'Chocolate' },
                    { value: 'strawberry', label: 'Strawberry' },
                    { value: 'vanilla', label: 'Vanilla' },
                  ]}
                  className="focus:outline-none rounded-lg w-full pt-1"
                  placeholder="Select option"
                  label=""
                  isSearchable={true}
                  // value={value}
                  isClearable={true}
                  onChange={(selectedOption) => {
                    // onChange(selectedOption);
                    // setValue("benefitSubType", selectedOption);
                  }}
                />
                <Button
                  variant="outlined"
                  // onClick={() => handleSaveClick()}
                >
                  Preview
                </Button>
                {/* <Controller
                  name=""
                  control={control}
                  render={({ field }) => {
                    const { onChange, value, ...rest } = field;
                    return (
                      <div>
                        {" "}
                        <Select
                          {...field}
                          //   options={getFormattedList(countriesData)}
                          options={[
                            { value: "chocolate", label: "Chocolate" },
                            { value: "strawberry", label: "Strawberry" },
                            { value: "vanilla", label: "Vanilla" },
                          ]}
                          className="focus:outline-none rounded-lg w-full pt-1"
                          placeholder="Select option"
                          label="Industry"
                          isSearchable={true}
                          value={value}
                          isClearable={true}
                          onChange={(selectedOption) => {
                            onChange(selectedOption);
                            // setValue("benefitSubType", selectedOption);
                          }}
                          {...rest}
                        />
                      </div>
                    );
                  }}
                /> 
              </div>
            </div> */}
          </div>
          <p className="text-xl font-semibold mt-2">Offer Preview</p>

          {!previewData ? (
            <div className="w-full rounded-lg border p-4 flex items-center justify-center text-sm text-gray-600">
              Please select letter template
            </div>
          ) : (
            <div className="rounded-lg">
              {' '}
              <PdfViewer
                // apiEndPoint={``} // TO Do : put endpoint for api to fetch pdf
                url={imageURLCheck(previewData)}
                // showAllPage={true}
                // defaultScale={1.5}
              />{' '}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReleaseOffer;
