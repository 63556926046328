import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Toggle from 'components/atoms/FormElements/toggle/Toggle';
import React from 'react';

const VersionHistory = ({ setVersionModalOpen }) => {
  return (
    <div className="flex flex-col gap-3.5">
      <div className="flex justify-between items-center">
        <span className="text-gray-900 font-semibold text-xl">
          Version History
        </span>
        <XMarkIcon
          className="w-5 h-5 cursor-pointer"
          onClick={() => setVersionModalOpen(false)}
        />
      </div>
      <hr className="w-full h-1"></hr>
      <div className="pt-2">
        <Toggle label="Show Changes" />
      </div>
      <div className="flex flex-col gap-2">
        <div className="border border-gray-300 px-5 py-3 rounded-lg">
          <Disclosure>
            <Disclosure.Button className="group flex w-full items-center justify-between">
              <div className="flex flex-col gap-1">
                <span className="text-gray-900 text-sm font-normal">
                  Date And Time
                </span>
                <span className="text-gray-900 text-sm font-semibold -ml-5">
                  User Name
                </span>
              </div>
              <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
            </Disclosure.Button>
            <Disclosure.Panel className="flex flex-col gap-2 mt-3">
              hello
            </Disclosure.Panel>
          </Disclosure>
        </div>
      </div>
    </div>
  );
};

export default VersionHistory;
