import { useDeleteJobMatchingRow } from 'query/rangeBuilder/jobMatching/jobMatching';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { onResponse } from 'utils/toastMessages';
import { v4 } from 'uuid';
import { colorArrayShade1, colorArrayShade4 } from '../constants';
import { RangeTable } from './rangeTable/RangeTable';
import ViewDetailsPopUp from './viewDetailsPopUp/ViewDetailsPopUp';
import { removeUntilLastDoubleColon } from '../externalData/viewExternalData/ViewExternalData';

export const ViewJobMatchingData = ({
  jobMatchingData,
  refetchJobMatchingData,
  selectedFilters,
  setSelectedFilters,
  filtersData,
  setPageSize,
  isRefetchingTableData,
}) => {
  const [benchmarkWithColSpan, setbenchmarkWithColSpan] = useState();
  const [subHeadings, setSubHeadings] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [isViewDetailsPopUp, setIsViewDetailsPopUp] = useState(null);
  const { mutateAsync: deleteJobMatchingRow } = useDeleteJobMatchingRow();
  const { id: planId } = useParams();

  useEffect(() => {
    let subData = [];
    let rowsData = [];
    jobMatchingData?.data?.forEach((data) => {
      benchmarkWithColSpan?.forEach((benchmark) => {
        Object.entries(data?.uploadedJson)?.forEach((entry) => {
          if (
            entry[0] === benchmark?.columnName &&
            entry[0] === 'Job Details'
          ) {
            subData = [
              ...subData,
              ...Object.keys(jobMatchingData?.pivotMap || {}).map(
                (pivotId) => entry?.[1]?.[pivotId],
              ),
            ];
          }

          if (
            entry[0] === benchmark?.columnName?.split('_')?.[1] &&
            entry[0] !== 'Job Details'
          ) {
            subData = [...subData, ...Object.values(entry[1])];
          }
        });
      });
      rowsData = [...rowsData, { id: data?.id, data: [...subData] }];
      subData = [];
    });

    setTableData(rowsData);
  }, [benchmarkWithColSpan, jobMatchingData]);

  useEffect(() => {
    const pivotDataColumns = Object.values(jobMatchingData?.pivotMap || {});

    let counter = 0;
    const benchmarks = Object.keys(
      jobMatchingData?.data?.[0]?.uploadedJson || {},
    )?.map((item, index) => {
      if (item !== 'Job Details') {
        counter = counter + 1;
        return {
          id: v4(),
          columnName: `${jobMatchingData?.data?.[0]?.uploadedJson?.[item]?.['Benchmark Provider']}_${item}`,
          colSpan: Object.keys(
            Object.values(jobMatchingData?.data?.[0]?.uploadedJson)?.[index],
          )?.length,
        };
      }
      return {
        id: v4(),
        columnName: 'Job Details',
        colSpan: 1,
      };
    });

    setbenchmarkWithColSpan([
      benchmarks[0],
      {
        id: v4(),
        columnName: '',
        colSpan: pivotDataColumns?.length - 1,
      },
      ...((benchmarks || [])
        .slice(1, benchmarks?.length)
        ?.map((benchmark, index) => ({
          ...benchmark,
          backgroundColor: colorArrayShade1[index],
        })) || []),
    ]);

    let headings = [];
    Object?.values(jobMatchingData?.data?.[0]?.uploadedJson)
      ?.map((value) => {
        return Object.keys(value ?? {});
      })
      ?.filter(
        (item) =>
          !Object.keys(jobMatchingData?.pivotMap || {})?.includes(item?.[0]),
      )
      ?.forEach((item, ind) => {
        const formattedHeadings = item?.map((name) => {
          return {
            name: name,
            textColor: colorArrayShade1[ind % 9],
            bgColor: colorArrayShade4[ind % 9],
          };
        });
        headings = [...headings, ...formattedHeadings];
      });
    const formattedPivotDataColumns = pivotDataColumns?.map((name) => {
      return {
        name: name,
        textColor: colorArrayShade1[9],
        bgColor: colorArrayShade4[9],
      };
    });
    setSubHeadings([...formattedPivotDataColumns, ...headings]);
  }, []);
  const newFiltersData = {};
  Object.keys(filtersData ?? {})?.forEach((key) => {
    newFiltersData[jobMatchingData?.pivotMap[removeUntilLastDoubleColon(key)]] =
      filtersData?.[key];
  });

  return (
    <>
      <RangeTable
        benchmarkWithColSpan={benchmarkWithColSpan}
        subHeadings={subHeadings}
        tableData={tableData}
        selectedRowId={selectedRowId}
        setSelectedRowId={setSelectedRowId}
        setIsViewDetailsPopUp={setIsViewDetailsPopUp}
        dropDownFilters={newFiltersData}
        pivotMap={jobMatchingData?.pivotMap}
        setSelectedFilters={(filter) => {
          setSelectedFilters(filter);
        }}
        pivotedColumns={Object.values(jobMatchingData?.pivotMap || {})}
        selectedFilters={selectedFilters}
        handleDelete={async (selectedId) => {
          const data = {
            rangeFormTypeId: planId,
            payload: {
              dataIds: selectedId,
            },
          };
          deleteJobMatchingRow(data)
            .then((res) => {
              onResponse('Deleted Successfully!');
              refetchJobMatchingData();
            })
            .catch((err) => onResponse('Delete failed!'));
        }}
        isAddRow={false}
        isEditRow={false}
        setPageSize={setPageSize}
        isRefetchingTableData={isRefetchingTableData}
        maxRowCount={200}
      />
      {isViewDetailsPopUp && (
        <ViewDetailsPopUp
          isViewDetailsPopUp={isViewDetailsPopUp}
          setIsViewDetailsPopUp={setIsViewDetailsPopUp}
          benchmarkWithColSpan={benchmarkWithColSpan}
          subHeadings={subHeadings}
          pivotMap={jobMatchingData?.pivotMap}
        />
      )}
    </>
  );
};
