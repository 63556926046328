import {
  ArrowDownTrayIcon,
  CheckIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  getCandidateOfferDetails,
  offerAction,
  uploadOfferLetter,
} from 'apiClient/hiringOffer/hiringOffer';
import AcceptTickIcon from 'assets/svg/acceptTickIcon';
import ArrowLeftIcon from 'assets/svg/arrowLeft';
import ButtonLoader from 'assets/svg/buttonLoader';
import EyeIcon from 'assets/svg/eyeIcon';
import { AxiosError } from 'axios';
import TextBox from 'components/atoms/FormElements/textBox/TextBox';
import Toggle from 'components/atoms/FormElements/toggle/Toggle';
import Button from 'components/atoms/button/Button';
import ModalDialogue from 'components/atoms/modalDialogue/ModalDialogue';
import PdfViewer from 'components/atoms/pdfViewer/PDFViewer';
import Chart from 'components/molecules/chart/ChartComponent';
import moment from 'moment';
import FileUploader from 'pages/policy/create/mainPanel/policySteps/FileUploader';
import SelectedFile from 'pages/policy/create/mainPanel/policySteps/SelectedFile';
import ProjectData from 'pages/profile/profileModules/compensation/ProjectData';
import TableRowSection from 'pages/profile/profileModules/compensation/TableRowSection';
import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { imageURLCheck } from 'utils/ImageUrlCheck';
import { onResponse } from 'utils/toastMessages';
const color = ['#F63D68', '#22CCEE', '#FAC515', '#7A5AF8'];
const initialData = {
  firstYear: {
    basePay: 0,
    variablePay: 0,
    benefitsPay: 0,
  },
  secondYear: {
    basePay: 0,
    variablePay: 0,
    benefitsPay: 0,
  },
  thirdYear: {
    basePay: 0,
    variablePay: 0,
    benefitsPay: 0,
  },
  fourthYear: {
    basePay: 0,
    variablePay: 0,
    benefitsPay: 0,
  },
};
const ltiGrant = {
  amountofYear: 0,
  firstYearPercent: 0,
  secondYearPercent: 0,
  thirdYearPercent: 0,
  fourthYearPercent: 0,
};
const DownloadTemplate = ({ value }) => {
  const handleDownloadTemplate = () => {
    const a = document.createElement('a');
    a.href = value;
    a.download = `offerLetter.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <div
      className="flex gap-2 items-center border rounded-lg p-2 justify-center cursor-pointer border-gray-400 text-gray-600"
      onClick={() => handleDownloadTemplate()}
    >
      <ArrowDownTrayIcon className="w-5 h-5 " /> <p>Download Offer Letter</p>
    </div>
  );
};
const ReviewOffer = () => {
  const numberOfYears = 4;
  const PERCENT_DECIMAL = 1;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const value = queryParams.get('value');
  const storedOrgDetails = localStorage.getItem('orgDetails');
  const parsedOrgDetails = storedOrgDetails
    ? JSON.parse(storedOrgDetails)
    : null;
  const [isConfettiVisible, setConfettiVisible] = useState(true);
  const [commentMessage, setCommentMessage] = useState('');
  const [vestingSchedule, setVestingSchedule] = useState({});
  const [releaseData, setReleaseData] = useState();
  const [showPopUp, setShowPopUp] = useState(false);
  const [previewLink, setPreviewLink] = useState('');
  const [approveLoading, setApproveLoading] = useState(false);
  const [inputFileData, setInputFileData] = useState();
  const [offerDetailsData, setOfferDetailsData] = useState();
  const [acceptPopup, setAcceptPopup] = useState(false);
  const [compDataList, setCompDataList] = useState([]);
  const [totalCompensationValue, setTotalCompensationValue] = useState(0);
  const [realizedWealth, setRealizedWealth] = useState(false);
  const [yearData, setYearData] = useState(initialData);

  const [grantsArray, setGrantsArray] = useState([0, 0, 0, 0]);
  const methods = useForm({
    defaultValues: {
      showAmountInMillions: false,
      allowLtiGrants: false,
      allowOneTimePayment: false,
      simulationProject: {
        benefitsPay: '',
        rangeSlider: 0,
        benefitsPayPercentage: '',
        currentBasePay: '',
        currentBasePayPercentage: '',
        variablePay: '',
        currentUnvestedValue: 0,
        variablePayPercentage: '',
        currentUnvested: {
          firstYearPercent: 0,
          fourthYearPercent: 0,
          secondYearPercent: 0,
          thirdYearPercent: 0,
        },
        ltiGrants: [{ ...ltiGrant }],
      },
    },
    shouldFocusError: false,
  });
  const { control, setValue, watch } = methods;

  const simulationProject = useWatch({
    control,
    name: 'reviewOffer',
  });
  const [showAmountInMillions, allowLtiGrants, allowOneTimePayment] = watch([
    'showAmountInMillions',
    'allowLtiGrants',
    'allowOneTimePayment',
  ]);
  const currentYear = new Date().getFullYear();
  const chartLabels = Array.from(
    { length: numberOfYears },
    (_, index) => currentYear + index,
  );
  const firstYearSum = Math.ceil(
    (yearData.firstYear?.basePay || 0) +
      (yearData.firstYear?.variablePay || 0) +
      (yearData.firstYear?.benefitsPay || 0),
    +(allowLtiGrants ? grantsArray[0] || 0 : 0) +
      (allowOneTimePayment
        ? parseInt(simulationProject?.oneTimePayYear1 || 0)
        : 0),
  );
  const secondYearSum = Math.ceil(
    (yearData.secondYear?.basePay || 0) +
      (yearData.secondYear?.variablePay || 0) +
      (yearData.secondYear?.benefitsPay || 0),
    +(allowLtiGrants ? grantsArray[1] || 0 : 0) +
      (allowOneTimePayment
        ? parseInt(simulationProject?.oneTimePayYear2 || 0)
        : 0),
  );

  const thirdYearSum = Math.ceil(
    (yearData.thirdYear?.basePay || 0) +
      (yearData.thirdYear?.variablePay || 0) +
      (yearData.thirdYear?.benefitsPay || 0),
    +(allowLtiGrants ? grantsArray[2] || 0 : 0),
  );

  const fourthYearSum = Math.ceil(
    (yearData.fourthYear?.basePay || 0) +
      (yearData.fourthYear?.variablePay || 0) +
      (yearData.fourthYear?.benefitsPay || 0),
    +(allowLtiGrants ? grantsArray[3] || 0 : 0),
  );
  useEffect(() => {
    const fyBasePay = calculateValue(
      simulationProject?.currentBasePay,
      simulationProject?.currentBasePayPercentage || 0,
    );
    const fyVariablePay = calculateValue(
      simulationProject?.variablePay,
      simulationProject?.variablePayPercentage || 0,
    );
    const fyBenefitsPay = calculateValue(
      simulationProject?.benefitsPay,
      simulationProject?.benefitsPayPercentage || 0,
    );
    setYearData((prevData) => ({
      ...prevData,
      firstYear: {
        ...prevData.firstYear,
        basePay: fyBasePay,
        variablePay: fyVariablePay,
        benefitsPay: fyBenefitsPay,
      },
    }));
  }, [simulationProject]);
  const calculateValue = (value, percentage) => {
    const parsedValue = parseFloat(value);
    const parsedPercentage = parseFloat(percentage);
    return parsedValue + parsedValue * (parsedPercentage / 100);
  };
  useEffect(() => {
    const syBasePay = calculateValue(
      yearData.firstYear?.basePay,
      simulationProject?.currentBasePayPercentage || 0,
    );
    const syVariablePay = calculateValue(
      yearData.firstYear?.variablePay,
      simulationProject?.variablePayPercentage || 0,
    );
    const syBenefitsPay = calculateValue(
      yearData.firstYear?.benefitsPay,
      simulationProject?.benefitsPayPercentage || 0,
    );
    setYearData((prevData) => ({
      ...prevData,
      secondYear: {
        ...prevData.secondYear,
        basePay: syBasePay,
        variablePay: syVariablePay,
        benefitsPay: syBenefitsPay,
      },
    }));
  }, [yearData.firstYear]);
  useEffect(() => {
    const tyBasePay = calculateValue(
      yearData.secondYear?.basePay,
      simulationProject?.currentBasePayPercentage || 0,
    );
    const tyVariablePay = calculateValue(
      yearData.secondYear?.variablePay,
      simulationProject?.variablePayPercentage || 0,
    );
    const tyBenefitsPay = calculateValue(
      yearData.secondYear?.benefitsPay,
      simulationProject?.benefitsPayPercentage || 0,
    );
    setYearData((prevData) => ({
      ...prevData,
      thirdYear: {
        ...prevData.thirdYear,
        basePay: tyBasePay,
        variablePay: tyVariablePay,
        benefitsPay: tyBenefitsPay,
      },
    }));
  }, [yearData.secondYear]);
  useEffect(() => {
    const fourthBasePay = calculateValue(
      yearData.thirdYear?.basePay,
      simulationProject?.currentBasePayPercentage || 0,
    );
    const fourthVariablePay = calculateValue(
      yearData.thirdYear?.variablePay,
      simulationProject?.variablePayPercentage || 0,
    );
    const fourthBenefitsPay = calculateValue(
      yearData.thirdYear?.benefitsPay,
      simulationProject?.benefitsPayPercentage || 0,
    );
    setYearData((prevData) => ({
      ...prevData,
      fourthYear: {
        ...prevData.fourthYear,
        basePay: fourthBasePay,
        variablePay: fourthVariablePay,
        benefitsPay: fourthBenefitsPay,
      },
    }));
  }, [yearData.thirdYear]);
  const fetchCandidateOfferDetails = async (body) => {
    try {
      const data = await getCandidateOfferDetails(body);
      return data;
    } catch (err) {
      throw new AxiosError(err?.message);
    }
  };
  useEffect(() => {
    const averageGrowth = simulationProject?.rangeSlider;
    const unvestedGrowthFirstAmount = calculateValue(
      simulationProject?.currentUnvestedValue,
      averageGrowth,
    );
    const unvestedGrowthSecondAmount = calculateValue(
      unvestedGrowthFirstAmount,
      averageGrowth,
    );
    const unvestedGrowthThirdAmount = calculateValue(
      unvestedGrowthSecondAmount,
      averageGrowth,
    );
    const unvestedGrowthFourthAmount = calculateValue(
      unvestedGrowthThirdAmount,
      averageGrowth,
    );
    const UnvestedFirstAmount =
      unvestedGrowthFirstAmount *
      (simulationProject?.currentUnvested?.firstYearPercent / 100);
    const UnvestedSecondAmount =
      unvestedGrowthSecondAmount *
      (simulationProject?.currentUnvested?.secondYearPercent / 100);
    const UnvestedThirdAmount =
      unvestedGrowthThirdAmount *
      (simulationProject?.currentUnvested?.thirdYearPercent / 100);
    const UnvestedFourthAmount =
      unvestedGrowthFourthAmount *
      (simulationProject?.currentUnvested?.fourthYearPercent / 100);
    const totalsArray = simulationProject?.ltiGrants?.reduce(
      (acc, item, index) => {
        const firstAmount = calculateValue(item?.amountofYear, averageGrowth);
        const secondAmount = calculateValue(firstAmount, averageGrowth);
        const thirdAmount = calculateValue(secondAmount, averageGrowth);
        const fourthAmount = calculateValue(thirdAmount, averageGrowth);

        let calculatedValues = [0, 0, 0, 0];

        if (index === 0) {
          calculatedValues = [
            firstAmount * (item?.firstYearPercent / 100),
            secondAmount * (item?.secondYearPercent / 100),
            thirdAmount * (item?.thirdYearPercent / 100),
            fourthAmount * (item?.fourthYearPercent / 100),
          ];
        } else if (index === 1) {
          calculatedValues = [
            0,
            secondAmount * (item?.secondYearPercent / 100),
            thirdAmount * (item?.thirdYearPercent / 100),
            fourthAmount * (item?.fourthYearPercent / 100),
          ];
        } else if (index === 2) {
          calculatedValues = [
            0,
            0,
            thirdAmount * (item?.thirdYearPercent / 100),
            fourthAmount * (item?.fourthYearPercent / 100),
          ];
        } else if (index === 3) {
          calculatedValues = [
            0,
            0,
            0,
            fourthAmount * (item?.fourthYearPercent / 100),
          ];
        }

        return acc?.map((value, idx) => value + calculatedValues[idx]);
      },
      [0, 0, 0, 0],
    );
    const updatedTotalsArray = [
      totalsArray?.[0] + (UnvestedFirstAmount || 0),
      totalsArray?.[1] + (UnvestedSecondAmount || 0),
      totalsArray?.[2] + (UnvestedThirdAmount || 0),
      totalsArray?.[3] + (UnvestedFourthAmount || 0),
    ];
    setGrantsArray(updatedTotalsArray);
  }, [
    simulationProject?.ltiGrants,
    simulationProject?.rangeSlider,
    simulationProject?.currentUnvestedValue,
    simulationProject?.currentUnvested,
  ]);
  useEffect(() => {
    const payload = {
      value: value,
    };
    fetchCandidateOfferDetails(payload)
      .then((res) => {
        setOfferDetailsData(res?.data);
      })
      .catch((error) => onResponse(error?.message));
  }, []);
  useEffect(() => {
    if (offerDetailsData) {
      let totalValue = 0;
      const newCompData = [];
      if (offerDetailsData?.hiringProposal?.[0]?.componsationProposal?.body) {
        Object?.entries(
          offerDetailsData.hiringProposal[0].componsationProposal.body,
        )?.forEach(([key, item]) => {
          if (typeof item === 'object' && item?.total) {
            const headerData = offerDetailsData?.compSideNavDetails[key];
            const foundObject =
              offerDetailsData?.compSideNavDetails?.compFields?.find(
                (item) => item?.codeId === key,
              );
            if (foundObject?.name === 'LTI') {
              setVestingSchedule(item?.vestingSchedule);
            }
            totalValue += parseInt(item?.total);
            newCompData.push({ ...item, ...foundObject, header: headerData });
          }
        });
      }
      if (offerDetailsData?.hiringProposal?.[0]?.hiringReleaseLetter) {
        const releaseLetterData =
          offerDetailsData?.hiringProposal?.[0]?.hiringReleaseLetter?.[0];
        setReleaseData(releaseLetterData);
        if (releaseLetterData?.signatureLetterUrl === null) {
          setPreviewLink(imageURLCheck(releaseLetterData?.releaseLetterUrl));
        } else {
          setPreviewLink(imageURLCheck(releaseLetterData?.signatureLetterUrl));
        }
      }
      setTotalCompensationValue(totalValue);
      setCompDataList(newCompData);
    }
  }, [offerDetailsData]);
  useEffect(() => {
    if (compDataList && compDataList?.length > 0) {
      compDataList?.map((item) => {
        if (item?.name === 'Fixed Salary') {
          methods?.setValue(
            `reviewOffer.currentBasePay`,
            parseInt(item?.total),
          );
          methods?.setValue(`reviewOffer.currentBasePayPercentage`, 1);
        } else if (item?.name === 'Benefits') {
          methods?.setValue(`reviewOffer.benefitsPay`, parseInt(item?.total));
          methods?.setValue(`reviewOffer.benefitsPayPercentage`, 1);
        } else if (item?.name === 'Variable') {
          methods?.setValue(`reviewOffer.variablePay`, parseInt(item?.total));
          methods?.setValue(`reviewOffer.variablePayPercentage`, 1);
        } else if (item?.name === 'One time Payout') {
          methods?.setValue(
            `reviewOffer.oneTimePayYear1`,
            parseInt(item?.total),
          );
          methods?.setValue(
            `reviewOffer.oneTimePayYear2`,
            parseInt(item?.total),
          );
        } else if (item?.name === 'LTI') {
          methods?.setValue(
            `reviewOffer.currentUnvestedValue`,
            parseInt(item?.total),
          );
          methods?.setValue(`reviewOffer.variablePayPercentage`, 1);
        }
      });
    }
  }, [compDataList]);
  useEffect(() => {
    if (vestingSchedule?.name) {
      const {
        firstYearPercent,
        secondYearPercent,
        thirdYearPercent,
        fourthYearPercent,
      } = extractYearPercents(vestingSchedule?.name);
      methods?.setValue(
        `reviewOffer.currentUnvested.firstYearPercent`,
        firstYearPercent,
      );
      methods?.setValue(
        `reviewOffer.currentUnvested.secondYearPercent`,
        secondYearPercent,
      );
      methods?.setValue(
        `reviewOffer.currentUnvested.thirdYearPercent`,
        thirdYearPercent,
      );
      methods?.setValue(
        `reviewOffer.currentUnvested.fourthYearPercent`,
        fourthYearPercent,
      );
    }
  }, [vestingSchedule]);

  const extractYearPercents = (str) => {
    // Ensure the string is valid and contains the expected format
    if (!str || !str?.includes('-'))
      return {
        firstYearPercent: 0,
        secondYearPercent: 0,
        thirdYearPercent: 0,
        fourthYearPercent: 0,
      };
    const [
      firstYearPercent,
      secondYearPercent,
      thirdYearPercent,
      fourthYearPercent,
    ] = str.split(' ')[0].split('-').map(Number);
    return {
      firstYearPercent: isNaN(firstYearPercent) ? 0 : firstYearPercent,
      secondYearPercent: isNaN(secondYearPercent) ? 0 : secondYearPercent,
      thirdYearPercent: isNaN(thirdYearPercent) ? 0 : thirdYearPercent,
      fourthYearPercent: isNaN(fourthYearPercent) ? 0 : fourthYearPercent,
    };
  };
  function createGradient() {
    const ctx = document.createElement('canvas').getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(
      0,
      getComputedStyle(document.documentElement).getPropertyValue(
        '--primaryLeft',
      ),
    );
    gradient.addColorStop(
      1,
      getComputedStyle(document.documentElement).getPropertyValue(
        '--primaryRight',
      ),
    );
    return gradient;
  }
  const simulationData = [
    firstYearSum,
    secondYearSum,
    thirdYearSum,
    fourthYearSum,
  ];
  const dataForChart = {
    labels: chartLabels,
    datasets: [
      {
        label: 'Bar Chart',
        backgroundColor: createGradient(),
        data: simulationData,
        fill: false,
        tension: 0.1,
      },
    ],
  };
  // console.log(methods?.watch());
  const chartOptions = {};

  const handleAcceptOffer = (type) => {
    const body = {
      value: value,
      status: type,
    };
    offerAction(body)
      ?.then((res) => {
        onResponse('Response Saved Successfully');
      })
      .catch((error) => onResponse(error?.message));
  };
  // const handleGetPreviewLink = () => {
  //   const body = {
  //     value: value,
  //   };
  //   offerAction(body)
  //     ?.then((res) => {
  //       setPreviewLink(res?.data?.url);
  //     })
  //     .catch((error) => onResponse(error?.message));
  // };

  const handleSubmitAcceptance = () => {
    if (inputFileData) {
      const reqData = new FormData();
      reqData.append('signOfferLetter', inputFileData);
      reqData.append('value', value);
      reqData.append('comment', commentMessage);
      console.log(reqData?.signOfferLetter, inputFileData);
      uploadOfferLetter(reqData)
        ?.then((res) => {
          setAcceptPopup(false);
          onResponse('Offer Accepted Successfully');
        })
        .catch((error) => onResponse(error?.message));
    }
  };
  useEffect(() => {
    // Stop confetti after 10 seconds
    const timer = setTimeout(() => {
      setConfettiVisible(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isConfettiVisible && (
        <div style={{ position: 'relative' }}>
          {' '}
          <Confetti
            numberOfPieces={200}
            width={window.innerWidth}
            height={window.innerHeight}
          />
        </div>
      )}

      <FormProvider {...methods}>
        {acceptPopup && (
          <ModalDialogue
            icon={<AcceptTickIcon />}
            isModalOpen={acceptPopup}
            onClose={() => setAcceptPopup(false)}
            title={'Accept Offer'}
            description={'Upload Signed Offer Letter/Redirect to DocuSign'}
            width="500px"
          >
            <div className="flex flex-col gap-4 w-full py-4">
              <DownloadTemplate value={previewLink} />
              <div className="flex flex-col">
                <p className="text-gray-600 text-sm">
                  Upload signed offer letter
                </p>
                {inputFileData ? (
                  <>
                    <SelectedFile
                      file={inputFileData}
                      onRemoveImage={() => {
                        setInputFileData(null);
                      }}
                    />
                  </>
                ) : (
                  <FileUploader
                    acceptedTypes={['.pdf']}
                    maxSize={5 * 1024 * 1024}
                    subText="PDF (Max. 5 MB)"
                    onFileSelect={(file) => {
                      console.log(file);
                      setInputFileData(file);
                    }}
                  />
                )}
              </div>{' '}
              <div className="flex flex-col">
                <p className="text-gray-600 text-sm">Comments</p>
                <TextBox
                  className="!mt-0 !py-2  border border-gray-300"
                  placeholder="Comments.."
                  value={commentMessage}
                  rows="3"
                  onChange={(e) => setCommentMessage(e.target.value)}
                />
              </div>
              <div className="flex gap-4 justify-end mt-5">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setAcceptPopup(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="filled"
                  onClick={() => handleSubmitAcceptance()}
                  disabled={!inputFileData || approveLoading}
                >
                  {approveLoading ? (
                    <span>
                      <ButtonLoader /> Accepting..
                    </span>
                  ) : (
                    'Submit Acceptance'
                  )}
                </Button>
              </div>
            </div>
          </ModalDialogue>
        )}

        {showPopUp && (
          <div className="fixed z-[53] inset-0 bg-backdrop overflow-y-auto custom-scrollbar w-full pb-8">
            <div className="flex flex-col items-center justify-center gap-4 min-h-screen mx-auto pt-24 w-full">
              <div className="flex items-center justify-between w-9/12 mx-auto">
                <div className="flex items-center gap-4 text-white">
                  <ArrowLeftIcon
                    className="w-5 h-5 cursor-pointer text-white"
                    color="white"
                    onClick={() => {
                      setShowPopUp(false);
                    }}
                  />
                </div>
              </div>
              <div
                className={`bg-white rounded-lg shadow-md w-9/12  p-4  pb-10`}
              >
                <div className="w-full mx-auto">
                  {previewLink === undefined || !previewLink ? (
                    <div className="text-gray-600 flex items-center justify-center">
                      Unable to preview document.
                    </div>
                  ) : (
                    <PdfViewer
                      // apiEndPoint={``} // TO Do : put endpoint for api to fetch pdf
                      pdfFile={previewLink}
                      // showAllPage={true}
                      defaultScale={1.5}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-col w-full">
          <div className="w-full h-[250px] z-30 flex flex-col gap-8 items-center justify-center bg-white">
            <p className="text-xl font-semibold">
              Congratulations,{' '}
              {offerDetailsData?.candidateDetails?.applicationFullName}!
            </p>
            <p className="text-sm text-gray-600">
              Hey {offerDetailsData?.candidateDetails?.applicationFullName}!
              We’re pleased to present you this offer to join{' '}
              {parsedOrgDetails?.orgName}, as a{' '}
              {offerDetailsData?.jobDetails?.jobCode?.name}. Here’s you will
              find your Total Paymix.
            </p>
          </div>
          <div className="flex flex-col w-full px-4 py-6 gap-4 bg-white border-b">
            <div className="flex items-center justify-between w-full  bg-white">
              <div className="flex flex-col gap-1">
                <p className="text-lg font-semibold text-gray-600">Pay-mix</p>
                <p className="text-sm text-gray-600">
                  Your Total Compensation is{' '}
                  <span className="font-semibold text-black">
                    INR {totalCompensationValue}/yr
                  </span>
                </p>
              </div>
              <div className="flex flex-col gap-1 justify-end items-end">
                {/* <p className="flex items-center text-base text-gray-700">
                <Toggle
                  checked={realizedWealth}
                  onChange={(e) => {
                    // setShowWithRealizedWealth(!showWithRealizedWealth);
                    setRealizedWealth(e?.target?.checked);
                  }}
                />
                <span className="pr-1">With realized wealth</span>{' '}
                <QuestionMarkCircleIcon className="w-4 h-4" />
              </p> */}
                <p className="text-sm text-gray-600">
                  effective date{' '}
                  {moment(offerDetailsData?.approvalDate).format(
                    'DD MMM, YYYY',
                  )}
                </p>
              </div>
            </div>
            <div className="flex w-full h-10 bg-white">
              {compDataList?.map((item, index) => {
                const calculatedWidth = `${(
                  (parseInt(item?.total) / totalCompensationValue) *
                  100
                )?.toFixed(PERCENT_DECIMAL)}%`;
                const isFirst = index === 0;
                const isLast = index === compDataList.length - 1;

                return (
                  <>
                    <Tooltip
                      id="tooltip-value-percentage"
                      place="top"
                      type="light"
                    />
                    <div
                      className={
                        `h-full border flex items-center justify-start grow px-2 text-white font-semibold text-xs cursor-pointer` +
                        (isFirst ? ' rounded-l-lg' : '') +
                        (isLast ? ' rounded-r-lg' : '')
                      }
                      data-tooltip-id="tooltip-value-percentage"
                      data-tooltip-content={`${item?.name} ${parseFloat(
                        calculatedWidth,
                      )?.toFixed(PERCENT_DECIMAL)} %`}
                      key={item?.name}
                      style={{
                        width: calculatedWidth,
                        backgroundColor: color[index % color?.length], // Repeat colors from the color array
                      }}
                    >
                      <span className="bg-gray-600 bg-opacity-40 py-1 px-2 rounded-3xl overflow-hidden truncate ">
                        {parseFloat(calculatedWidth)?.toFixed(PERCENT_DECIMAL)}{' '}
                        %{/* 20 % */}
                      </span>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <div className="flex w-full">
            <div className="p-4 w-4/12">
              {' '}
              <div className="bg-white p-4 flex flex-col gap-2 border shadow-md rounded-lg w-full">
                <p className="text-xl font-semibold">Compensation Details</p>
                <div className=" p-4 flex items-center justify-between bg-gray-50 border rounded-lg w-full">
                  <div className=" flex items-center justify-center w-1/2">
                    <p className="font-semibold text-start">Pay Component</p>
                  </div>
                  <div className=" flex items-center justify-center w-1/2">
                    <p className="font-semibold text-start">Amount/Year</p>
                  </div>
                </div>
                {compDataList && compDataList?.length > 0 ? (
                  compDataList?.map((item, index) => (
                    <div
                      className=" p-4 flex flex-col bg-white border rounded-lg gap-2 w-full"
                      key={index}
                    >
                      <div className="flex items-center justify-between w-full">
                        <div className=" flex items-center justify-center w-1/2">
                          <p className="font-semibold text-start">
                            {item?.name}
                          </p>
                        </div>
                        <div className=" flex items-center justify-center w-1/2">
                          <p className="font-semibold text-start">
                            {item?.total || '0'}
                          </p>
                        </div>
                      </div>
                      {item?.value &&
                        item?.value?.length > 0 &&
                        item?.value?.map((dataItem, index) => (
                          <div
                            className="p-4 border rounded-lg flex flex-col gap-2"
                            key={index}
                          >
                            <div className="flex items-center justify-between w-full">
                              <div className=" flex items-center justify-center w-1/2">
                                <p className=" text-start">
                                  {dataItem?.componentname ||
                                    item?.header?.value[index]?.option?.name ||
                                    '-'}
                                </p>
                              </div>
                              <div className=" flex items-center justify-center w-1/2">
                                <p className=" text-start">
                                  {' '}
                                  {dataItem?.value || '0'}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  ))
                ) : (
                  <p className="flex items-center justify-center">
                    No Compensation Data
                  </p>
                )}
              </div>
            </div>
            <div className="bg-white w-8/12 p-4 flex flex-col gap-4">
              <div className="flex items-center justify-between border rounded-lg shadow-md p-4">
                <div className="flex items-center gap-2">
                  <p className="font-semibold text-lg">Your Response</p>
                  {releaseData &&
                    (releaseData?.status === 'Accept' ? (
                      <div className="rounded-lg py-0.5 px-2 text-[13px] w-auto text-[#039855] bg-[#E3FCEC] ">
                        Accepted
                      </div>
                    ) : releaseData?.status === 'Reject' ? (
                      <div className="rounded-lg py-0.5 px-2 text-[13px] w-auto text-[#D92D20] bg-[#FEF3F2] ">
                        Rejected
                      </div>
                    ) : (
                      <></>
                    ))}
                </div>
                <div className="flex gap-2">
                  <div
                    className="flex items-center gap-2 p-2 rounded-lg border border-gray-400 text-gray-600"
                    onClick={() => {
                      setShowPopUp(true);
                      // handleGetPreviewLink();
                    }}
                  >
                    <EyeIcon className="w-4 h-4" /> View Offer Letter
                  </div>
                  {releaseData?.status !== 'Default' ? (
                    <>
                      <DownloadTemplate value={value} />
                    </>
                  ) : (
                    <>
                      <div
                        className="flex items-center gap-2 p-2 rounded-lg border border-green text-green cursor-pointer"
                        onClick={() => setAcceptPopup(true)}
                      >
                        <CheckIcon className="w-5 h-5 text-green" /> Accept
                      </div>{' '}
                      <div
                        className="flex items-center gap-2 p-2 rounded-lg border border-red-600 text-red-600"
                        onClick={() => handleAcceptOffer('Reject')}
                      >
                        <XMarkIcon className="w-5 h-5 text-red-600" /> Reject
                      </div>
                    </>
                  )}
                </div>
              </div>
              <p className="font-semibold text-xl">Simulate Growth</p>
              <div className="bg-gray-100 rounded-lg p-4 flex flex-col gap-2">
                <div className="flex flex-col">
                  <p className="font-semibold text-lg">Description</p>
                  <p className="text-gray-700">
                    Simulate your growth with the company and project your
                    income on the basis of your assumption.
                  </p>
                </div>{' '}
                <div className="flex flex-col">
                  <p className="font-semibold text-lg">Desclaimer</p>
                  <p className="text-gray-700">
                    The simulation is purely based on your assumption. It will
                    have no impact on the actual value and growth.
                  </p>
                </div>
              </div>
              <div className="flex gap-4 w-full">
                <div className="w-2/6 p-4 border rounded-lg bg-gray-100">
                  {' '}
                  <ProjectData
                    projectTitle={parsedOrgDetails?.orgName}
                    project={'reviewOffer'}
                    // ltiGrant={ltiGrant}
                  />
                </div>
                <div className="w-4/6 rounded-lg shadow-md bg-white p-4 flex flex-col gap-4">
                  {' '}
                  <div className="flex items-center justify-between gap-4">
                    {/* <p className="font-medium text-gray-600 text-sm">The Values are shown in Lac(s)</p> */}
                    <p className="font-medium text-gray-600 text-sm"></p>
                    <div className="flex items-center gap-2">
                      <span className="text-sm font-medium text-gray-700">
                        {' '}
                        Lacs{' '}
                      </span>
                      <Toggle
                        checked={showAmountInMillions}
                        onChange={() => {
                          setValue(
                            'showAmountInMillions',
                            !showAmountInMillions,
                          );
                        }}
                      />{' '}
                      <span className="text-sm font-medium text-gray-700">
                        {' '}
                        Millions{' '}
                      </span>
                    </div>
                  </div>
                  <Chart
                    type="bar"
                    data={dataForChart}
                    chartOptions={chartOptions}
                  />
                  <div className="flex flex-col gap-2 w-full">
                    <div className="flex gap-2">
                      <div className="w-2/12 p-2 rounded-lg bg-gray-100 border border-gray-300 flex items-center justify-center text-gray-900 text-sm font-semibold">
                        Comparison
                      </div>
                      <div className="w-8/12 p-2 rounded-lg bg-gray-100 border border-gray-300 grid grid-cols-4 gap-2">
                        {chartLabels.map((year) => (
                          <div
                            className=" flex items-center justify-center text-sm text-gray-900 font-semibold"
                            key={year}
                          >
                            {year}
                          </div>
                        ))}
                      </div>
                      <div className="w-2/12 p-2 rounded-lg bg-gray-100 border border-gray-300 flex items-center justify-center text-sm text-gray-900 font-semibold">
                        Company
                      </div>
                    </div>
                    <>
                      <TableRowSection
                        title="Base"
                        data={[
                          yearData.firstYear?.basePay || 0,
                          yearData.secondYear?.basePay || 0,
                          yearData.thirdYear?.basePay || 0,
                          yearData.fourthYear?.basePay || 0,
                        ]}
                        selectedSideTab={'Simulation'}
                        simulationTitle="Current"
                        ComparisionTitle="Potential"
                      />
                      <TableRowSection
                        title="Variable"
                        data={[
                          yearData.firstYear?.variablePay || 0,
                          yearData.secondYear?.variablePay || 0,
                          yearData.thirdYear?.variablePay || 0,
                          yearData.fourthYear?.variablePay || 0,
                        ]}
                        selectedSideTab={'Simulation'}
                        simulationTitle="Current"
                        ComparisionTitle="Potential"
                      />
                      {allowLtiGrants && (
                        <TableRowSection
                          title="ESOPs"
                          data={grantsArray}
                          selectedSideTab={'Simulation'}
                          simulationTitle="Current"
                          ComparisionTitle="Potential"
                        />
                      )}
                      <TableRowSection
                        title="Benefits"
                        data={[
                          yearData.firstYear?.benefitsPay || 0,
                          yearData.secondYear?.benefitsPay || 0,
                          yearData.thirdYear?.benefitsPay || 0,
                          yearData.fourthYear?.benefitsPay || 0,
                        ]}
                        selectedSideTab={'Simulation'}
                        simulationTitle="Current"
                        ComparisionTitle="Potential"
                      />
                      {allowOneTimePayment && (
                        <TableRowSection
                          title="Onetime"
                          data={[
                            parseInt(simulationProject?.oneTimePayYear1 || 0),
                            parseInt(simulationProject?.oneTimePayYear2 || 0),
                            0,
                            0,
                          ]}
                          selectedSideTab={'Simulation'}
                          simulationTitle="Current"
                          ComparisionTitle="Potential"
                        />
                      )}
                      <TableRowSection
                        title="Total"
                        data={[
                          firstYearSum || 0,
                          secondYearSum || 0,
                          thirdYearSum || 0,
                          fourthYearSum || 0,
                        ]}
                        selectedSideTab={'Simulation'}
                        simulationTitle="Current"
                        ComparisionTitle="Potential"
                      />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormProvider>
    </>
  );
};

export default ReviewOffer;
