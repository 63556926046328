import Toggle from 'components/atoms/FormElements/toggle/Toggle';
import ModalPopup from 'components/layout/modalPopup/ModalPopup';
import { useForm, Controller } from 'react-hook-form';
import React, { useState } from 'react';
import { AGING_ACTION_TYPE } from '../JobMatching';
import { Input } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import DatePicker from 'components/atoms/FormElements/datepicker/DatePicker';
import FileUploadDownloadPopup from 'components/molecules/FileUpload_Download_Popup/FileUploadDownloadPopup';
import {
  useGetAgingFrequencyDropDown,
  useIndividualAgingUpload,
} from 'query/rangeBuilder/jobMatching/jobMatching';
import Button from 'components/atoms/button/Button';
import {
  agingValidateCsvData,
  downloadAgingTemplate,
} from 'apiClient/rangeBuilder/rangeBuilder';
import { useParams } from 'react-router-dom';
import { onResponse } from 'utils/toastMessages';

const AgingPopUp = ({ agingActionType, setAgingActionType }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { data: agingFrequencyDropDownValue } = useGetAgingFrequencyDropDown();
  const { mutateAsync: individualUpload, isLoading: individualAgingUploading } =
    useIndividualAgingUpload();
  const { id: planId } = useParams();

  const onSubmit = (data) => {
    const payload = {
      rate: parseInt(data?.agingRate),
      frequency: data?.selectedFrequency?.value,
      startDate: data?.startDate?.toISOString(),
      endDate: data?.endDate?.toISOString(),
    };
    individualUpload({ planId, payload })
      .then((res) => {
        onResponse('Uploaded successfully!');
        setAgingActionType(null);
      })
      .catch((err) => {
        onResponse('Upload failed!');
        setAgingActionType(null);
      });
  };

  return (
    <ModalPopup
      onClose={() => setAgingActionType(null)}
      title={`Aging`}
      body={
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-5 flex flex-col gap-4"
          >
            <div className="p-4">
              <div className="flex justify-between">
                <p className="font-figtree text-sm font-normal text-gray-600">
                  Apply aging to the data
                </p>
                <div className="flex h-full items-center gap-0">
                  <Toggle
                    checked={agingActionType === AGING_ACTION_TYPE.bulkUpload}
                    width={'w-7'}
                    height={'h-4'}
                    afterClass={'after:h-3 after:w-3'}
                    onChange={(e) => {
                      if (e?.target?.checked) {
                        setAgingActionType(AGING_ACTION_TYPE.bulkUpload);
                      } else {
                        setAgingActionType(AGING_ACTION_TYPE.individualUpload);
                      }
                      reset({});
                    }}
                  />
                  <p className="font-figtree text-sm font-medium text-gray-700 -ml-3">
                    Bulk Upload
                  </p>
                </div>
              </div>
              {agingActionType === AGING_ACTION_TYPE.individualUpload ? (
                <div className="mt-5 flex flex-col gap-4">
                  <Controller
                    name="agingRate"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Required' }}
                    render={({ field }) => (
                      <Input
                        label="Aging Rate"
                        type="number"
                        {...field}
                        required
                        error={errors?.agingRate}
                      />
                    )}
                  />
                  <Controller
                    name="selectedFrequency"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Required' }}
                    render={({ field }) => (
                      <div>
                        <Select
                          label="Aging frequency"
                          placeholder="Select frequency"
                          options={agingFrequencyDropDownValue?.map((item) => ({
                            label: item,
                            value: item,
                          }))}
                          required
                          error={errors?.selectedFrequency}
                          {...field}
                        />
                      </div>
                    )}
                  />
                  <Controller
                    name="startDate"
                    control={control}
                    defaultValue={null}
                    rules={{ required: 'Required' }}
                    render={({ field }) => (
                      <div>
                        <DatePicker
                          label="Start Date"
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          required
                          error={errors?.startDate}
                        />
                      </div>
                    )}
                  />
                  <Controller
                    name="endDate"
                    control={control}
                    defaultValue={null}
                    rules={{ required: 'Required' }}
                    render={({ field }) => (
                      <div>
                        <DatePicker
                          label="End Date"
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          required
                          error={errors?.endDate}
                        />
                      </div>
                    )}
                  />
                </div>
              ) : (
                <div>
                  <FileUploadDownloadPopup
                    showWithoutPopup={true}
                    downloadFileTemplateName={'template'}
                    downloadTemplateApi={async () => {
                      const res = await downloadAgingTemplate(planId);
                      return res;
                    }}
                    uploadApi={async (formData) => {
                      const file = formData.get('excel_file');
                      // setFileData(file);
                      formData.delete('excel_file');
                      formData.append('file', file);
                      const res = await agingValidateCsvData(planId, formData);
                      if (res?.data?.isValid) {
                        onResponse('Aging uploaded successfully!');
                      } else {
                        onResponse('Aging validation failed!');
                      }
                      return res;
                    }}
                    onUploadSuccess={() => {
                      setAgingActionType(null);
                    }}
                  />
                </div>
              )}
            </div>
            {agingActionType === AGING_ACTION_TYPE.individualUpload && (
              <div className="pt-4 flex border-t justify-end gap-2 pr-4">
                <Button
                  variant={'outlined'}
                  onClick={() => {
                    setAgingActionType(null);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant={'filled'}
                  type={'submit'}
                  onSubmit={handleSubmit}
                >
                  {individualAgingUploading ? 'Uploading...' : 'Upload'}
                </Button>
              </div>
            )}
          </form>
        </div>
      }
      width={'w-[400px]'}
      height={'416px'}
    />
  );
};

export default AgingPopUp;
