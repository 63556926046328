import React from 'react';

const ScalesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M1.66699 14.1667V14.0415C1.66699 13.7778 1.66699 13.6459 1.68716 13.5174C1.70508 13.4033 1.73479 13.2914 1.77584 13.1835C1.82206 13.062 1.88749 12.9475 2.01834 12.7185L5.00033 7.5M1.66699 14.1667C1.66699 16.0076 3.15938 17.5 5.00033 17.5C6.84127 17.5 8.33366 16.0076 8.33366 14.1667M1.66699 14.1667V14C1.66699 13.7666 1.66699 13.65 1.71241 13.5608C1.75235 13.4824 1.8161 13.4187 1.8945 13.3787C1.98363 13.3333 2.1003 13.3333 2.33366 13.3333H7.66699C7.90035 13.3333 8.01703 13.3333 8.10616 13.3787C8.18456 13.4187 8.2483 13.4824 8.28825 13.5608C8.33366 13.65 8.33366 13.7666 8.33366 14V14.1667M5.00033 7.5L7.98231 12.7185C8.11316 12.9475 8.17859 13.062 8.22481 13.1835C8.26586 13.2914 8.29558 13.4033 8.31349 13.5174C8.33366 13.6459 8.33366 13.7778 8.33366 14.0415V14.1667M5.00033 7.5L15.0003 5.83333M11.667 12.5V12.3748C11.667 12.1111 11.667 11.9792 11.6872 11.8507C11.7051 11.7367 11.7348 11.6248 11.7758 11.5168C11.8221 11.3953 11.8875 11.2808 12.0183 11.0518L15.0003 5.83333M11.667 12.5C11.667 14.3409 13.1594 15.8333 15.0003 15.8333C16.8413 15.8333 18.3337 14.3409 18.3337 12.5M11.667 12.5V12.3333C11.667 12.1 11.667 11.9833 11.7124 11.8942C11.7524 11.8158 11.8161 11.752 11.8945 11.7121C11.9836 11.6667 12.1003 11.6667 12.3337 11.6667H17.667C17.9003 11.6667 18.017 11.6667 18.1062 11.7121C18.1846 11.752 18.2483 11.8158 18.2882 11.8942C18.3337 11.9833 18.3337 12.1 18.3337 12.3333V12.5M15.0003 5.83333L17.9823 11.0518C18.1132 11.2808 18.1786 11.3953 18.2248 11.5168C18.2659 11.6248 18.2956 11.7367 18.3135 11.8507C18.3337 11.9792 18.3337 12.1111 18.3337 12.3748V12.5M10.0003 2.5V6.66667"
        stroke="#344054"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ScalesIcon;
