import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import {
  createPlan,
  editPlan,
  fetchPlanById,
} from 'apiClient/rangeBuilder/rangeBuilder';
import ButtonLoader from 'assets/svg/buttonLoader';
import Button from 'components/atoms/button/Button';
import { setToastMessage } from 'pages/recognition/redemption/helper';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AdditionalColumns } from './AdditionalColumns';
import { MethodOfCalculation } from './MethodOfCalculation';
import { PayStructure } from './PayStructure';
import { RangeDetails } from './RangeDetails';
import { SetBenchMarks } from './SetBenchmarks';

/*
Components being reused are:-
    1. Button
*/

export const CreatePlan = () => {
  const userData = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [checkValidation, setCheckValidation] = useState(false);
  const [error, setError] = useState({});
  const [planResponse, setPlanResponse] = useState({});
  const [editedAdditionalColumns, setEditedAdditionalColumns] = useState([]);
  const [planDetails, setPlanDetails] = useState({
    compensationType: [],
    additionalColumns: [],
    title: '',
    validFrom: new Date()?.toISOString(),
    validTill: new Date()?.toISOString(),
    modeOfCalculationReference: '3 point',
    modeOfCalculationOutput: 'Range Based on Mid Point',
    createdBy: userData?.userProfileData?.userId?.value,
    calculations: '',
    benchmarkColumns: [],
  });

  const { planId } = useParams();

  useEffect(() => {
    if (location.pathname.includes('update')) {
      (async () => {
        const response = await fetchPlanById(planId);
        setPlanDetails(response.data);
        setPlanResponse(response.data);
      })();
    }
  }, []);

  const validateData = () => {
    let errorObject = {};
    const { title, calculations, benchmarkColumns, additionalColumns } =
      planDetails;

    if (!title?.length) {
      errorObject = { ...errorObject, title: "Title can't be empty!" };
    }
    errorObject = {
      ...errorObject,
      benchmarkColumns: benchmarkColumns?.map((column) => {
        if (!column?.columnName?.length && column?.calculation?.formula) {
          return {
            id: column?.id,
            columnName: "Column name can't be empty!",
          };
        } else if (
          !column?.calculation?.formula &&
          column?.columnName?.length
        ) {
          return {
            id: column?.id,
            formula: 'Please enter formula.',
          };
        } else if (
          !column?.columnName?.length &&
          !column?.calculation?.formula
        ) {
          return {
            id: column?.id,
            formula: 'Please enter formula.',
            columnName: "Column name can't be empty!",
          };
        }
      }),
    };
    errorObject = {
      ...errorObject,
      additionalColumns: additionalColumns?.map((column) => {
        if (!column?.columnName?.length) {
          return {
            id: column?.id,
            columnName: "Name can't be empty.",
          };
        }
      }),
    };
    setError(errorObject);
    return !Object.keys(errorObject)?.length ? true : false;
  };

  function getDifferenceArray(arr1, arr2) {
    // Helper function to compare two objects
    function compareObjects(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    // Result array to store objects that are different
    let differenceArray = [];

    // Iterate over the first array
    arr1.forEach((obj1) => {
      let isDifferent = true;
      // Check if the current object in arr1 is present in arr2
      for (let obj2 of arr2) {
        if (compareObjects(obj1, obj2)) {
          isDifferent = false;
          break;
        }
      }
      // If the object is different, add it to the difference array
      if (isDifferent) {
        differenceArray.push(obj1);
      }
    });

    return differenceArray;
  }

  const createBenchmarkPlan = async () => {
    try {
      // if (validateData()) {
      setLoading(true);
      const payload = planDetails;
      await createPlan({
        ...payload,
        additionalColumns: payload?.additionalColumns?.map((column) => {
          delete column?.id;
          delete column?.label;
          return { ...column, order: null };
        }),
        benchmarkColumns: payload?.benchmarkColumns?.map((benchmark) => {
          delete benchmark?.id;
          return benchmark;
        }),
        calculations: payload?.calculations?.map((calculation) => {
          delete calculation?.id;
          return calculation;
        }),
      });
      setToastMessage('Plan successfully created.');
      navigate('/range-builder/plans');
      setLoading(false);
      // }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setToastMessage('Something went wrong!');
    }
  };

  const editBenchmarkPlan = async () => {
    try {
      setLoading(true);
      let details = { id: planDetails?.id, createdBy: planDetails?.createdBy };
      if (planDetails?.title !== planResponse?.title) {
        details = { ...details, title: planDetails?.title };
      }
      if (planDetails?.validFrom !== planResponse?.validFrom) {
        details = { ...details, validFrom: planDetails?.validFrom };
      }
      if (planDetails?.validTill !== planResponse?.validTill) {
        details = { ...details, validTill: planDetails?.validTill };
      }
      if (
        planDetails?.compensationType?.toString() !==
        planResponse?.compensationType.toString()
      ) {
        details = {
          ...details,
          compensationType: planDetails?.compensationType,
        };
      }
      if (
        planDetails?.modeOfCalculationOutput !==
        planResponse?.modeOfCalculationOutput
      ) {
        details = {
          ...details,
          modeOfCalculationOutput: planDetails?.modeOfCalculationOutput,
        };
      }
      if (
        planDetails?.modeOfCalculationReference !==
        planResponse?.modeOfCalculationReference
      ) {
        details = {
          ...details,
          modeOfCalculationReference: planDetails?.modeOfCalculationReference,
        };
      }

      details = {
        ...details,
        additionalColumns: editedAdditionalColumns?.map((column) => {
          if (column?.createdBy) {
            delete column?.id;
            return column;
          }
          return column;
        }),
      };

      details = {
        ...details,
        benchmarkColumns: getDifferenceArray(
          planDetails?.benchmarkColumns,
          planResponse?.benchmarkColumns,
        )?.map((benchmark) => {
          if (
            !planResponse?.benchmarkColumns?.filter(
              (responseBenchmark) => responseBenchmark?.id === benchmark?.id,
            )?.length
          ) {
            delete benchmark?.id;
            return benchmark;
          }
          return benchmark;
        }),
      };

      details = {
        ...details,
        calculations: getDifferenceArray(
          planDetails?.calculations,
          planResponse?.calculations,
        )?.map((calculation) => {
          if (
            !planResponse?.calculationsl?.filter(
              (responseCalculation) =>
                responseCalculation?.id === calculation?.id,
            )?.length
          ) {
            delete calculation?.id;
            return calculation;
          }
          return calculation;
        }),
      };

      await editPlan(details);
      setToastMessage('Plan successfully updated.');
      navigate('/range-builder/plans');
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setToastMessage('Something went wrong!');
    }
  };

  return (
    <div className="">
      <div className="flex items-center justify-between py-6 px-8 bg-white">
        <div className="flex flex-row items-center gap-3 justify-start">
          <div
            className="p-2 rounded-lg border-2 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <ArrowSmallLeftIcon className="w-5 h-5" />
          </div>
          <div className=" flex items-center justify center text-2xl font-semibold">
            {location.pathname.includes('update') ? 'Edit Plan' : 'Create New'}
          </div>
        </div>
        <div className="flex items-center gap-5">
          <button
            className="text-sm text-gray-600 font-semibold"
            onClick={() => navigate('/range-builder/plans')}
          >
            Cancel
          </button>
          <Button
            className="text-sm font-semibold"
            variant="filled"
            onClick={() =>
              location.pathname.includes('update')
                ? editBenchmarkPlan()
                : createBenchmarkPlan()
            }
          >
            {loading ? (
              <>
                <ButtonLoader />
                {location.pathname.includes('update')
                  ? 'Saving...'
                  : 'Creating...'}
              </>
            ) : (
              <>
                {location.pathname.includes('update') ? 'Save' : 'Create New'}
              </>
            )}
          </Button>
        </div>
      </div>
      <div className="pt-6 pb-8 px-8 space-y-[10px]">
        <RangeDetails
          planDetails={planDetails}
          setPlanDetails={setPlanDetails}
          checkValidation={checkValidation}
          error={error}
          setError={setError}
        />
        <PayStructure
          planDetails={planDetails}
          setPlanDetails={setPlanDetails}
        />
        <MethodOfCalculation
          planDetails={planDetails}
          setPlanDetails={setPlanDetails}
        />
        <SetBenchMarks
          planDetails={planDetails}
          setPlanDetails={setPlanDetails}
          checkValidation={checkValidation}
          error={error}
          planResponse={planResponse}
        />
        <AdditionalColumns
          planDetails={planDetails}
          setPlanDetails={setPlanDetails}
          checkValidation={checkValidation}
          error={error}
          setError={setError}
          planResponse={planResponse}
          editedColumns={editedAdditionalColumns}
          setEditedColumns={setEditedAdditionalColumns}
        />
      </div>
    </div>
  );
};
