import { TrashIcon } from '@heroicons/react/24/outline';
import { Input } from 'components/atoms/FormElements/input/Input';
import Toggle from 'components/atoms/FormElements/toggle/Toggle';
import SingleRangeSlider from 'pages/recognition/redemption/multiRangeSlider/SingleRangeSlider';
import React, { useEffect } from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';

const ProjectData = ({ projectTitle, project, ltiGrant, paymixData }) => {
  const { register, control, watch, setValue } = useFormContext();
  const [allowLtiGrants, allowOneTimePayment] = watch([
    'allowLtiGrants',
    'allowOneTimePayment',
  ]);
  const currentUnvested = useWatch({
    control,
    name: `${project}.currentUnvested`,
  });
  const ltiGrantValues = useWatch({
    control,
    name: `${project}.ltiGrants`,
  });
  const validatePercentageTotal = (a, b, c, d) => {
    const limit = 100;
    return (
      limit - (parseFloat(a) + parseFloat(b) + parseFloat(c) + parseFloat(d))
    );
  };

  const {
    fields: ltiGrants,
    append: addLtiGrant,
    remove: removeLtiGrant,
  } = useFieldArray({
    control,
    name: `${project}.ltiGrants`,
  });

  return (
    <div className="flex flex-col gap-4 w-full">
      <p className="font-semibold text-base flex items-center text-gray-900">
        {projectTitle}
      </p>
      <div className="flex flex-col w-full gap-4">
        <div className="flex flex-col w-full gap-1">
          <div className="flex items-center justify-between gap-4">
            <p className="text-xs text-gray-600 font-medium">
              Current Base Pay
            </p>
            <p className="flex text-xs text-gray-600 font-medium justify-end items-end text-right">
              % of Avg. Increase
            </p>
          </div>
          <div className="flex">
            <div className="flex w-3/4">
              <Controller
                name={`${project}.currentBasePay`}
                control={control}
                render={({ field }) => {
                  const { onChange, ...rest } = field;
                  const handleInputChange = (e) => {
                    const inputValue = e.target.value;
                    const regex = /^\d*(\.\d{0,2})?$/; // Regex to allow numbers with up to two digits after the decimal point
                    if (regex.test(inputValue)) {
                      onChange(inputValue); // Update the form value if it matches the regex
                    }
                    // Ignore other inputs if they don't match the regex pattern
                  };

                  return (
                    <Input
                      className={`!h-10 !px-2 text-sm !py-3 !border !rounded-r-none !border-gray-200`}
                      placeholder="Enter Number"
                      type="text" // Change type to text to handle the custom input logic
                      onChange={handleInputChange}
                      {...rest}
                    />
                  );
                }}
              />
            </div>
            <div className="flex w-1/4">
              <Controller
                name={`${project}.currentBasePayPercentage`}
                control={control}
                render={({ field }) => {
                  const { onChange, ...rest } = field;
                  return (
                    <Input
                      className={`!h-10 !px-2 text-sm !py-3 !border !border-l-0 !rounded-l-none !border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                      placeholder="Percentage"
                      onChange={(e) => {
                        const value = e?.target?.value;
                        let decimal = 0;
                        if (Math.floor(value) === value) {
                          decimal = 0;
                        } else {
                          decimal =
                            value?.toString().split('.')[1]?.length || 0;
                        }
                        if (
                          0 <= value &&
                          value <= 999 &&
                          decimal <= 4 &&
                          /^\d*\.?\d*$/.test(value)
                        ) {
                          onChange(value);
                        }
                      }}
                      {...rest}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full gap-1">
          <div className="flex items-center justify-between gap-4">
            <p className="text-xs text-gray-600 font-medium">
              Variable Pay Amount
            </p>
            <p className="flex text-xs text-gray-600 justify-end items-end text-right font-medium">
              % of Avg. Increase
            </p>
          </div>
          <div className="flex">
            <div className="flex w-3/4">
              <Controller
                name={`${project}.variablePay`}
                control={control}
                render={({ field }) => {
                  const { onChange, ...rest } = field;
                  const handleInputChange = (e) => {
                    const inputValue = e.target.value;
                    const regex = /^\d*(\.\d{0,2})?$/; // Regex to allow numbers with up to two digits after the decimal point
                    if (regex.test(inputValue)) {
                      onChange(inputValue); // Update the form value if it matches the regex
                    }
                    // Ignore other inputs if they don't match the regex pattern
                  };

                  return (
                    <Input
                      className={`!h-10 !px-2 text-sm !py-3 !border !rounded-r-none !border-gray-200`}
                      placeholder="Enter Number"
                      type="text" // Change type to text to handle the custom input logic
                      onChange={handleInputChange}
                      {...rest}
                    />
                  );
                }}
              />
            </div>
            <div className="flex w-1/4">
              <Controller
                name={`${project}.variablePayPercentage`}
                control={control}
                render={({ field }) => {
                  const { onChange, ...rest } = field;
                  return (
                    <Input
                      className={`!h-10 !px-2 text-sm !py-3 !border !border-l-0 !rounded-l-none !border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                      placeholder="Percentage"
                      onChange={(e) => {
                        const value = e?.target?.value;
                        let decimal = 0;
                        if (Math.floor(value) === value) {
                          decimal = 0;
                        } else {
                          decimal =
                            value?.toString().split('.')[1]?.length || 0;
                        }

                        if (
                          0 <= value &&
                          value <= 999 &&
                          decimal <= 4 &&
                          /^\d*\.?\d*$/.test(value)
                        ) {
                          onChange(value);
                        }
                      }}
                      {...rest}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full gap-1">
          <div className="flex items-center justify-between gap-4">
            <p className="text-xs text-gray-600 font-medium">Benefits Amount</p>
            <p className="flex text-xs text-gray-600 justify-end items-end text-right font-medium">
              % of Avg. Increase
            </p>
          </div>
          <div className="flex">
            {' '}
            <div className="flex w-3/4">
              <Controller
                name={`${project}.benefitsPay`}
                control={control}
                render={({ field }) => {
                  const { onChange, ...rest } = field;
                  const handleInputChange = (e) => {
                    const inputValue = e.target.value;
                    const regex = /^\d*(\.\d{0,2})?$/; // Regex to allow numbers with up to two digits after the decimal point
                    if (regex.test(inputValue)) {
                      onChange(inputValue); // Update the form value if it matches the regex
                    }
                    // Ignore other inputs if they don't match the regex pattern
                  };

                  return (
                    <Input
                      className={`!h-10 !px-2 text-sm !py-3 !border !rounded-r-none !border-gray-200`}
                      placeholder="Enter Number"
                      type="text" // Change type to text to handle the custom input logic
                      onChange={handleInputChange}
                      {...rest}
                    />
                  );
                }}
              />
            </div>
            <div className="flex w-1/4">
              <Controller
                name={`${project}.benefitsPayPercentage`}
                control={control}
                render={({ field }) => {
                  const { onChange, ...rest } = field;
                  return (
                    <Input
                      className={`!h-10 !px-2 !py-3 text-sm !border !border-l-0 !rounded-l-none !border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                      placeholder="Percentage"
                      onChange={(e) => {
                        const value = e?.target?.value;
                        let decimal = 0;
                        if (Math.floor(value) === value) {
                          decimal = 0;
                        } else {
                          decimal =
                            value?.toString().split('.')[1]?.length || 0;
                        }

                        if (
                          0 <= value &&
                          value <= 999 &&
                          decimal <= 4 &&
                          /^\d*\.?\d*$/.test(value)
                        ) {
                          onChange(value);
                        }
                      }}
                      {...rest}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-full p-3 flex flex-col bg-white border rounded-lg shadow gap-2">
          <div className="flex items-center justify-between">
            <span className="font-semibold text-base text-gray-900">
              LTI Grants
            </span>
            <Toggle
              checked={allowLtiGrants}
              onChange={(e) => {
                setValue('allowLtiGrants', !allowLtiGrants);
                if (!e?.target?.checked) {
                  setValue(`comparisonProject.ltiGrants`, [
                    {
                      amountofYear: 0,
                      firstYearPercent: 0,
                      secondYearPercent: 0,
                      thirdYearPercent: 0,
                      fourthYearPercent: 0,
                    },
                  ]);
                  setValue(`simulationProject.ltiGrants`, [
                    {
                      amountofYear: 0,
                      firstYearPercent: 0,
                      secondYearPercent: 0,
                      thirdYearPercent: 0,
                      fourthYearPercent: 0,
                    },
                  ]);
                }
              }}
            />
          </div>
          {allowLtiGrants && (
            <>
              <div className="w-full flex flex-col gap-2 rounded-lg px-2.5 py-3 bg-gray-50 border">
                <p className="text-gray-600 text-xs mr-3">
                  Avg. Predicted growth year on year
                </p>
                <div className="w-full flex items-start h-7 relative">
                  <SingleRangeSlider
                    min={0}
                    max={100}
                    defaultValue={1}
                    project={project}
                  />
                </div>
              </div>
              <>
                <Controller
                  name={`${project}.currentUnvestedValue`}
                  control={control}
                  render={({ field }) => {
                    const { onChange, ...rest } = field;
                    const handleInputChange = (e) => {
                      if (e.target.value > 0) {
                        const inputValue = parseFloat(e.target.value);
                        const regex = /^\d*(\.\d{0,2})?$/;
                        if (regex.test(inputValue)) {
                          onChange(inputValue);
                        }
                      } else {
                        onChange(0);
                      }
                    };

                    return (
                      <Input
                        labelClassName="text-xs text-gray-600"
                        label={`Current Un-vested Grant Value`}
                        className={`!h-10 !px-2 text-sm !py-3 !border !rounded-r-none !border-gray-200`}
                        placeholder="Enter Number"
                        onChange={handleInputChange}
                        {...rest}
                      />
                    );
                  }}
                />

                <div className="flex flex-col gap-1">
                  <p className="text-xs text-gray-600 font-medium leading-5  mb-0.5">
                    Vesting schedule(% per year)
                  </p>
                  <div className="flex gap-1">
                    <Controller
                      name={`${project}.currentUnvested.firstYearPercent`}
                      control={control}
                      render={({ field }) => {
                        const { onChange, ...rest } = field;
                        return (
                          <Input
                            className={`!h-10 !px-2 !py-3 text-sm !border !border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                            // type="number"
                            // min={0}
                            placeholder="Percentage"
                            onChange={(e) => {
                              if (
                                validatePercentageTotal(
                                  parseFloat(e?.target?.value),
                                  currentUnvested?.secondYearPercent,
                                  currentUnvested?.thirdYearPercent,
                                  currentUnvested?.fourthYearPercent,
                                ) >= 0
                              ) {
                                const value = parseFloat(e?.target?.value);
                                let decimal = 0;
                                if (Math.floor(value) === value) {
                                  decimal = 0;
                                } else {
                                  decimal =
                                    value?.toString().split('.')[1]?.length ||
                                    0;
                                }

                                if (
                                  0 <= value &&
                                  value <= 100 &&
                                  decimal <= 2 &&
                                  /^\d*\.?\d*$/.test(value)
                                ) {
                                  onChange(value);
                                }
                              } else onChange(0);
                            }}
                            {...rest}
                          />
                        );
                      }}
                    />
                    <Controller
                      name={`${project}.currentUnvested.secondYearPercent`}
                      control={control}
                      render={({ field }) => {
                        const { onChange, ...rest } = field;
                        return (
                          <Input
                            className={`!h-10 !px-2 !py-3 text-sm !border !border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                            placeholder="Percentage"
                            // type="number"
                            // min={0}
                            onChange={(e) => {
                              if (
                                validatePercentageTotal(
                                  currentUnvested?.firstYearPercent,
                                  parseFloat(e?.target?.value),
                                  currentUnvested?.thirdYearPercent,
                                  currentUnvested?.fourthYearPercent,
                                ) >= 0
                              ) {
                                const value = parseFloat(e?.target?.value);
                                let decimal = 0;
                                if (Math.floor(value) === value) {
                                  decimal = 0;
                                } else {
                                  decimal =
                                    value?.toString().split('.')[1]?.length ||
                                    0;
                                }

                                if (
                                  0 <= value &&
                                  value <= 100 &&
                                  decimal <= 2 &&
                                  /^\d*\.?\d*$/.test(value)
                                ) {
                                  onChange(value);
                                }
                              } else onChange(0);
                            }}
                            {...rest}
                          />
                        );
                      }}
                    />
                    <Controller
                      name={`${project}.currentUnvested.thirdYearPercent`}
                      control={control}
                      render={({ field }) => {
                        const { onChange, ...rest } = field;
                        return (
                          <Input
                            className={`!h-10 !px-2 !py-3 text-sm !border !border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                            placeholder="Percentage"
                            // type="number"
                            // min={0}
                            onChange={(e) => {
                              if (
                                validatePercentageTotal(
                                  currentUnvested?.firstYearPercent,
                                  currentUnvested?.secondYearPercent,
                                  parseFloat(e?.target?.value),
                                  currentUnvested?.fourthYearPercent,
                                ) >= 0
                              ) {
                                const value = parseFloat(e?.target?.value);
                                let decimal = 0;
                                if (Math.floor(value) === value) {
                                  decimal = 0;
                                } else {
                                  decimal =
                                    value?.toString().split('.')[1]?.length ||
                                    0;
                                }

                                if (
                                  0 <= value &&
                                  value <= 100 &&
                                  decimal <= 2 &&
                                  /^\d*\.?\d*$/.test(value)
                                ) {
                                  onChange(value);
                                }
                              } else onChange(0);
                            }}
                            {...rest}
                          />
                        );
                      }}
                    />
                    <Controller
                      name={`${project}.currentUnvested.fourthYearPercent`}
                      control={control}
                      render={({ field }) => {
                        const { onChange, ...rest } = field;
                        return (
                          <Input
                            className={`!h-10 !px-2 !py-3 text-sm !border !border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                            placeholder="Percentage"
                            // type="number"
                            // min={0}
                            onChange={(e) => {
                              if (
                                validatePercentageTotal(
                                  currentUnvested?.firstYearPercent,
                                  currentUnvested?.secondYearPercent,
                                  currentUnvested?.thirdYearPercent,
                                  parseFloat(e?.target?.value),
                                ) >= 0
                              ) {
                                const value = parseFloat(e?.target?.value);
                                let decimal = 0;
                                if (Math.floor(value) === value) {
                                  decimal = 0;
                                } else {
                                  decimal =
                                    value?.toString().split('.')[1]?.length ||
                                    0;
                                }

                                if (
                                  0 <= value &&
                                  value <= 100 &&
                                  decimal <= 2 &&
                                  /^\d*\.?\d*$/.test(value)
                                ) {
                                  onChange(value);
                                }
                              } else onChange(0);
                            }}
                            {...rest}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </>
              {ltiGrants?.map((field, index) => (
                <>
                  <div
                    className="flex items-center justify-end -mb-6 z-20"
                    onClick={() => removeLtiGrant(index)}
                  >
                    <TrashIcon
                      className="w-4 h-4 cursor-pointer"
                      color="gray"
                    />
                  </div>
                  <Controller
                    name={`${project}.ltiGrants.[${index}].amountofYear`}
                    control={control}
                    render={({ field }) => {
                      const { onChange, ...rest } = field;
                      const handleInputChange = (e) => {
                        if (e.target.value > 0) {
                          const inputValue = parseFloat(e.target.value);
                          const regex = /^\d*(\.\d{0,2})?$/;
                          if (regex.test(inputValue)) {
                            onChange(inputValue);
                          }
                        } else {
                          onChange(0);
                        }
                      };
                      return (
                        <Input
                          labelClassName="text-xs text-gray-600"
                          label={`Grant Value in ${index + 1} Year`}
                          className={`!h-10 !px-2 !py-3 !border text-sm !border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                          placeholder="Enter number"
                          type="text" // Change type to text to handle the custom input logic
                          onChange={handleInputChange}
                          {...rest}
                        />
                      );
                    }}
                  />

                  <div className="flex flex-col gap-1">
                    <p className="text-xs text-gray-600 font-medium leading-5  mb-0.5">
                      Vesting schedule(% per year)
                    </p>
                    <div className="flex gap-1">
                      <Controller
                        name={`${project}.ltiGrants.[${index}].firstYearPercent`}
                        control={control}
                        render={({ field }) => {
                          const { onChange, ...rest } = field;
                          return (
                            <Input
                              className={`!h-10 !px-2 !py-3 text-sm !border !border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                              placeholder="Percentage"
                              // type="number"
                              // min={0}
                              onChange={(e) => {
                                if (
                                  validatePercentageTotal(
                                    parseFloat(e?.target?.value),
                                    ltiGrantValues?.[index]?.secondYearPercent,
                                    ltiGrantValues?.[index]?.thirdYearPercent,
                                    ltiGrantValues?.[index]?.fourthYearPercent,
                                  ) >= 0
                                ) {
                                  const value = parseFloat(e?.target?.value);
                                  let decimal = 0;
                                  if (Math.floor(value) === value) {
                                    decimal = 0;
                                  } else {
                                    decimal =
                                      value?.toString().split('.')[1]?.length ||
                                      0;
                                  }

                                  if (
                                    0 <= value &&
                                    value <= 100 &&
                                    decimal <= 2 &&
                                    /^\d*\.?\d*$/.test(value)
                                  ) {
                                    onChange(value);
                                  }
                                } else onChange(0);
                              }}
                              {...rest}
                            />
                          );
                        }}
                      />
                      <Controller
                        name={`${project}.ltiGrants.[${index}].secondYearPercent`}
                        control={control}
                        render={({ field }) => {
                          const { onChange, ...rest } = field;
                          return (
                            <Input
                              className={`!h-10 !px-2 !py-3 text-sm !border !border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                              placeholder="Percentage"
                              // type="number"
                              // min={0}
                              onChange={(e) => {
                                if (
                                  validatePercentageTotal(
                                    ltiGrantValues?.[index]?.firstYearPercent,
                                    parseFloat(e?.target?.value),
                                    ltiGrantValues?.[index]?.thirdYearPercent,
                                    ltiGrantValues?.[index]?.fourthYearPercent,
                                  ) >= 0
                                ) {
                                  const value = parseFloat(e?.target?.value);
                                  let decimal = 0;
                                  if (Math.floor(value) === value) {
                                    decimal = 0;
                                  } else {
                                    decimal =
                                      value?.toString().split('.')[1]?.length ||
                                      0;
                                  }

                                  if (
                                    0 <= value &&
                                    value <= 100 &&
                                    decimal <= 2 &&
                                    /^\d*\.?\d*$/.test(value)
                                  ) {
                                    onChange(value);
                                  }
                                } else onChange(0);
                              }}
                              {...rest}
                            />
                          );
                        }}
                      />
                      <Controller
                        name={`${project}.ltiGrants.[${index}].thirdYearPercent`}
                        control={control}
                        render={({ field }) => {
                          const { onChange, ...rest } = field;
                          return (
                            <Input
                              className={`!h-10 !px-2 !py-3 text-sm !border !border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                              placeholder="Percentage"
                              // type="number"
                              // min={0}
                              onChange={(e) => {
                                if (
                                  validatePercentageTotal(
                                    ltiGrantValues?.[index]?.firstYearPercent,
                                    ltiGrantValues?.[index]?.secondYearPercent,
                                    parseFloat(e?.target?.value),
                                    ltiGrantValues?.[index]?.fourthYearPercent,
                                  ) >= 0
                                ) {
                                  const value = parseFloat(e?.target?.value);
                                  let decimal = 0;
                                  if (Math.floor(value) === value) {
                                    decimal = 0;
                                  } else {
                                    decimal =
                                      value?.toString().split('.')[1]?.length ||
                                      0;
                                  }

                                  if (
                                    0 <= value &&
                                    value <= 100 &&
                                    decimal <= 2 &&
                                    /^\d*\.?\d*$/.test(value)
                                  ) {
                                    onChange(value);
                                  }
                                } else onChange(0);
                              }}
                              {...rest}
                            />
                          );
                        }}
                      />
                      <Controller
                        name={`${project}.ltiGrants.[${index}].fourthYearPercent`}
                        control={control}
                        render={({ field }) => {
                          const { onChange, ...rest } = field;
                          return (
                            <Input
                              className={`!h-10 !px-2 !py-3 text-sm !border !border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                              placeholder="Percentage"
                              // type="number"
                              // min={0}
                              onChange={(e) => {
                                if (
                                  validatePercentageTotal(
                                    ltiGrantValues?.[index]?.firstYearPercent,
                                    ltiGrantValues?.[index]?.secondYearPercent,
                                    ltiGrantValues?.[index]?.thirdYearPercent,
                                    parseFloat(e?.target?.value),
                                  ) >= 0
                                ) {
                                  const value = parseFloat(e?.target?.value);
                                  let decimal = 0;
                                  if (Math.floor(value) === value) {
                                    decimal = 0;
                                  } else {
                                    decimal =
                                      value?.toString().split('.')[1]?.length ||
                                      0;
                                  }

                                  if (
                                    0 <= value &&
                                    value <= 100 &&
                                    decimal <= 2 &&
                                    /^\d*\.?\d*$/.test(value)
                                  ) {
                                    onChange(value);
                                  }
                                } else onChange(0);
                              }}
                              {...rest}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </>
              ))}
              {ltiGrants?.length < 4 && (
                <p
                  className="font-semibold text-gray-700 text-lg cursor-pointer"
                  onClick={() => {
                    addLtiGrant({ ...ltiGrant });
                  }}
                >
                  + Add Grants
                </p>
              )}
            </>
          )}
        </div>
        <div className="w-full p-3 flex flex-col bg-white border rounded-lg shadow-md gap-2">
          <div className="flex items-center justify-between">
            <span className="font-semibold text-base text-gray-900">
              One-Time Payment
            </span>
            <Toggle
              checked={allowOneTimePayment}
              onChange={(e) => {
                setValue('allowOneTimePayment', !allowOneTimePayment);
                if (!e?.target?.checked) {
                  setValue(`${project}.oneTimePayYear1`, 0);
                  setValue(`${project}.oneTimePayYear2`, 0);
                }
              }}
            />
          </div>
          {allowOneTimePayment && (
            <>
              <Controller
                name={`${project}.oneTimePayYear1`}
                control={control}
                render={({ field }) => {
                  const { onChange, ...rest } = field;
                  const handleInputChange = (e) => {
                    if (e.target.value > 0) {
                      const inputValue = parseFloat(e.target.value);
                      const regex = /^\d*(\.\d{0,2})?$/;
                      if (regex.test(inputValue)) {
                        onChange(inputValue);
                      }
                    } else {
                      onChange(0);
                    }
                  };
                  return (
                    <Input
                      labelClassName="text-gray-600 text-xs"
                      label="Year 1"
                      className={`!h-10 !px-2 text-sm !py-3 !border !border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                      placeholder="Enter number"
                      type="text" // Change type to text to handle the custom input logic
                      onChange={handleInputChange}
                      {...rest}
                    />
                  );
                }}
              />
              <Controller
                name={`${project}.oneTimePayYear2`}
                control={control}
                render={({ field }) => {
                  const { onChange, ...rest } = field;
                  const handleInputChange = (e) => {
                    if (e.target.value > 0) {
                      const inputValue = parseFloat(e.target.value);
                      const regex = /^\d*(\.\d{0,2})?$/;
                      if (regex.test(inputValue)) {
                        onChange(inputValue);
                      }
                    } else {
                      onChange(0);
                    }
                  };
                  return (
                    <Input
                      labelClassName="text-gray-600 text-xs"
                      label="Year 2"
                      className={`!h-10 !px-2 text-sm !py-3 !border !border-gray-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                      placeholder="Enter number"
                      type="text" // Change type to text to handle the custom input logic
                      onChange={handleInputChange}
                      {...rest}
                    />
                  );
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectData;
