import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid';
import {
  downloadJobMatchingTemplate,
  getJobMatchingData,
  uploadJobMatchingData,
} from 'apiClient/rangeBuilder/rangeBuilder';
import { NoRoleIcon } from 'assets/svg/noRoleIcon';
import { TabularIcon } from 'assets/svg/tabularIcon';
import Button from 'components/atoms/button/Button';
import ModalPopup from 'components/layout/modalPopup/ModalPopup';
import FileUploadDownloadPopup from 'components/molecules/FileUpload_Download_Popup/FileUploadDownloadPopup';
import { Search } from 'pages/benefits/manageBenefits/Search';
import { setToastMessage } from 'pages/recognition/redemption/helper';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { downloadDataToCsv } from 'utils/downloadDataToCsv';
import { CompensationTypeFilter } from '../internalRange/CompensationTypeFilter';
import { CompensationUnitFilter } from '../internalRange/CompensationUnitFilter';
import { ReferenceTypeFilter } from '../internalRange/ReferenceTypeFilter';
import { ViewJobMatchingData } from './ViewJobMatchingData';
import {
  useDownloadJobMatchingData,
  useGetJobMatchingData,
  useGetJobMatchingDataFilters,
} from 'query/rangeBuilder/jobMatching/jobMatching';
import { Loader } from 'components/atoms/loader/Loader';
import { ACTION_TYPE } from '../externalData/externalDataTable/ExternalDataTable';
import { RadioButton } from 'components/atoms/FormElements/input/Input';
import CalenderPlus02 from 'assets/svg/calenderPlus02';
import AgingPopUp from './agingPopUp/AgingPopUp';
import { useDebounce } from 'hooks/useDebounce';
import { DEBOUNCE_SEARCH_TIME } from 'apiClient/api.constant';
import ButtonLoader from 'assets/svg/buttonLoader';
export const AGING_ACTION_TYPE = {
  individualUpload: 'individualUpload',
  bulkUpload: 'bulkUpload',
};

export const JobMatching = ({ planName }) => {
  const [dataAvailable, setDataAvailable] = useState(true);
  const [searchedValue, setSearchedValue] = useState('');
  const [uploadCsv, setUploadCsv] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [isLakhsCompUnit, setIsLakhsCompUnit] = useState(false);
  const [agingActionType, setAgingActionType] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filtersKeyMap, setFiltersKeyMap] = useState({});
  const [pageSize, setPageSize] = useState(12);

  const { id } = useParams();
  const { data: filtersData } = useGetJobMatchingDataFilters(id);
  const debouncedValue = useDebounce(searchedValue, DEBOUNCE_SEARCH_TIME);
  const {
    data: jobMatchingData,
    isLoading: isJobMatchingDataLoading,
    refetch: refetchJobMatchingData,
    isRefetching: isJobMatchingDataRefetching,
  } = useGetJobMatchingData(
    id,
    pageSize,
    0,
    selectedFilters?.map((filter) => {
      return {
        ...filter,
        key: filtersKeyMap?.[filter?.key],
      };
    }),
    debouncedValue,
  );
  const {
    data: downloadJobMatchingData,
    isLoading: isJobMatchingDataDownloading,
    refetch: refetchJobMatchingDownloadData,
  } = useDownloadJobMatchingData(id);

  const handleUploadData = async (formData) => {
    for (let [key, value] of formData.entries()) {
      formData.append('file', value);
      formData.delete('excel_file');
    }
    const typeTransformer = () => {
      if (actionType === ACTION_TYPE.Upload) {
        return 'new';
      } else if (actionType === ACTION_TYPE.Update) {
        return 'update';
      } else if (actionType === ACTION_TYPE.Replace) {
        return 'reset';
      }
    };
    const type = typeTransformer();
    const response = await uploadJobMatchingData(id, type, formData);
    if (response?.data?.isValid) {
      setToastMessage(`Data ${actionType}ed successfully!`);
    } else {
      setToastMessage('Invalid CSV File');
    }
    setActionType(null);
    setDataAvailable(true);
    refetchJobMatchingData();
  };

  const handleDownloadTemplate = async () => {
    const response = await downloadJobMatchingTemplate(id);
    downloadDataToCsv(response, `Template_${planName}`);
  };

  const renderUploadPopUp = (showWithoutPopup) => {
    return (
      <FileUploadDownloadPopup
        setPopupVisible={setActionType}
        title={`${actionType} Data`}
        uploadApi={(formData) => handleUploadData(formData)}
        downloadTemplateApi={handleDownloadTemplate}
        showWithoutPopup={showWithoutPopup}
      />
    );
  };

  useEffect(() => {
    const filtersKeyMap = {};
    const pivotMap = jobMatchingData?.pivotMap;
    Object.keys(pivotMap ?? {})?.forEach((key) => {
      filtersKeyMap[pivotMap?.[key]] = 'Job Details::' + key;
    });
    setFiltersKeyMap(filtersKeyMap);
  }, [filtersData, jobMatchingData]);

  useEffect(() => {
    refetchJobMatchingData();
  }, [selectedFilters, debouncedValue, pageSize]);

  return (
    <>
      {isJobMatchingDataLoading ? (
        <div
          className="flex w-full items-center justify-center"
          style={{
            height: 'calc(100vh - 195px)',
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <div className={`flex items-center px-8 justify-end`}>
            <div className="flex items-center gap-5">
              <Search
                searchedValue={searchedValue}
                setSearchedValue={setSearchedValue}
              />
              <div className="flex items-center gap-[10px]">
                <div className="relative">
                  <ArrowDownIcon
                    className="w-5 cursor-pointer h-5 text-gray-600 border-b border-gray-600"
                    onClick={() => {
                      if (!isJobMatchingDataDownloading) {
                        const res = refetchJobMatchingDownloadData();
                        downloadDataToCsv(res, `${planName}-JobMatchingData`);
                      }
                    }}
                  />
                  {isJobMatchingDataDownloading && (
                    <div className="absolute -top-1 cursor-not-allowed">
                      <ButtonLoader />
                    </div>
                  )}
                </div>
                <ArrowUpIcon
                  className="w-5 h-5 cursor-pointer text-gray-600 border-t border-gray-600"
                  onClick={() => setActionType(ACTION_TYPE.Update)}
                />
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    setAgingActionType(AGING_ACTION_TYPE.individualUpload)
                  }
                >
                  <CalenderPlus02 />
                </div>
                <TabularIcon className="cursor-pointer m-1" />
              </div>
              {actionType && (
                <ModalPopup
                  onClose={() => setActionType(null)}
                  title={`Upload Job Matchings`}
                  body={
                    <div className="p-4">
                      {actionType === ACTION_TYPE.Upload ? (
                        <>{renderUploadPopUp()}</>
                      ) : (
                        <div className="flex flex-col w-full">
                          <div className="flex flex-col gap-2.5">
                            <p className="font-figtree text-xs font-normal text-gray-600 px-4">
                              In case of existing data for matching exists
                            </p>
                            <div className="flex w-full gap-2">
                              <div className="flex w-full gap-2">
                                <RadioButton
                                  id={'update'}
                                  value={actionType === ACTION_TYPE.Update}
                                  checked={actionType === ACTION_TYPE.Update}
                                  onChange={() => {
                                    setActionType(
                                      actionType === ACTION_TYPE.Replace
                                        ? ACTION_TYPE.Update
                                        : ACTION_TYPE.Replace,
                                    );
                                  }}
                                  label="Update Matchings"
                                />
                              </div>
                              <div className="flex w-full gap-2">
                                <RadioButton
                                  id={'replace'}
                                  value={actionType === ACTION_TYPE.Replace}
                                  checked={actionType === ACTION_TYPE.Replace}
                                  label="Replace Matchings"
                                  onChange={() => {
                                    setActionType(
                                      actionType === ACTION_TYPE.Replace
                                        ? ACTION_TYPE.Update
                                        : ACTION_TYPE.Replace,
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          {renderUploadPopUp(true)}
                        </div>
                      )}
                    </div>
                  }
                  width={'w-[400px]'}
                  height={'416px'}
                />
              )}
              {agingActionType && (
                <AgingPopUp
                  agingActionType={agingActionType}
                  setAgingActionType={setAgingActionType}
                />
              )}
            </div>
          </div>
          {selectedFilters?.length > 0 && (
            <div className={`flex items-center px-8`}>
              <div className="flex items-center gap-2.5">
                {selectedFilters?.map((filter, index) => {
                  return (
                    <div
                      className="flex gap-2 px-2 text-sm font-medium gradient-border-and-text before:rounded-2xl before:border"
                      key={index}
                      onClick={() => {
                        setSelectedFilters(
                          selectedFilters?.filter(
                            (data) => data?.key !== filter?.key,
                          ),
                        );
                      }}
                    >
                      {filter?.value} <span className="cursor-pointer">X</span>
                    </div>
                  );
                })}
                {selectedFilters?.length > 0 && (
                  <div
                    className="flex text-sm font-semibold text-gray-600 cursor-pointer"
                    onClick={() => {
                      setSelectedFilters([]);
                    }}
                  >
                    Clear All
                  </div>
                )}
              </div>
            </div>
          )}
          {/* {jobMatchingData && (
            <div className="border-y bg-gray-50 border-gray-200 px-8 py-1 flex items-center justify-between">
              <div className="flex items-center">
                <CompensationTypeFilter
                  typeList={[
                    { name: 'Base Pay' },
                    { name: 'Variable Pay' },
                    { name: 'Others' },
                    { name: 'Equity' },
                  ]}
                />
                <p className="text-gray-300 px-4">|</p>
                <ReferenceTypeFilter
                  typeList={[{ name: 'Min' }, { name: 'Mid' }, { name: 'Max' }]}
                />
              </div>
              <CompensationUnitFilter
                isLakhsCompUnit={isLakhsCompUnit}
                setIsLakhsCompUnit={setIsLakhsCompUnit}
              />
            </div>
          )} */}
          <div className="px-8 pb-5 bg-white">
            {!jobMatchingData ? (
              <div className="flex flex-col items-center justify-center h-full space-y-5 my-14">
                <NoRoleIcon />
                <div className="space-y-1 flex flex-col items-center justify-between">
                  <p className="text-sm font-semibold text-gray-900">
                    There are no matches to show
                  </p>
                  <p className="text-xs text-gray-600">
                    If there are matches and you have access, you will the see
                    the benchmarks here
                  </p>
                </div>
                <div className="flex itesm-center justify-between gap-5">
                  <Button
                    variant="filled"
                    className="text-sm font-semibold"
                    onClick={() => {
                      setActionType(ACTION_TYPE.Upload);
                    }}
                  >
                    Upload Job Matching
                  </Button>
                </div>
              </div>
            ) : (
              <div className="h-fit relative">
                {isJobMatchingDataRefetching && (
                  <div className="absolute z-100 left-1/2 top-1/2">
                    <div>
                      <Loader />
                    </div>
                  </div>
                )}
                <ViewJobMatchingData
                  jobMatchingData={jobMatchingData}
                  refetchJobMatchingData={refetchJobMatchingData}
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                  filtersData={filtersData}
                  setPageSize={setPageSize}
                  isRefetchingTableData={isJobMatchingDataRefetching}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
