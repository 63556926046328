import {
  createPage,
  getAllFiles,
  getChartTypes,
  getPage,
  updateDashboard,
  updatePage,
  viewDashboardData,
} from 'apiClient/dashboardBuilder/DashboardBuilder';
import { useQuery, useMutation } from 'react-query';

export const useGetDashboardData = (payload) => {
  return useQuery(['get/DashboardData', payload], async () =>
    viewDashboardData(payload),
  );
};

export const useUpdateDashboardData = (payload, id) => {
  return useMutation(() => updateDashboard(payload, id), {
    mutationKey: ['update/DashboardData', payload, id],
  });
};

export const useCreatePage = (payload) => {
  return useMutation(() => createPage(payload), {
    mutationKey: ['create/Page', payload],
  });
};

export const useUpdatePage = (payload, id) => {
  return useMutation(
    () => updatePage(payload, id),
    {
      mutationKey: ['update/Page', payload, id],
    },
    {
      enabled: !!id,
    },
  );
};

export const useGetPage = (id, idx) => {
  return useQuery(['get/Page', id, idx], async () => getPage(id, idx), {
    enabled: !!id,
  });
};

export const useGetAllFIles = (id) => {
  return useQuery(['get/AllFiles', id], async () => getAllFiles(id), {
    enabled: !!id,
  });
};

export const useGetChartTypes = (id) => {
  return useQuery(['get/ChartTypes', id], async () => getChartTypes(id), {
    enabled: !!id,
  });
};
