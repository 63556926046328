import { useEffect, useState } from 'react';
// import Avatar from "../../components/atoms/Avatar";
import { conditions } from 'config/sideNavConfig';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ChevronDownLogo from '../../assets/svg/chevronDownLogo';
import NavItem from '../../components/atoms/navItem/NavItem';
import SideNav from '../../config/dummy';

import {
  checkModuleAccessSidebar,
  checkRoleAccess,
  isHome,
  isSurface,
} from 'utils/accessControl';

const Sidebar = ({ isCollapsed }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const theme = useSelector((state) => state.theme);
  const [sideNav, setSideNav] = useState(SideNav);
  const [isDropDownActive, setDropDownActive] = useState(null);
  const modules = useSelector((state) => state.theme?.rbac);
  const userAccess = useSelector((state) => state.theme?.userAccess);
  const [isSelectedOption, setIsSelectedOption] = useState();
  const [dropDownOpenList, setDropDownOpenList] = useState([]);
  //const isCollapsed = theme.isSidebarVisible;

  const renderSideNavSection = (
    item,
    title,
    isCollapsed,
    isDropDownActive,
    setDropDownActive,
    panel,
  ) => {
    // const sortedItem = item?.sort((a, b) => a.order > b.order);
    return (
      <div className=" ">
        {/* <p className="px-3 my-3 text-sm font-semibold text-gray-500 font-figtree">{title}</p> */}
        <div className="space-y-1">
          {item?.map((obj, index) => {
            if (
              checkModuleAccessSidebar(
                modules,
                obj?.module,
                obj?.name,
                userAccess,
              ) ||
              !obj?.module ||
              (obj?.module === 'Surface' && isSurface(modules)) // if side nav item does not have relation with module
            ) {
              if (obj?.key === 'Home' && !isHome(modules)) {
                return null;
              }
              if (
                conditions.some(
                  (cond) =>
                    cond.name === obj.name &&
                    !checkRoleAccess(
                      userAccess,
                      cond?.moduleName,
                      cond.roleAccess,
                    ),
                )
              ) {
                return null;
              }

              return (
                <NavItem
                  key={index}
                  // path={`${routes.filter((route) => obj.itemName === route.name)[0].route}`}
                  // logo={`${routes.filter((route) => obj.itemName === route.name)[0].icon}`}
                  link={obj.url}
                  isQuickLinks={title === 'Quick Links'}
                  order={obj.order}
                  newTab={obj.newTab}
                  isCollapsed={isCollapsed}
                  itemName={obj.name}
                  notificationCount={
                    obj.notificationCount > 0 ? obj.notificationCount : null
                  }
                  subMenu={obj?.sublinks}
                  subMenuIcon={
                    <ChevronDownLogo
                      isActive={
                        isDropDownActive === index + Math.pow(10, panel)
                          ? true
                          : false
                      }
                      className="h-6 w-6 p-4"
                    />
                  }
                  index={index + Math.pow(10, panel)}
                  isDropDownActive={isDropDownActive}
                  setDropDownActive={setDropDownActive}
                  setIsSelectedOption={setIsSelectedOption}
                  isSelectedOption={isSelectedOption}
                  setDropDownOpenList={setDropDownOpenList}
                  dropDownOpenList={dropDownOpenList}
                />
              );
            } else return null;
          })}
          {/* {title === 'Quick Links' &&
            item?.map((obj, index) => {
              return (
                <NavItem
                  key={index}
                  isQuickLinks={true}
                  // path={`${routes.filter((route) => obj.itemName === route.name)[0].route}`}
                  // logo={`${routes.filter((route) => obj.itemName === route.name)[0].icon}`}
                  link={obj.url}
                  order={obj.order}
                  newTab={obj.newTab}
                  isCollapsed={isCollapsed}
                  itemName={obj.name}
                  // notificationCount={obj.notificationCount > 0 ? obj.notificationCount : null}
                  subMenu={obj?.sublinks}
                  subMenuIcon={
                    <ChevronDownLogo
                      isActive={
                        isDropDownActive === index + Math.pow(10, panel)
                          ? true
                          : false
                      }
                      className="h-6 w-6 p-4"
                    />
                  }
                  index={index + Math.pow(10, panel)}
                  isDropDownActive={isDropDownActive}
                  setDropDownActive={setDropDownActive}
                  setIsSelectedOption={setIsSelectedOption}
                  isSelectedOption={isSelectedOption}
                  setDropDownOpenList={setDropDownOpenList}
                  dropDownOpenList={dropDownOpenList}
                />
              );
            })} */}
        </div>
        {/* <p className="mt-3 border-t border-gray-200"></p> */}
      </div>
    );
  };

  useEffect(() => {
    setSideNav(theme.sidenav);
  }, [theme.sidenav]);

  return (
    <aside
      className={` px-3 h-full overflow-y-auto overflow-x-hidden bg-gray-50 relative `}
    >
      <div className=" pt-4 pb-12 w-full">
        {/* <div className="mb-[26px]">
          {user?.userProfileData?.avatar?.value ? (
            <Avatar
              avatarContainerStyles="flex items-center gap-3 cursor-pointer"
              src={
                imageURLCheck(user?.userProfileData?.avatar?.value)
                // : "https://pixlok.com/wp-content/uploads/2022/02/Profile-Icon-SVG-09856789.png"
              }
              alt={trending[0].author}
              imgStyles="w-10 h-10 rounded-full"
              titleDivStyles="flex flex-col"
              titleStyles="text-base font-semibold text-gray-900 font-figtree"
              title={
                isCollapsed
                  ? null
                  : user?.userProfileData
                  ? user?.userProfileData?.displayName?.value
                  : "N/A"
              }
              subTitleStyles="text-sm font-semibold text-gray-600 font-figtree"
              subTitle={isCollapsed ? null : "View Profile"}
              onClick={() => navigate("/profile/" + user?.userProfileData?.userId?.value)}
            />
          ) : (
            <div className="flex w-full gap-4">
              <UserNameAvatar userName={user?.userProfileData?.fullName?.value} />
              <div>
                <p className="text-base font-semibold text-gray-900 font-figtree">
                  {user?.userProfileData?.displayName?.value}
                </p>
                <p
                  className="text-sm font-semibold text-gray-600 font-figtree cursor-pointer"
                  onClick={() => navigate("/profile/" + user?.userProfileData?.userId?.value)}
                >
                  View Profile
                </p>
              </div>
            </div>
          )}
        </div> */}
        {/* passing panel just to differentiate between each sidenav to make the dropdown for subMenu work properly*/}
        {/* {console.log(sideNav.primaryTabs)} */}
        {renderSideNavSection(
          sideNav.primaryTabs,
          '',
          isCollapsed,
          isDropDownActive,
          setDropDownActive,
          1,
        )}
        {renderSideNavSection(
          sideNav.workTools,
          'Work Tools',
          isCollapsed,
          isDropDownActive,
          setDropDownActive,
          2,
        )}
        {renderSideNavSection(
          sideNav.quickLinks,
          'Quick Links',
          isCollapsed,
          isDropDownActive,
          setDropDownActive,
          3,
        )}
        {renderSideNavSection(
          sideNav.admin,
          'Admin',
          isCollapsed,
          isDropDownActive,
          setDropDownActive,
          4,
        )}
        {renderSideNavSection(
          sideNav.about,
          'About',
          isCollapsed,
          isDropDownActive,
          setDropDownActive,
          5,
        )}
        {/* <div
          onClick={() => {
            dispatch(ToggleSidebar(!isCollapsed));
          }}
          className={`
          ${isCollapsed ? 'w-[4.7rem]' : 'w-64'} 
        fixed p-4 bottom-0 flex justify-between cursor-pointer bg-white z-50 `}
        >
          {isCollapsed ? (
            <div className="justify-center flex w-full ">
              <ChevronDoubleRightIcon className="w-6 h-6" />
            </div>
          ) : (
            <div className="w-full flex justify-between text-sm text-gray-900 font-semibold">
              Collapse <ChevronDoubleLeftIcon className="w-6 h-6" />
            </div>
          )}
        </div> */}
      </div>
    </aside>
  );
};

export default Sidebar;
