import ArchiveBoxIcon from 'assets/svg/archiveIcon';
import ButtonLoader from 'assets/svg/buttonLoader';
import Button from 'components/atoms/button/Button';
import ModalPopup from 'components/layout/modalPopup/ModalPopup';

const ConfirmModalPopup = ({
  setModalVisible,
  confirmType,
  onClick,
  loading,
  type,
}) => {
  let icon;
  let text;
  let descriptionText;
  let buttonText;
  switch (confirmType) {
    case 'DELETE': {
      text = `Delete ${type}`;
      descriptionText = `Deleting a ${type} will remove it permanently. Are you sure you want to delete the ${type}?`;
      icon = <ArchiveBoxIcon />;
      buttonText = loading ? (
        <>
          <ButtonLoader />
          Deleting
        </>
      ) : (
        'Yes! Delete'
      );
      break;
    }
    case 'ARCHIVE': {
      text = `Archive ${type}`;
      descriptionText = `Archiving a ${type} will move it to archived state. Are you sure you want to archive the ${type}?`;
      icon = <ArchiveBoxIcon />;
      buttonText = loading ? (
        <>
          <ButtonLoader />
          Archiving
        </>
      ) : (
        'Yes! Archive'
      );
      break;
    }
    default: {
      break;
    }
  }

  return (
    <ModalPopup
      onClose={() => setModalVisible(false)}
      title={icon}
      width="max-w-[400px]"
      body={
        <div className="px-6">
          <p className="text-lg font-semibold text-black-400">{text}</p>
          <p className="text-sm text-gray-600 mb-6">{descriptionText}</p>
          <div className="flex items-center justify-end border-t border-gray-200 px-6 py-4 gap-[18px]">
            <button
              className="text-sm font-semibold text-gray-600"
              onClick={() => setModalVisible(false)}
            >
              Cancel
            </button>
            <Button
              variant="filled"
              className="text-sm font-semibold"
              onClick={onClick}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default ConfirmModalPopup;
