import { MEDIA_LINK } from 'apiClient/api.constant';

export const imageURLCheck = (urlString) => {
  const stringFormat = urlString?.startsWith('/') ? urlString : `/${urlString}`;
  const finalUrl =
    urlString?.startsWith('https://') || urlString?.startsWith('blob:')
      ? urlString
      : `${MEDIA_LINK}${stringFormat}`;
  return finalUrl;
};
