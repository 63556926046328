/**
 * Functional component for rendering a options within a popup menu.
 *
 * @component
 * @param {React.ReactNode} icon - Icon element for the item.
 * @param {React.ReactNode} children - Content to be displayed within the item.
 * @param {string} className - Additional CSS classes for the item.
 * @returns {JSX.Element} A single item within a popup menu.
 * @example
 * <PopUpItem icon={<Icon />} disable={false}>Item 1</PopUpItem>
 */

import React, { useEffect, useRef } from 'react';

export const PopUpItem = (props) => {
  const { icon, children, className, disable, ...rest } = props;
  return (
    <li
      className={`py-3 px-4 w-full flex gap-2 items-center hover:bg-gray-100 cursor-pointer ${className} ${
        disable && 'bg-gray-100 text-gray-400 hover:bg-gray-100'
      }`}
      {...rest}
    >
      {icon}
      <span>{children}</span>
    </li>
  );
};

const PopUp = (props) => {
  const { children, closePopup, width, right, className } = props;
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        closePopup && closePopup();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className="relative " ref={ref}>
      <ul
        className={`rounded-md mt-2 border shadow-xl absolute bg-white w-[${width}px] ${
          right?.includes('px')
            ? `right-[${right}]`
            : right
            ? `right-${right}`
            : 'right-0'
        } top-0 z-50 ${className}`}
        // style={{
        //   left: width ? `-${width/2}px` : ''
        // }}
      >
        {children}
      </ul>
    </div>
  );
};

export default PopUp;
