import PlusIcon from 'assets/svg/plusIcon';
import { TableFilterGradientIcon } from 'assets/svg/tableFilterGradientIcon';
import Button from 'components/atoms/button/Button';
import { Search } from 'pages/benefits/manageBenefits/Search';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ExternalDataTable, {
  ACTION_TYPE,
} from './externalDataTable/ExternalDataTable';
import FileUploadDownloadPopup from 'components/molecules/FileUpload_Download_Popup/FileUploadDownloadPopup';
import ColumnsMappingPopUp from './columnsMappingPopUp/ColumnsMappingPopUp';
import PreviewPopUp from './previewPopUp/PreviewPopUp';
import {
  useGetAllExternalRanges,
  useGetTemplate,
  useGetUpdateTemplate,
} from 'query/rangeBuilder/externalData/externalData';
import TableSkeleton from 'components/atoms/skeletonLoaderTypes/TableSkeleton';
import NoCommunityIcon from 'assets/svg/noCommunityIcon';
import {
  getCsvHeadersAndData,
  replaceExternalDataCsv,
  updateExternalDataCsv,
  uploadExternalDataCsv,
  validateCsvData,
} from 'apiClient/rangeBuilder/rangeBuilder';
import { onResponse } from 'utils/toastMessages';
import ViewExternalData from './viewExternalData/ViewExternalData';
import Pagination from 'components/atoms/pagination/Pagination';
import ModalPopup from 'components/layout/modalPopup/ModalPopup';
import { RadioButton } from 'components/atoms/FormElements/input/Input';

const ExternalData = ({ planName }) => {
  const navigate = useNavigate();
  const { id: planId } = useParams();
  const [isAddNewPopUp, setIsAddNewPopUp] = useState(false);
  const [isColumnsMappingPopUp, setIsColumnsMappingPopUp] = useState(false);
  const [isPreviewPopUp, setIsPreviewPopUp] = useState(false);
  const [query, setQuery] = useState('');
  const [headersData, setHeadersData] = useState({});
  const [fileData, setFileData] = useState({});
  const [rangeFormTypeId, setRangeFormTypeId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [actionType, setActionType] = useState(null);
  const [isUpdateReplaceMatching, setIsUpdateReplaceMatching] = useState(null);

  const [selectedData, setSelectedData] = useState(false);

  const { data, isLoading, refetch } = useGetAllExternalRanges(
    planId,
    10,
    currentPage,
    query,
  );
  const { mutateAsync: downloadTemplate } = useGetTemplate(planId);
  const { mutateAsync: downloadUpdateTemplate } = useGetUpdateTemplate();
  const onPageChanged = (event) => {
    setCurrentPage(event?.selected);
  };

  const validateAndUpdateCsv = async (rangeFormTypeId, formData) => {
    try {
      formData.append(
        'body',
        JSON.stringify({
          id: '1',
          type: 'update',
          key: 'key',
        }),
      );

      // Validate CSV data
      const validateRes = await validateCsvData(rangeFormTypeId, formData);

      if (validateRes?.data?.isValid) {
        // Upload external data CSV
        const uploadCsvFormData = new FormData();
        uploadCsvFormData.append('file', fileData);
        // uploadCsvFormData.append('body', JSON.stringify({ mappings }));

        const apiRes = await updateExternalDataCsv(
          rangeFormTypeId,
          uploadCsvFormData,
        );

        // Check if upload was successful
        if (apiRes) {
          setRangeFormTypeId(apiRes?.data?.rangeFormTypeId);
          onResponse(`${actionType ?? 'Upload'}ed Successfully!`);
          setIsColumnsMappingPopUp(false);
          setIsPreviewPopUp(true);
        } else {
          onResponse('Error! Upload Failed');
        }
      } else {
        onResponse('Error! Validation Failed');
      }
    } catch (err) {
      onResponse('Error Occurred');
    }
  };

  const validateAndUploadCsv = async (tableData, mappingDropDownValue) => {
    try {
      const formData = new FormData();
      formData.append('file', fileData);

      // Find the first data item where templateColumn name is 'Pay Range Code'
      const body = tableData.find(
        (data) => data?.templateColumn?.name === 'Pay Range Code',
      );

      formData.append(
        'body',
        JSON.stringify({
          id: '1',
          type: actionType === ACTION_TYPE.Replace ? 'reset' : 'new',
          key: body?.uploadedColumn?.value,
        }),
      );

      // Validate CSV data
      const validateRes = await validateCsvData(planId, formData);

      if (validateRes?.data?.isValid) {
        // Prepare mappings object
        const mappings = {};
        tableData.forEach((data) => {
          if (data?.uploadedColumn?.value) {
            mappings[data?.templateColumn?.id] = {
              name: data?.uploadedColumn?.value,
              isActive: data?.isToggle,
            };
          }
        });
        mappingDropDownValue.forEach((data) => {
          mappings[data.value] = {
            name: data.value,
            isActive: true,
          };
        });

        // Upload external data CSV
        const uploadCsvFormData = new FormData();
        uploadCsvFormData.append('file', fileData);
        uploadCsvFormData.append('body', JSON.stringify({ mappings }));

        let apiRes;
        if (actionType === ACTION_TYPE.Replace) {
          apiRes = await replaceExternalDataCsv(
            rangeFormTypeId,
            uploadCsvFormData,
          );
        } else {
          apiRes = await uploadExternalDataCsv(planId, uploadCsvFormData);
        }

        // Check if upload was successful
        if (apiRes) {
          if (actionType !== ACTION_TYPE.Replace) {
            setRangeFormTypeId(apiRes?.data?.rangeFormTypeId);
          }
          onResponse(`${actionType ?? 'Upload'}ed Successfully!`);
          setIsColumnsMappingPopUp(false);
          setIsPreviewPopUp(true);
        } else {
          onResponse('Error! Upload Failed');
        }
      } else {
        onResponse('Error! Validation Failed');
      }
    } catch (error) {
      console.error('Error occurred:', error);
      onResponse('Error Occurred');
    }
  };

  const renderUploadPopUp = (showWithoutPopup) => {
    return (
      <FileUploadDownloadPopup
        title={`${actionType ?? 'Upload'} Data`}
        downloadTemplateApi={async () => {
          let res;
          if (actionType === ACTION_TYPE.Update) {
            res = await downloadUpdateTemplate(rangeFormTypeId);
          } else {
            res = await downloadTemplate(planId);
          }
          return res;
        }}
        downloadFileTemplateName={'template'}
        uploadApi={async (formData) => {
          const file = formData.get('excel_file');
          setFileData(file);
          formData.delete('excel_file');
          formData.append('file', file);
          let res;
          if (actionType === ACTION_TYPE.Update) {
            res = await validateAndUpdateCsv(rangeFormTypeId, formData);
          } else {
            res = await getCsvHeadersAndData(planId, formData);
          }
          if (res) {
            setHeadersData(res?.data);
          }
          return res;
        }}
        onUploadSuccess={() => {
          if (actionType === ACTION_TYPE.Update) {
            setIsAddNewPopUp(false);
          } else {
            setIsAddNewPopUp(false);
            setIsColumnsMappingPopUp(true);
          }
        }}
        showWithoutPopup={showWithoutPopup}
        setPopupVisible={setIsAddNewPopUp}
        uploadButtonLabel={`${
          actionType === ACTION_TYPE.Update ? 'Update' : 'Next'
        }`}
        uploadingButtonLabel={`${
          actionType === ACTION_TYPE.Update ? 'Updating' : 'Next'
        }`}
      />
    );
  };
  return (
    <div className="">
      {!selectedData ? (
        <div className="flex flex-col gap-4 w-full h-full">
          {!isLoading && data?.data?.length === 0 && query?.length < 1 ? (
            <div className="flex flex-col px-8 py-14 justify-center items-center gap-5">
              <NoCommunityIcon />
              <div className="flex flex-col justify-center text-center gap-1">
                <p className="flex w-full font-figtree text-xs font-semibold justify-center text-center">
                  There are no data to show
                </p>
                <p className="flex w-[262px] font-figtree text-xs font-normal text-gray-600 text-center">
                  If there is data and you have access, you will the see the
                  data here
                </p>
              </div>
              <Button
                variant={'outlined'}
                onClick={() => {
                  setIsAddNewPopUp(true);
                }}
              >
                Upload Data
              </Button>
            </div>
          ) : (
            <div className="flex flex-col gap-4 w-full h-full">
              <div className="flex justify-end px-8">
                <div className="flex items-center justify-end">
                  <div className="flex items-center gap-4">
                    <Search
                      searchedValue={query}
                      setSearchedValue={setQuery}
                      className="!py-2 !px-[14px]"
                    />
                    <Button
                      className="text-sm font-semibold gap-2 flex items-center justify-center"
                      variant="filled"
                      onClick={() => {
                        setIsAddNewPopUp(true);
                        setActionType(null);
                      }}
                    >
                      <PlusIcon />
                      Add New
                    </Button>
                  </div>
                </div>
              </div>
              {isLoading ? (
                <div className="px-8">
                  <TableSkeleton count={8} />
                </div>
              ) : (
                <div className="flex w-full h-full">
                  <ExternalDataTable
                    data={data?.data ?? []}
                    setSelectedData={setSelectedData}
                    refetch={refetch}
                    setIsAddNewPopUp={setIsAddNewPopUp}
                    setActionType={setActionType}
                    setRangeFormTypeId={setRangeFormTypeId}
                  />
                  <Pagination
                    onPageChanged={onPageChanged}
                    pageCount={data?.count / 10}
                    currentPage={currentPage}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="absolute h-full w-full top-0 left-0 bg-white z-10">
          <ViewExternalData
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            setIsUpdateReplaceMatching={setIsUpdateReplaceMatching}
            setActionType={setActionType}
            planName={planName}
          />
        </div>
      )}
      {isAddNewPopUp && <div>{renderUploadPopUp(false)}</div>}
      {isColumnsMappingPopUp && (
        <ColumnsMappingPopUp
          data={headersData}
          setIsColumnsMappingPopUp={setIsColumnsMappingPopUp}
          onNextClick={validateAndUploadCsv}
        />
      )}
      {isPreviewPopUp && (
        <PreviewPopUp
          setIsPreviewPopUp={setIsPreviewPopUp}
          rangeFormTypeId={rangeFormTypeId}
          refetchData={refetch}
        />
      )}
      {isUpdateReplaceMatching && (
        <div>
          <ModalPopup
            title={'Upload Job Matchings'}
            body={
              <div className="flex flex-col w-full">
                <div className="flex w-full gap-2">
                  <div className="flex w-full gap-2">
                    <RadioButton
                      id={'update'}
                      value={actionType === ACTION_TYPE.Update}
                      checked={actionType === ACTION_TYPE.Update}
                      onChange={() => {
                        console.log('here');
                        setActionType(
                          actionType === ACTION_TYPE.Replace
                            ? ACTION_TYPE.Update
                            : ACTION_TYPE.Replace,
                        );
                      }}
                      label="Update Matchings"
                    />
                  </div>
                  <div className="flex w-full gap-2">
                    <RadioButton
                      id={'replace'}
                      value={actionType === ACTION_TYPE.Replace}
                      checked={actionType === ACTION_TYPE.Replace}
                      label="Replace Matchings"
                      onChange={() => {
                        console.log('here');
                        setActionType(
                          actionType === ACTION_TYPE.Replace
                            ? ACTION_TYPE.Update
                            : ACTION_TYPE.Replace,
                        );
                      }}
                    />
                  </div>
                </div>
                {renderUploadPopUp(true)}
              </div>
            }
            height={'416px'}
            onClose={() => {
              setIsUpdateReplaceMatching(false);
            }}
            width={'w-[400px]'}
          />
        </div>
      )}
    </div>
  );
};

export default ExternalData;
