import { v4 } from 'uuid';
// export const chartList = [
//   {
//     name: 'Bar Chart',
//     type: 'bar',
//     data: [
//       { x: 'Q1', y: [10, 20, 30], label: 'Stack 1', backgroundColor: 'red' },
//       { x: 'Q2', y: [15, 25, 35], label: 'Stack 2', backgroundColor: 'blue' },
//       { x: 'Q3', y: [20, 30, 40], label: 'Stack 3', backgroundColor: 'green' },
//       { x: 'Q4', y: [25, 35, 45], label: 'Stack 4', backgroundColor: 'purple' },
//     ],
//     id: '1990101',
//   },
//   {
//     name: 'Column Chart',
//     type: 'bar',
//     data: [
//       { x: 'Jan', y: 10, label: 'Data 1', backgroundColor: 'red' },
//       { x: 'Feb', y: 20, label: 'Data 2', backgroundColor: 'blue' },
//       { x: 'Mar', y: 30, label: 'Data 3', backgroundColor: 'green' },
//       { x: 'Apr', y: 40, label: 'Data 4', backgroundColor: 'purple' },
//       { x: 'May', y: 50, label: 'Data 5', backgroundColor: 'orange' },
//     ],
//     id: '1990102',
//   },
//   {
//     name: 'Line Chart',
//     type: 'line',
//     data: [
//       { x: 'Jan', y: 5, label: 'Data 1', borderColor: 'red' },
//       { x: 'Feb', y: 10, label: 'Data 2', borderColor: 'blue' },
//       { x: 'Mar', y: 15, label: 'Data 3', borderColor: 'green' },
//       { x: 'Apr', y: 20, label: 'Data 4', borderColor: 'purple' },
//       { x: 'May', y: 25, label: 'Data 5', borderColor: 'orange' },
//     ],
//     id: '1990103',
//   },
//   {
//     name: 'Pie Chart / Donut Chart',
//     type: 'pie',
//     data: [
//       { label: 'Data 1', value: 30, backgroundColor: 'red' },
//       { label: 'Data 2', value: 20, backgroundColor: 'blue' },
//       { label: 'Data 3', value: 10, backgroundColor: 'green' },
//       { label: 'Data 4', value: 5, backgroundColor: 'purple' },
//       { label: 'Data 5', value: 35, backgroundColor: 'orange' },
//     ],
//     id: '1990104',
//   },
//   {
//     name: 'Scatter Plot',
//     type: 'scatter',
//     data: [
//       { x: 1, y: 10, label: 'Point 1', backgroundColor: 'red' },
//       { x: 2, y: 20, label: 'Point 2', backgroundColor: 'blue' },
//       { x: 3, y: 30, label: 'Point 3', backgroundColor: 'green' },
//       { x: 4, y: 40, label: 'Point 4', backgroundColor: 'purple' },
//       { x: 5, y: 50, label: 'Point 5', backgroundColor: 'orange' },
//     ],
//     id: '1990105',
//   },
//   {
//     name: 'Area Chart',
//     type: 'line',
//     data: [
//       {
//         x: 'Jan',
//         y: 15,
//         label: 'Data 1',
//         borderColor: 'red',
//         backgroundColor: 'red',
//       },
//       {
//         x: 'Feb',
//         y: 25,
//         label: 'Data 2',
//         borderColor: 'blue',
//         backgroundColor: 'blue',
//       },
//       {
//         x: 'Mar',
//         y: 35,
//         label: 'Data 3',
//         borderColor: 'green',
//         backgroundColor: 'green',
//       },
//       {
//         x: 'Apr',
//         y: 45,
//         label: 'Data 4',
//         borderColor: 'purple',
//         backgroundColor: 'purple',
//       },
//       {
//         x: 'May',
//         y: 55,
//         label: 'Data 5',
//         borderColor: 'orange',
//         backgroundColor: 'orange',
//       },
//     ],
//     fill: true,
//     id: '1990106',
//   },
//   {
//     name: 'Bubble Chart',
//     type: 'bubble',
//     data: [
//       { x: 10, y: 20, r: 5, label: 'Bubble 1', backgroundColor: 'red' },
//       { x: 15, y: 25, r: 10, label: 'Bubble 2', backgroundColor: 'blue' },
//       { x: 20, y: 30, r: 15, label: 'Bubble 3', backgroundColor: 'green' },
//     ],
//     id: '1990107',
//   },
//   {
//     name: 'Heatmap',
//     type: 'heatmap',
//     data: [
//       { x: 1, y: 1, value: 10, label: 'Cell 1', backgroundColor: 'red' },
//       { x: 1, y: 2, value: 20, label: 'Cell 2', backgroundColor: 'blue' },
//       { x: 2, y: 1, value: 30, label: 'Cell 3', backgroundColor: 'green' },
//       { x: 2, y: 2, value: 40, label: 'Cell 4', backgroundColor: 'purple' },
//     ],
//     id: '1990108',
//   },
//   {
//     name: 'Histogram',
//     type: 'bar',
//     data: [
//       { x: '0-10', y: 15, label: 'Bin 1', backgroundColor: 'red' },
//       { x: '10-20', y: 25, label: 'Bin 2', backgroundColor: 'blue' },
//       { x: '20-30', y: 35, label: 'Bin 3', backgroundColor: 'green' },
//       { x: '30-40', y: 45, label: 'Bin 4', backgroundColor: 'purple' },
//       { x: '40-50', y: 55, label: 'Bin 5', backgroundColor: 'orange' },
//     ],
//     id: '1990109',
//   },
//   {
//     name: 'Box Plot',
//     type: 'boxplot',
//     data: [
//       {
//         label: 'Data 1',
//         min: 10,
//         q1: 20,
//         median: 30,
//         q3: 40,
//         max: 50,
//         backgroundColor: 'red',
//       },
//       {
//         label: 'Data 2',
//         min: 15,
//         q1: 25,
//         median: 35,
//         q3: 45,
//         max: 55,
//         backgroundColor: 'blue',
//       },
//       {
//         label: 'Data 3',
//         min: 20,
//         q1: 30,
//         median: 40,
//         q3: 50,
//         max: 60,
//         backgroundColor: 'green',
//       },
//     ],
//     id: '1990110',
//   },
//   {
//     name: 'Treemap',
//     type: 'treemap',
//     data: [
//       { label: 'Category 1', value: 20, backgroundColor: 'red' },
//       { label: 'Category 2', value: 30, backgroundColor: 'blue' },
//       { label: 'Category 3', value: 50, backgroundColor: 'green' },
//     ],
//     id: '1990111',
//   },
//   {
//     name: 'Waterfall Chart',
//     type: 'waterfall',
//     data: [
//       { x: 'Start', y: 100, label: 'Start', backgroundColor: 'red' },
//       { x: 'Increase', y: 50, label: 'Increase', backgroundColor: 'blue' },
//       { x: 'Decrease', y: -30, label: 'Decrease', backgroundColor: 'green' },
//       { x: 'End', y: 120, label: 'End', backgroundColor: 'purple' },
//     ],
//     id: '1990112',
//   },
//   {
//     name: 'Radar Chart',
//     type: 'radar',
//     data: [
//       {
//         label: 'Data 1',
//         value: 50,
//         backgroundColor: 'rgba(255, 99, 132, 0.2)',
//         borderColor: 'rgb(255, 99, 132)',
//       },
//       {
//         label: 'Data 2',
//         value: 30,
//         backgroundColor: 'rgba(54, 162, 235, 0.2)',
//         borderColor: 'rgb(54, 162, 235)',
//       },
//       {
//         label: 'Data 3',
//         value: 40,
//         backgroundColor: 'rgba(75, 192, 192, 0.2)',
//         borderColor: 'rgb(75, 192, 192)',
//       },
//     ],
//     id: '1990113',
//   },
//   {
//     name: 'Funnel Chart',
//     type: 'funnel',
//     data: [
//       { x: 'Step 1', y: 100, label: 'Step 1', backgroundColor: 'red' },
//       { x: 'Step 2', y: 80, label: 'Step 2', backgroundColor: 'blue' },
//       { x: 'Step 3', y: 60, label: 'Step 3', backgroundColor: 'green' },
//       { x: 'Step 4', y: 40, label: 'Step 4', backgroundColor: 'purple' },
//       { x: 'Step 5', y: 20, label: 'Step 5', backgroundColor: 'orange' },
//     ],
//     id: '1990114',
//   },
//   {
//     name: 'Gantt Chart',
//     type: 'gantt',
//     data: [
//       {
//         x: 'Task 1',
//         start: new Date('2024-06-01'),
//         end: new Date('2024-06-15'),
//         backgroundColor: 'red',
//       },
//       {
//         x: 'Task 2',
//         start: new Date('2024-06-05'),
//         end: new Date('2024-06-20'),
//         backgroundColor: 'blue',
//       },
//       {
//         x: 'Task 3',
//         start: new Date('2024-06-10'),
//         end: new Date('2024-06-25'),
//         backgroundColor: 'green',
//       },
//     ],
//     id: '1990115',
//   },
//   {
//     name: 'Dual-axis Chart',
//     type: 'line',
//     data: [
//       { x: 'Jan', y1: 50, y2: 100, label: 'Data 1', borderColor: 'red' },
//       { x: 'Feb', y1: 45, y2: 90, label: 'Data 2', borderColor: 'blue' },
//       { x: 'Mar', y1: 40, y2: 80, label: 'Data 3', borderColor: 'green' },
//       { x: 'Apr', y1: 35, y2: 70, label: 'Data 4', borderColor: 'purple' },
//       { x: 'May', y1: 30, y2: 60, label: 'Data 5', borderColor: 'orange' },
//     ],
//     id: '1990116',
//   },
// ];
export const tableData = [
  {
    programName: 'Wow awards',
    programStatus: 'Draft',
    programOwner: 'Everyone',
    totalBudget: 10000,
    utilized: 4000,
    balanceAmount: 6000,
  },
  {
    programName: 'Testing Nomination',
    programStatus: 'Draft',
    programOwner: 'Everyone',
    totalBudget: 4000,
    utilized: 820,
    balanceAmount: 3180,
  },
  {
    programName: 'User Task Test Program',
    programStatus: 'Draft',
    programOwner: 'Everyone',
    totalBudget: 8000,
    utilized: 934,
    balanceAmount: 7066,
  },
  {
    programName: 'Program 001',
    programStatus: 'Published',
    programOwner: 'Everyone',
    totalBudget: 122233,
    utilized: 5045,
    balanceAmount: 117188,
  },
  {
    programName: 'Monetary Award with everything',
    programStatus: 'Published',
    programOwner: 'Nishit Only',
    totalBudget: 100000,
    utilized: 2311,
    balanceAmount: 97689,
  },
  {
    programName: 'First Paid General VE',
    programStatus: 'Draft',
    programOwner: 'Everyone',
    totalBudget: 401,
    utilized: 5800,
    balanceAmount: -5399,
  },
  {
    programName: 'Third Paid ES',
    programStatus: 'Published',
    programOwner: 'Everyone',
    totalBudget: 400,
    utilized: 20000,
    balanceAmount: -19600,
  },
];

export const chartList = [
  {
    id: v4(),
    name: 'Line Chart',
    type: 'line',
  },
  {
    id: v4(),
    name: 'Bar Chart',
    type: 'bar',
  },
  {
    id: v4(),
    name: 'Pie Chart',
    type: 'pie',
  },
  {
    id: v4(),
    name: 'Donut Chart',
    type: 'doughnut',
  },
  {
    id: v4(),
    name: 'Bubble Chart',
    type: 'bubble',
  },

  {
    id: v4(),
    name: 'Scatter Chart',
    type: 'scatter',
  },
  // {
  //   id: v4(),
  //   name: 'HeatMap',
  //   type: 'heatmap',
  // },
  // {
  //   id: '1990108',
  //   name: 'Floating Bar Chart',
  //   type: 'bar',
  //   title: 'BALANCE INCREMENT BUDGET BY BUSINESS',
  // },
  // {
  //   id: '1990107',
  //   name: 'Horizontal Bar Chart',
  //   type: 'bar',
  //   // title: 'AVERAGE INCREMENT % BY COUNTRY & RATING',
  // },
  // {
  //   id: '1990106',
  //   name: 'AVERAGE INCREMENT BY GENDER',
  //   type: 'bar',
  //   title: 'AVERAGE INCREMENT BY GENDER',
  //   options: {
  //     scales: {
  //       y1: {
  //         type: 'linear',
  //         position: 'left',
  //         beginAtZero: true,
  //         grid: {
  //           drawOnChartArea: true, // Show grid lines for y1
  //         },
  //       },
  //       y2: {
  //         type: 'linear',
  //         position: 'right',
  //         beginAtZero: true,
  //         grid: {
  //           drawOnChartArea: false, // Hide grid lines for y2
  //         },
  //       },
  //       x: {
  //         type: 'category',
  //         labels: ['Female', 'Male'],
  //       },
  //     },
  //   },
  // },
  // {
  //   id: '1990105',
  //   name: 'AVERAGE INCREMENT BY GENDER & RATING',
  //   type: 'bar',
  //   title: 'AVERAGE INCREMENT BY GENDER & RATING',
  //   options: {
  //     scales: {
  //       y1: {
  //         type: 'linear',
  //         position: 'left',
  //         beginAtZero: true,
  //         grid: {
  //           drawOnChartArea: true, // Show grid lines for y1
  //         },
  //       },
  //       y2: {
  //         type: 'linear',
  //         position: 'right',
  //         beginAtZero: true,
  //         grid: {
  //           drawOnChartArea: false, // Hide grid lines for y2
  //         },
  //       },
  //       x: {
  //         type: 'linear',
  //         labels: ['1', '2', '3', '4', '5'],
  //       },
  //     },
  //   },
  // },
  // {
  //   id: '1990104',
  //   name: 'PRE & POST REVIEW COMPA-RATIO BY BUSINESS',
  //   type: 'bar',
  //   title: 'PRE & POST REVIEW COMPA-RATIO BY BUSINESS',
  // },
  // {
  //   id: '1990103',
  //   name: 'RATING DISTRIBUTION ANALYSIS',
  //   type: 'bar',
  //   title: 'RATING DISTRIBUTION ANALYSIS',
  // },
];

export const graphOptions = [
  {
    value: 'RATING DISTRIBUTION ANALYSIS',
    label: 'RATING DISTRIBUTION ANALYSIS',
    title: 'RATING DISTRIBUTION ANALYSIS',
    chartData: {
      type: ['line'],
      labels: ['1', '2', '3', '4', '5'],
      chartOptions: {
        plugins: {
          legend: {
            display: false,
          },
        },
        maintainAspectRatio: true, //
      },
      datasets: [
        {
          label: 'Customer Success',
          data: [0.0, 16.9, 60.2, 19.5, 3.4],
          backgroundColor: '#F63D68',
          borderWidth: 1,
        },
        {
          label: 'Engineering',
          data: [0.0, 0.0, 60.7, 36.7, 2.6],
          backgroundColor: ['#16B364'],
          borderWidth: 1,
        },
        {
          label: 'Corporate',
          data: [1.7, 10.2, 62.7, 25.4, 0.0],
          backgroundColor: ['#FF692E'],
          borderWidth: 1,
        },
        {
          label: 'Marketing',
          data: [9.1, 13.6, 54.5, 20.5, 2.3],
          backgroundColor: ['#2ED3B7'],
          borderWidth: 1,
        },
        {
          label: 'Product',
          data: [0.0, 2.2, 60.0, 35.6, 2.2],
          backgroundColor: ['#F63D68'],
          borderWidth: 1,
        },
        {
          label: 'Sales',
          data: [5.7, 14.3, 68.6, 11.4, 0.0],
          backgroundColor: ['#22CCEE'],
          borderWidth: 1,
        },
      ],
    },
  },
  {
    value: 'PRE & POST REVIEW COMPA-RATIO BY BUSINESS',
    label: 'PRE & POST REVIEW COMPA-RATIO BY BUSINESS',
    title: 'PRE & POST REVIEW COMPA-RATIO BY BUSINESS',
    chartData: {
      type: ['bar', 'line'],
      chartOptions: {
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      labels: [
        'Customer Success',
        'Engineering',
        'Corporate',
        'Marketing',
        'Product',
        'Sales',
      ],
      datasets: [
        {
          label: 'Average of CURRENT COMPA-RATIO',
          data: [94.5, 86.1, 101.6, 88.9, 91.2, 99.3],
          backgroundColor: '#22CCEE',
          borderWidth: 1,
        },
        {
          label: 'Average of NEW COMPA-RATIO',
          data: [102.0, 97.6, 110.1, 91.5, 100.5, 102.4],
          backgroundColor: ['#F63D68'],
          borderWidth: 1,
        },
      ],
    },
  },
  {
    value: 'AVERAGE INCREMENT BY GENDER & RATING',
    label: 'AVERAGE INCREMENT BY GENDER & RATING',
    title: 'AVERAGE INCREMENT BY GENDER & RATING',
    chartData: {
      type: ['bar', 'line'],
      labels: ['1', '2', '3', '4', '5'],
      chartOptions: {
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      datasets: [
        {
          label: 'Female',
          data: [0.0, 1.1, 10.0, 15.3, 18.1],
          backgroundColor: '#22CCEE',
          borderWidth: 1,
        },
        {
          label: 'Male',
          data: [0.0, 1.3, 9.8, 15.8, 17.0],
          backgroundColor: ['#F63D68'],
          borderWidth: 1,
        },
        {
          label: 'Female',
          data: [3, 13, 107, 36, 3],
          backgroundColor: ['#FAC515'],
          borderWidth: 1,
        },
        {
          label: 'Male',
          data: [4, 25, 228, 119, 10],
          backgroundColor: '#7A5AF8',
          borderWidth: 1,
        },
      ],
    },
  },
  {
    value: 'AVERAGE INCREMENT BY GENDER',
    label: 'AVERAGE INCREMENT BY GENDER',
    title: 'AVERAGE INCREMENT BY GENDER',
    chartData: {
      type: ['doughnut', 'pie'],
      labels: ['Female', 'Male'],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      datasets: [
        {
          label: 'Female',
          data: [162, 382],
          backgroundColor: ['#22CCEE', '#F63D68'],
          borderWidth: 1,
        },
        {
          label: 'Male',
          data: [10.4, 11.2],
          backgroundColor: ['#22CCEE', '#F63D68'],
          borderWidth: 1,
        },
      ],
    },
  },
  {
    value: 'BALANCE INCREMENT BUDGET BY BUSINESS',
    label: 'BALANCE INCREMENT BUDGET BY BUSINESS',
    title: 'BALANCE INCREMENT BUDGET BY BUSINESS',
    chartData: {
      type: ['bar'],
      chartOptions: {
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      labels: [
        'Customer Success',
        'Engineering',
        'Corporate',
        'Marketing',
        'Product',
        'Sales',
      ],
      datasets: [
        {
          label: 'Increase',
          data: [
            { x: 'Customer Success', y: [0, 0] },
            { x: 'Engineering', y: [0, 0] },
            {
              x: 'Corporate',
              y: [
                -20310.9895 - 53364.99088,
                -20310.9895 - 53364.99088 + 8644.80512,
              ],
            },
            {
              x: 'Marketing',
              y: [
                -20310.9895 - 53364.99088 + 8644.8051,
                -20310.9895 - 53364.99088 + 8644.8051 + 18727.53,
              ],
            },
            {
              x: 'Product',
              y: [
                -20310.9895 - 53364.99088 + 8644.8051 + 18727.53,
                -20310.9895 - 53364.99088 + 8644.8051 + 18727.53 + 16563.18048,
              ],
            },
            {
              x: 'Sales',
              y: [
                -20310.9895 - 53364.99088 + 8644.8051 + 18727.53 + 16563.18048,
                -20310.9895 -
                  53364.99088 +
                  8644.8051 +
                  18727.53 +
                  16563.18048 +
                  200985.4212,
              ],
            },
          ],
          backgroundColor: '#22CCEE',
          borderWidth: 1,
        },
        {
          label: 'Decrease',
          data: [
            { x: 'Customer Success', y: [-20310.98952, 0] },
            {
              x: 'Engineering',
              y: [-20310.98952, -20310.9895 - 53364.99088],
            },
            { x: 'Corporate', y: [0, 0] },
            { x: 'Marketing', y: [0, 0] },
            { x: 'Product', y: [0, 0] },
            { x: 'Sales', y: [0, 0] },
          ],
          backgroundColor: '#F63D68',
          borderWidth: 1,
        },
      ],
    },
  },
  {
    value: 'RANGE POSITIONING SHIFT',
    label: 'RANGE POSITIONING SHIFT',
    title: 'RANGE POSITIONING SHIFT',
    chartData: {
      type: ['bar'],
      chartOptions: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
      labels: [
        'Pre inc - <P25',
        'Pre inc - P25-50',
        'Pre inc - P50-P75',
        'Pre inc - >P75',
      ],
      datasets: [
        {
          label: 'Post inc - <P25',
          data: [10, 0, 0, 0],
          backgroundColor: ['#22CCEE'],
          borderWidth: 1,
        },
        {
          label: 'Post inc - P25-50',
          data: [40, 20, 0, 0],
          backgroundColor: ['#F63D68'],
          borderWidth: 1,
        },
        {
          label: 'Post inc - P50-P75',
          data: [1, 20, 10, 0],
          backgroundColor: ['#FAC515'],
          borderWidth: 1,
        },
        {
          label: 'Post inc - >P75',
          data: [0, 2, 5, 10],
          backgroundColor: ['#7A5AF8'],
          borderWidth: 1,
        },
      ],
    },
  },
  {
    value: 'HEADCOUNT PROJECTIONS FOR NEXT 3 YRS',
    label: 'HEADCOUNT PROJECTIONS FOR NEXT 3 YRS',
    title: 'HEADCOUNT PROJECTIONS FOR NEXT 3 YRS',
    chartData: {
      type: ['bar'],
      chartOptions: {
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      labels: [
        'Customer Success',
        'Engineering',
        'Corporate',
        'Marketing',
        'Product',
        'Sales',
      ],
      datasets: [
        {
          label: '2024',
          data: [225, 550, 100, 30, 50, 20],
          backgroundColor: ['#22CCEE'],
          borderWidth: 1,
        },
        {
          label: '2025',
          data: [234, 572, 104, 31, 52, 20],
          backgroundColor: ['#F63D68'],
          borderWidth: 1,
        },
        {
          label: '2026',
          data: [257, 629, 114, 34, 57, 22],
          backgroundColor: ['#FAC515'],
          borderWidth: 1,
        },
      ],
    },
  },
  {
    value: 'BALANCE INCREMENT BUDGET BY COMP PLANNER',
    label: 'BALANCE INCREMENT BUDGET BY COMP PLANNER',
    title: 'BALANCE INCREMENT BUDGET BY COMP PLANNER',
    chartData: {
      type: ['bar'],
      chartOptions: {
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      labels: [
        'Arjun Raj',
        'Nikhil Kamath',
        'Serin Mathur',
        'Niharika Nair',
        'Adarsh Sharma',
        'John Mathew',
      ],
      datasets: [
        {
          label: 'Decrease',
          data: [
            { x: 'Arjun Raj', y: [-20311, 0] },
            { x: 'Nikhil Kamath', y: [-53365, 0] },
            { x: 'Serin Mathur', y: [0, 0] },
            { x: 'Niharika Nair', y: [0, 0] },
            { x: 'Adarsh Sharma', y: [0, 0] },
            { x: 'John Mathew', y: [0, 0] },
          ],
          backgroundColor: ['#22CCEE'],
          borderWidth: 1,
        },
        {
          label: 'Increase',
          data: [
            { x: 'Arjun Raj', y: [0, 0] },
            { x: 'Nikhil Kamath', y: [0, 0] },
            { x: 'Serin Mathur', y: [0, 8645] },
            { x: 'Niharika Nair', y: [0, 18728] },
            { x: 'Adarsh Sharma', y: [0, 16563] },
            { x: 'John Mathew', y: [0, 200985] },
          ],
          backgroundColor: ['#F63D68'],
          borderWidth: 1,
        },
      ],
    },
  },
  {
    value: 'BALANCE PROMOTION BUDGET BY COMP PLANNER',
    label: 'BALANCE PROMOTION BUDGET BY COMP PLANNER',
    title: 'BALANCE PROMOTION BUDGET BY COMP PLANNER',
    chartData: {
      type: ['bar'],
      chartOptions: {
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      labels: [
        'Arjun Raj',
        'Nikhil Kamath',
        'Serin Mathur',
        'Niharika Nair',
        'Adarsh Sharma',
        'John Mathew',
      ],
      datasets: [
        {
          label: 'Decrease',
          data: [
            { x: 'Arjun Raj', y: [-1000, 0] },
            { x: 'Nikhil Kamath', y: [-2000, 0] },
            { x: 'Serin Mathur', y: [0, 0] },
            { x: 'Niharika Nair', y: [0, 0] },
            { x: 'Adarsh Sharma', y: [0, 0] },
            { x: 'John Mathew', y: [0, 0] },
          ],
          backgroundColor: ['#22CCEE'],
          borderWidth: 1,
        },
        {
          label: 'Increase',
          data: [
            { x: 'Arjun Raj', y: [0, 0] },
            { x: 'Nikhil Kamath', y: [0, 0] },
            { x: 'Serin Mathur', y: [0, 200] },
            { x: 'Niharika Nair', y: [0, 5000] },
            { x: 'Adarsh Sharma', y: [0, 3000] },
            { x: 'John Mathew', y: [0, 3000] },
          ],
          backgroundColor: ['#F63D68'],
          borderWidth: 1,
        },
      ],
    },
  },
  {
    value: 'PRE & POST REVIEW COMPA-RATIO BY GENDER',
    label: 'PRE & POST REVIEW COMPA-RATIO BY GENDER',
    title: 'PRE & POST REVIEW COMPA-RATIO BY GENDER',
    chartData: {
      type: ['bar'],
      chartOptions: {
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      labels: ['Male', 'Female', 'Others'],
      datasets: [
        {
          label: 'Average of CURRENT COMPA-RATIO',
          data: [94.5, 86.1, 101.6],
          backgroundColor: '#22CCEE',
          borderWidth: 1,
        },
        {
          label: 'Average of NEW COMPA-RATIO',
          data: [102, 97.6, 110.1],
          backgroundColor: '#F63D68',
          borderWidth: 1,
        },
      ],
    },
  },
  {
    value: 'BALANCE PROMOTION BUDGET BY BUSINESS',
    label: 'BALANCE PROMOTION BUDGET BY BUSINESS',
    title: 'BALANCE PROMOTION BUDGET BY BUSINESS',
    chartData: {
      type: ['bar'],
      chartOptions: {
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      labels: [
        'Customer Success',
        'Engineering',
        'Corporate',
        'Marketing',
        'Product',
        'Sales',
      ],
      datasets: [
        {
          label: 'Increase',
          data: [
            { x: 'Customer Success', y: [0, 0] },
            { x: 'Engineering', y: [0, 0] },
            { x: 'Corporate', y: [-3000, -2800] },
            { x: 'Marketing', y: [-2800, 2200] },
            { x: 'Product', y: [2200, 5200] },
            { x: 'Sales', y: [5200, 8200] },
          ],
          backgroundColor: '#22CCEE',
          borderWidth: 1,
        },
        {
          label: 'Decrease',
          data: [
            { x: 'Customer Success', y: [-1000, 0] },
            { x: 'Engineering', y: [-1000, -3000] },
            { x: 'Corporate', y: [0, 0] },
            { x: 'Marketing', y: [0, 0] },
            { x: 'Product', y: [0, 0] },
            { x: 'Sales', y: [0, 0] },
          ],
          backgroundColor: '#F63D68',
          borderWidth: 1,
        },
      ],
    },
  },
  {
    value: 'PRE & POST REVIEW COMPA-RATIO',
    label: 'PRE & POST REVIEW COMPA-RATIO',
    title: 'PRE & POST REVIEW COMPA-RATIO',
    chartData: {
      type: ['bar'],
      labels: ['1', '2'],
      chartOptions: {
        indexAxis: 'y',
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      datasets: [
        {
          label: 'Average of CURRENT COMPA-RATIO',
          data: [91.2, 0],
          backgroundColor: ['#22CCEE'],
          borderWidth: 1,
        },
        {
          label: 'Average of NEW COMPA-RATIO',
          data: [0, 100],
          backgroundColor: ['#F63D68'],
          borderWidth: 1,
        },
      ],
    },
  },
  {
    value: 'AVERAGE NEW COMPA-RATIO BY BUSINESS & RATING',
    label: 'AVERAGE NEW COMPA-RATIO BY BUSINESS & RATING',
    title: 'AVERAGE NEW COMPA-RATIO BY BUSINESS & RATING',
    chartData: {
      type: ['heatmap'],
      labels: ['1', '2', '3', '4', '5'],
      datasets: [
        {
          id: 'Customer Success',
          data: [
            { x: '1', y: 112.3 },
            { x: '2', y: 101.9 },
            { x: '3', y: 94.6 },
            { x: '4', y: 91.0 },
            { x: '5', y: 102.0 },
          ],
        },
        {
          id: 'Engineering',
          data: [
            { x: '1', y: null },
            { x: '2', y: 98.2 },
            { x: '3', y: 96.0 },
            { x: '4', y: 97.0 },
            { x: '5', y: 97.6 },
          ],
        },
        {
          id: 'Corporate',
          data: [
            { x: '1', y: 100.7 },
            { x: '2', y: 111.3 },
            { x: '3', y: 115.2 },
            { x: '4', y: 101.5 },
            { x: '5', y: null },
          ],
        },
        {
          id: 'Marketing',
          data: [
            { x: '1', y: 89.7 },
            { x: '2', y: 82.2 },
            { x: '3', y: 96.4 },
            { x: '4', y: 95.9 },
            { x: '5', y: 98.4 },
          ],
        },
        {
          id: 'Product',
          data: [
            { x: '1', y: null },
            { x: '2', y: 83.8 },
            { x: '3', y: 103.8 },
            { x: '4', y: 100.1 },
            { x: '5', y: 95.4 },
          ],
        },
        {
          id: 'Sales',
          data: [
            { x: '1', y: 113.9 },
            { x: '2', y: 95.3 },
            { x: '3', y: 105.4 },
            { x: '4', y: 94.5 },
            { x: '5', y: null },
          ],
        },
        {
          id: 'Grand Total',
          data: [
            { x: '1', y: 98.2 },
            { x: '2', y: 104.4 },
            { x: '3', y: 101.6 },
            { x: '4', y: 97.4 },
            { x: '5', y: 93.9 },
          ],
        },
      ],
    },
  },
  {
    value: 'AVERAGE NEW COMPA-RATIO BY COUNTRY & RATING',
    label: 'AVERAGE NEW COMPA-RATIO BY COUNTRY & RATING',
    title: 'AVERAGE NEW COMPA-RATIO BY COUNTRY & RATING',
    chartData: {
      type: ['heatmap'],
      labels: ['1', '2', '3', '4', '5'],
      datasets: [
        {
          id: 'Canada',
          data: [
            { x: '1', y: 109.6 },
            { x: '2', y: 233.3 },
            { x: '3', y: 102.0 },
            { x: '4', y: 101.2 },
            { x: '5', y: 98.4 },
          ],
        },
        {
          id: 'Costa Rica',
          data: [
            { x: '1', y: null },
            { x: '2', y: 130.0 },
            { x: '3', y: null },
            { x: '4', y: null },
            { x: '5', y: 130.0 },
          ],
        },
        {
          id: 'India',
          data: [
            { x: '1', y: 81.8 },
            { x: '2', y: 103.2 },
            { x: '3', y: 101.1 },
            { x: '4', y: 97.3 },
            { x: '5', y: 93.5 },
          ],
        },
        {
          id: 'Poland',
          data: [
            { x: '1', y: null },
            { x: '2', y: null },
            { x: '3', y: null },
            { x: '4', y: null },
            { x: '5', y: null },
          ],
        },
        {
          id: 'United Kingdom',
          data: [
            { x: '1', y: 85.5 },
            { x: '2', y: null },
            { x: '3', y: 116.3 },
            { x: '4', y: 97.9 },
            { x: '5', y: 105.0 },
          ],
        },
        {
          id: 'United States',
          data: [
            { x: '1', y: 109.5 },
            { x: '2', y: 94.8 },
            { x: '3', y: 100.8 },
            { x: '4', y: 97.5 },
            { x: '5', y: 99.7 },
          ],
        },
        {
          id: 'Grand Total',
          data: [
            { x: '1', y: 98.2 },
            { x: '2', y: 104.4 },
            { x: '3', y: 101.6 },
            { x: '4', y: 97.4 },
            { x: '5', y: 100.4 },
          ],
        },
      ],
    },
  },
];

export const getChartData = (chartType) => {
  let chartData;
  switch (chartType) {
    case 'Line Chart':
      chartData = {
        labels: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
        datasets: [
          {
            label: 'Sales',
            data: [100, 120, 110, 130, 140, 150, 160, 170, 180, 190, 200, 210],
            backgroundColor: '#22CCEE',
          },
        ],
      };
      break;
    case 'Bar Chart':
      chartData = {
        labels: [
          'Customer Success',
          'Engineering',
          'Corporate',
          'Marketing',
          'Product',
          'Sales',
        ],
        datasets: [
          {
            label: '2024',
            data: [225, 550, 100, 30, 50, 20],
            backgroundColor: ['#22CCEE'],
            borderWidth: 1,
          },
          {
            label: '2025',
            data: [234, 572, 104, 31, 52, 20],
            backgroundColor: ['#F63D68'],
            borderWidth: 1,
          },
          {
            label: '2026',
            data: [257, 629, 114, 34, 57, 22],
            backgroundColor: ['#FAC515'],
            borderWidth: 1,
          },
        ],
      };
      break;
    case 'Pie Chart':
      chartData = {
        labels: ['1', '2', '3', '4', '5'],
        datasets: [
          {
            data: [1.3, 6.9, 61.1, 28.3, 2.4],
            backgroundColor: [
              '#22CCEE',
              '#F63D68',
              '#FAC515',
              '#7A5AF8',
              '#16B364',
            ],
            borderWidth: 1,
          },
        ],
      };
      break;
    case 'Donut Chart':
      chartData = {
        labels: ['Apples', 'Bananas', 'Oranges', 'Grapes', 'Pears'],
        datasets: [
          {
            label: chartType === 'Bar Chart' ? 'Fruit Sales' : null,
            data: [20, 30, 40, 50, 60],
            backgroundColor: [
              '#22CCEE',
              '#F63D68',
              'FAC515',
              '#7A5AF8',
              '#16B364',
            ],
            borderWidth: 1,
          },
        ],
      };
      break;
    case 'Polar Area Chart':
      chartData = {
        labels: ['Red', 'Green', 'Yellow', 'Grey', 'Blue'],
        datasets: [
          {
            label: 'My First Dataset',
            data: [11, 16, 7, 3, 14],
            backgroundColor: [
              'rgb(255, 99, 132)',
              'rgb(75, 192, 192)',
              'rgb(255, 205, 86)',
              'rgb(201, 203, 207)',
              'rgb(54, 162, 235)',
            ],
          },
        ],
      };
      break;
    case 'Bubble Chart':
      chartData = {
        datasets: [
          {
            label: 'Bubble Chart',
            data: [
              { x: 10, y: 20, r: 10 },
              { x: 20, y: 30, r: 20 },
              { x: 30, y: 40, r: 30 },
              { x: 40, y: 50, r: 40 },
              { x: 50, y: 60, r: 50 },
            ],
            backgroundColor: '#22CCEE',
            // borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
          },
        ],
      };
      break;
    case 'Radar Chart':
      chartData = {
        labels: [
          'Eating',
          'Drinking',
          'Sleeping',
          'Designing',
          'Coding',
          'Cycling',
          'Running',
        ],
        datasets: [
          {
            label: 'My First Dataset',
            data: [65, 59, 90, 81, 56, 55, 40],
            fill: true,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgb(255, 99, 132)',
            pointBackgroundColor: 'rgb(255, 99, 132)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(255, 99, 132)',
          },
          {
            label: 'My Second Dataset',
            data: [28, 48, 40, 19, 96, 27, 100],
            fill: true,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgb(54, 162, 235)',
            pointBackgroundColor: 'rgb(54, 162, 235)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(54, 162, 235)',
          },
        ],
      };
      break;
    case 'Scatter Chart':
      chartData = {
        options: {
          scales: {
            x: {
              type: 'linear',
              position: 'bottom',
            },
          },
        },
        datasets: [
          {
            label: 'Scatter Dataset',
            data: [
              {
                x: -10,
                y: 0,
              },
              {
                x: 0,
                y: 10,
              },
              {
                x: 10,
                y: 5,
              },
              {
                x: 0.5,
                y: 5.5,
              },
            ],
            backgroundColor: '#22CCEE',
          },
        ],
      };
      break;
    case 'Horizontal Bar Chart':
      chartData = {
        type: ['bar'],
        options: {
          indexAxis: 'y',
        },
        labels: ['5', '4', '3', '2', '1'],
        datasets: [
          {
            label: 'Canada',
            data: [0.0, 3.0, 4.1, 5.2, 6.2].reverse(),
            backgroundColor: '#22CCEE',
            // borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
          },
          {
            label: 'Costa Rica',
            data: [0, 0, 2.1, 0, 0].reverse(),
            backgroundColor: '#F63D68',
            // borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
          },
          {
            label: 'India',
            data: [0, 1.5, 11.3, 16.9, 18.2].reverse(),
            backgroundColor: '#FAC515',
            // borderColor: 'rgba(255, 206, 86, 1)',
            borderWidth: 1,
          },
          {
            label: 'Poland',
            data: [0, 0, 3.3, 0, 0].reverse(),
            backgroundColor: '#7A5AF8',
            // borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          },
          {
            label: 'United Kingdom',
            data: [0, 0, 4.4, 3, 0].reverse(),
            backgroundColor: '#16B364',
            // borderColor: 'rgba(153, 102, 255, 1)',
            borderWidth: 1,
          },
          {
            label: 'United States',
            data: [0, 0.3, 3.2, 7.1, 0].reverse(),
            backgroundColor: '#FF692E',
            // borderColor: 'rgba(255, 159, 64, 1)',
            borderWidth: 1,
          },
        ],
      };
      break;
    case 'BALANCE INCREMENT BUDGET BY BUSINESS':
      chartData = {
        type: ['bar'],
        labels: [
          'Customer Success',
          'Engineering',
          'Corporate',
          'Marketing',
          'Product',
          'Sales',
        ],
        datasets: [
          {
            label: 'Increase',
            data: [
              { x: 'Customer Success', y: [0, 0] },
              { x: 'Engineering', y: [0, 0] },
              {
                x: 'Corporate',
                y: [
                  -20310.9895 - 53364.99088,
                  -20310.9895 - 53364.99088 + 8644.80512,
                ],
              },
              {
                x: 'Marketing',
                y: [
                  -20310.9895 - 53364.99088 + 8644.8051,
                  -20310.9895 - 53364.99088 + 8644.8051 + 18727.53,
                ],
              },
              {
                x: 'Product',
                y: [
                  -20310.9895 - 53364.99088 + 8644.8051 + 18727.53,
                  -20310.9895 -
                    53364.99088 +
                    8644.8051 +
                    18727.53 +
                    16563.18048,
                ],
              },
              {
                x: 'Sales',
                y: [
                  -20310.9895 -
                    53364.99088 +
                    8644.8051 +
                    18727.53 +
                    16563.18048,
                  -20310.9895 -
                    53364.99088 +
                    8644.8051 +
                    18727.53 +
                    16563.18048 +
                    200985.4212,
                ],
              },
            ],
            backgroundColor: '#22CCEE',
            borderWidth: 1,
          },
          {
            label: 'Decrease',
            data: [
              { x: 'Customer Success', y: [-20310.98952, 0] },
              {
                x: 'Engineering',
                y: [-20310.98952, -20310.9895 - 53364.99088],
              },
              { x: 'Corporate', y: [0, 0] },
              { x: 'Marketing', y: [0, 0] },
              { x: 'Product', y: [0, 0] },
              { x: 'Sales', y: [0, 0] },
            ],
            backgroundColor: '#F63D68',
            borderWidth: 1,
          },
        ],
      };
      break;
    case 'AVERAGE INCREMENT BY GENDER':
      chartData = {
        title: 'AVERAGE INCREMENT BY GENDER',
        type: ['doughnut', 'scatter', 'pie'],
        labels: ['Female', 'Male'],
        datasets: [
          {
            label: 'Female',
            data: [162, 382],
            backgroundColor: ['#22CCEE', '#F63D68'],
            borderWidth: 1,
          },
          {
            label: 'Male',
            data: [10.4, 11.2],
            backgroundColor: ['#22CCEE', '#F63D68'],
            borderWidth: 1,
          },
        ],
      };
      break;
    case 'AVERAGE INCREMENT BY GENDER & RATING':
      chartData = {
        title: 'AVERAGE INCREMENT BY GENDER & RATING',
        type: ['bar', 'line'],
        labels: ['1', '2', '3', '4', '5'],
        datasets: [
          {
            label: 'Female',
            type: 'bar',
            data: [0.0, 1.1, 10.0, 15.3, 18.1],
            backgroundColor: '#22CCEE',
            borderWidth: 1,
            yAxisID: 'y1',
          },
          {
            label: 'Male',
            type: 'bar',
            data: [0.0, 1.3, 9.8, 15.8, 17.0],
            backgroundColor: ['#F63D68'],
            borderWidth: 1,
            yAxisID: 'y1',
          },
          {
            label: 'Female',
            type: 'scatter',
            data: [3, 13, 107, 36, 3],
            backgroundColor: ['#FAC515'],
            borderWidth: 1,
            yAxisID: 'y2',
          },
          {
            label: 'Male',
            type: 'scatter',
            data: [4, 25, 228, 119, 10],
            backgroundColor: '#7A5AF8',
            borderWidth: 1,
            yAxisID: 'y2',
          },
        ],
      };
      break;
    case 'PRE & POST REVIEW COMPA-RATIO BY BUSINESS':
      chartData = {
        title: 'PRE & POST REVIEW COMPA-RATIO BY BUSINESS',
        type: ['bar', 'line'],
        labels: [
          'Customer Success',
          'Engineering',
          'Corporate',
          'Marketing',
          'Product',
          'Sales',
        ],
        datasets: [
          {
            label: 'Average of CURRENT COMPA-RATIO',
            data: [94.5, 86.1, 101.6, 88.9, 91.2, 99.3],
            backgroundColor: '#FAC515',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
          },
          {
            label: 'Average of NEW COMPA-RATIO',
            data: [102.0, 97.6, 110.1, 91.5, 100.5, 102.4],
            backgroundColor: ['#7A5AF8'],
            borderColor: ['rgba(54, 162, 235, 1)'],
            borderWidth: 1,
          },
        ],
      };
      break;
    case 'RATING DISTRIBUTION ANALYSIS':
      chartData = {
        type: ['bar', 'line'],
        title: 'RATING DISTRIBUTION ANALYSIS',
        labels: ['1', '2', '3', '4', '5'],
        datasets: [
          {
            label: 'Customer Success',
            data: [0.0, 16.9, 60.2, 19.5, 3.4],
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
          },
          {
            label: 'Engineering',
            data: [0.0, 0.0, 60.7, 36.7, 2.6],
            backgroundColor: ['rgba(54, 162, 235, 0.2)'],
            borderColor: ['rgba(54, 162, 235, 1)'],
            borderWidth: 1,
          },
          {
            label: 'Corporate',
            data: [1.7, 10.2, 62.7, 25.4, 0.0],
            backgroundColor: ['rgba(255, 206, 86, 0.2)'],
            borderColor: ['rgba(255, 206, 86, 1)'],
            borderWidth: 1,
          },
          {
            label: 'Marketing',
            data: [9.1, 13.6, 54.5, 20.5, 2.3],
            backgroundColor: ['rgba(75, 192, 192, 0.2)'],
            borderColor: ['rgba(75, 192, 192, 1)'],
            borderWidth: 1,
          },
          {
            label: 'Product',
            data: [0.0, 2.2, 60.0, 35.6, 2.2],
            backgroundColor: ['rgba(54, 162, 235, 0.2)'],
            borderColor: ['rgba(54, 162, 235, 1)'],
            borderWidth: 1,
          },
          {
            label: 'Sales',
            data: [5.7, 14.3, 68.6, 11.4, 0.0],
            backgroundColor: ['rgba(153, 102, 255, 0.2)'],
            borderColor: ['rgba(153, 102, 255, 1)'],
            borderWidth: 1,
          },
        ],
      };
      break;
    case 'PRE & POST REVIEW COMPA-RATIO ':
      chartData = {
        type: ['bar'],
        title: 'PRE & POST REVIEW COMPA-RATIO',
        labels: [
          'Average of CURRENT COMPA-RATIO',
          'Average of NEW COMPA-RATIO',
        ],
        datasets: [
          {
            label: 'Dataset',
            data: [91.2, 100],
            backgroundColor: ['#22CCEE', '#F63D68'],
            borderWidth: 1,
          },
        ],
      };
      break;
    case 'RANGE POSITIONING SHIFT':
      chartData = {
        type: ['bar'],
        title: 'RANGE POSITIONING SHIFT',
        labels: [
          'Pre inc - <P25',
          'Pre inc - P25-50',
          'Pre inc - P50-P75',
          'Pre inc - >P75',
        ],
        datasets: [
          {
            label: 'Post inc - <P25',
            data: [10, 0, 0, 0],
            backgroundColor: ['#22CCEE'],
            borderWidth: 1,
          },
          {
            label: 'Post inc - P25-50',
            data: [40, 20, 0, 0],
            backgroundColor: ['#F63D68'],
            borderWidth: 1,
          },
          {
            label: 'Post inc - P50-P75',
            data: [1, 20, 10, 0],
            backgroundColor: ['#FAC515'],
            borderWidth: 1,
          },
          {
            label: 'Post inc - >P75',
            data: [0, 2, 5, 10],
            backgroundColor: ['#7A5AF8'],
            borderWidth: 1,
          },
        ],
      };
      break;
    case 'BALANCE INCREMENT BUDGET BY COMP PLANNER':
      chartData = {
        type: ['bar'],
        title: 'BALANCE INCREMENT BUDGET BY COMP PLANNER',
        labels: [
          'Arjun Raj',
          'Nikhil Kamath',
          'Serin Mathur',
          'Niharika Nair',
          'Adarsh Sharma',
          'John Mathew',
        ],
        datasets: [
          {
            label: 'Decrease',
            data: [
              { y: [-20311, 0] },
              { y: [-53365, 0] },
              { y: [0, 0] },
              { y: [0, 0] },
              { y: [0, 0] },
              { y: [0, 0] },
            ],
            backgroundColor: ['#22CCEE'],
            borderWidth: 1,
          },
          {
            label: 'Increase',
            data: [
              { y: [0, 0] },
              { y: [0, 0] },
              { y: [0, 8645] },
              { y: [0, 18728] },
              { y: [0, 16563] },
              { y: [0, 200985] },
            ],
            backgroundColor: ['#F63D68'],
            borderWidth: 1,
          },
        ],
      };
      break;
    case 'BALANCE PROMOTION BUDGET BY COMP PLANNER':
      chartData = {
        type: ['bar'],
        title: 'BALANCE PROMOTION BUDGET BY COMP PLANNER',
        labels: [
          'Arjun Raj',
          'Nikhil Kamath',
          'Serin Mathur',
          'Niharika Nair',
          'Adarsh Sharma',
          'John Mathew',
        ],
        datasets: [
          {
            label: 'Decrease',
            data: [
              { y: [-1000, 0] },
              { y: [-2000, 0] },
              { y: [0, 0] },
              { y: [0, 0] },
              { y: [0, 0] },
              { y: [0, 0] },
            ],
            backgroundColor: ['#22CCEE'],
            borderWidth: 1,
          },
          {
            label: 'Increase',
            data: [
              { y: [0, 0] },
              { y: [0, 0] },
              { y: [0, 200] },
              { y: [0, 5000] },
              { y: [0, 3000] },
              { y: [0, 3000] },
            ],
            backgroundColor: ['#F63D68'],
            borderWidth: 1,
          },
        ],
      };
      break;
    case 'BALANCE PROMOTION BUDGET BY BUSINESS':
      chartData = {
        type: ['bar'],
        labels: [
          'Customer Success',
          'Engineering',
          'Corporate',
          'Marketing',
          'Product',
          'Sales',
        ],
        datasets: [
          {
            label: 'Increase',
            data: [
              { x: 'Customer Success', y: [0, 0] },
              { x: 'Engineering', y: [0, 0] },
              { x: 'Corporate', y: [-3000, -2800] },
              { x: 'Marketing', y: [-2800, 2200] },
              { x: 'Product', y: [2200, 5200] },
              { x: 'Sales', y: [5200, 8200] },
            ],
            backgroundColor: '#22CCEE',
            borderWidth: 1,
          },
          {
            label: 'Decrease',
            data: [
              { x: 'Customer Success', y: [-1000, 0] },
              { x: 'Engineering', y: [-1000, -3000] },
              { x: 'Corporate', y: [0, 0] },
              { x: 'Marketing', y: [0, 0] },
              { x: 'Product', y: [0, 0] },
              { x: 'Sales', y: [0, 0] },
            ],
            backgroundColor: '#F63D68',
            borderWidth: 1,
          },
        ],
      };
      break;
    case 'PRE & POST REVIEW COMPA-RATIO BY GENDER':
      chartData = {
        type: ['bar'],
        labels: ['Male', 'Female', 'Others'],
        datasets: [
          {
            label: 'Average of CURRENT COMPA-RATIO',
            data: [94.5, 86.1, 101.6],
            backgroundColor: '#22CCEE',
            borderWidth: 1,
          },
          {
            label: 'Average of NEW COMPA-RATIO',
            data: [102, 97.6, 110.1],
            backgroundColor: '#F63D68',
            borderWidth: 1,
          },
        ],
      };
      break;
    case 'HEADCOUNT PROJECTIONS FOR NEXT 3 YRS':
      chartData = {
        labels: [
          'Customer Success',
          'Engineering',
          'Corporate',
          'Marketing',
          'Product',
          'Sales',
        ],
        datasets: [
          {
            label: '2024',
            data: [225, 550, 100, 30, 50, 20],
            backgroundColor: ['#22CCEE'],
            borderWidth: 1,
          },
          {
            label: '2025',
            data: [234, 572, 104, 31, 52, 20],
            backgroundColor: ['#F63D68'],
            borderWidth: 1,
          },
          {
            label: '2026',
            data: [257, 629, 114, 34, 57, 22],
            backgroundColor: ['#FAC515'],
            borderWidth: 1,
          },
        ],
      };
      break;
    // Add more chart types here if needed
    default:
      console.log('Invalid chart type');
  }
  return chartData;
};
