import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid';
import { TabularIcon } from 'assets/svg/tabularIcon';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { rangeData, tableColumns } from '../../../../config/rangeBuilderData';
import { RangeTable } from 'pages/rangeBuilder/externalData/viewExternalData/rangeTable/RangeTable';
import { ACTION_TYPE } from '../externalDataTable/ExternalDataTable';
import {
  useDeleteExternalDataRow,
  useDownloadExternalData,
  useGetExternalDataFilters,
  useGetPreviewData,
} from 'query/rangeBuilder/externalData/externalData';
import { CompensationTypeFilter } from 'pages/rangeBuilder/internalRange/CompensationTypeFilter';
import ViewDetailsPopUp from './viewDetailsPopUp/ViewDetailsPopUp';
import {
  colorArrayShade1,
  colorArrayShade2,
  colorArrayShade4,
} from 'pages/rangeBuilder/constants';
import { onResponse } from 'utils/toastMessages';
import { Search } from 'pages/benefits/manageBenefits/Search';
import { Loader } from 'components/atoms/loader/Loader';
import { useDebounce } from 'hooks/useDebounce';
import { DEBOUNCE_SEARCH_TIME } from 'apiClient/api.constant';
import { downloadDataToCsv } from 'utils/downloadDataToCsv';
import ButtonLoader from 'assets/svg/buttonLoader';

export const removeUntilLastDoubleColon = (str) => {
  const lastDoubleColonIndex = str.lastIndexOf('::');

  // If no double colon is found, return the original string
  if (lastDoubleColonIndex === -1) {
    return str;
  }

  // Slice the string to remove all characters up to and including the last double colon
  return str.slice(lastDoubleColonIndex + 2);
};

const ViewExternalData = ({
  selectedData,
  setSelectedData,
  setIsUpdateReplaceMatching,
  setActionType,
  planName,
}) => {
  const navigate = useNavigate();
  const [benchmarkWithColSpan, setBenchmarkWithColSpan] = useState([]);
  const [subHeadings, setSubHeadings] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [searchedValue, setSearchedValue] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedCompType, setSelectedCompType] = useState([]);
  const [compensationTypeList, setCompensationTypeList] = useState([]);
  const [dataRes, setDataRes] = useState([]);
  const [mappingRes, setMappingRes] = useState([]);
  const [isViewDetailsPopUp, setIsViewDetailsPopUp] = useState(null);
  const [pageSize, setPageSize] = useState(12);
  const { data: filtersData, isLoading: isFiltersLoading } =
    useGetExternalDataFilters(selectedData?.id);
  const { mutateAsync: deleteExternalData } = useDeleteExternalDataRow();
  const {
    data: downloadExternalData,
    isLoading: isDownloadDataLoading,
    refetch: refetchDownloadExternalData,
  } = useDownloadExternalData(selectedData?.id);
  const debouncedValue = useDebounce(searchedValue, DEBOUNCE_SEARCH_TIME);

  const {
    data: previewData,
    isLoading: isPreviewDataLoading,
    refetch: refetchPreviewData,
    isRefetching: isPreviewDataRefetching,
  } = useGetPreviewData(
    selectedData?.id,
    pageSize,
    0,
    selectedFilters,
    debouncedValue,
  );

  const isUUID = (str) => {
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(str);
  };

  const removePrefixes = (str) => {
    return str.replace(/^(BM_|PC_)/, '');
  };

  const splitAtLastUnderscore = (str) => {
    const lastUnderscoreIndex = str?.lastIndexOf('_');

    // If no underscore is found, return the original string and an empty string
    if (lastUnderscoreIndex === -1) {
      return [str, ''];
    }

    const part1 = str.slice(0, lastUnderscoreIndex);
    const part2 = str.slice(lastUnderscoreIndex + 1);

    return [part1, part2];
  };

  useEffect(() => {
    if (previewData?.data?.mappings && previewData?.data?.data) {
      const mappingRes = previewData?.data?.mappings; // extract mapping from the response
      const dataRes = previewData?.data?.data; // extract data from the response
      setDataRes(dataRes);
      setMappingRes(mappingRes);

      const subHeadersWithParent = Object.keys(
        dataRes?.[0]?.uploadedJson ?? {},
      )?.filter(
        (key) =>
          mappingRes?.[key] &&
          key?.includes('_') &&
          isUUID(key?.split('_')?.[0]),
      ); // All header which Parents exist
      const subHeadersWithoutParent = Object.keys(
        dataRes?.[0]?.uploadedJson ?? {},
      )?.filter(
        (key) =>
          !mappingRes?.[key] ||
          !(key?.includes('_') && isUUID(key?.split('_')?.[0])),
      ); // All SubHeader which parent does not exits
      const uniqueHeaders = new Map(); // Mapper for Unique Headers and their count
      const headerIdMap = new Map(); // Header Name to Id mapper
      subHeadersWithParent?.forEach((header) => {
        const nameWithoutBMUnderScoreAndPCUnderScore = splitAtLastUnderscore(
          mappingRes?.[header]?.name ?? '',
        )?.[0];
        const ele = nameWithoutBMUnderScoreAndPCUnderScore;
        if (uniqueHeaders?.has(ele)) {
          uniqueHeaders?.set(ele, uniqueHeaders?.get(ele) + 1);
        } else {
          uniqueHeaders?.set(ele, 1);
          headerIdMap?.set(ele, header?.split('_')?.[0]);
        }
      });
      const typeList = [];
      for (let [key, value] of uniqueHeaders) {
        typeList?.push({
          id: headerIdMap?.get(key),
          name: removePrefixes(removeUntilLastDoubleColon(key)),
          value: key,
        });
      }

      setCompensationTypeList(typeList);
      setSelectedCompType(typeList?.map((type) => type?.value));

      // const uniqueSubHeader = [];

      // subHeadersWithParent?.forEach((subHeader) => {
      //   if (subHeader?.includes(firstHeader?.id)) {
      //     uniqueSubHeader.push(subHeader);
      //   }
      // })
      // const formattedSubHeader = uniqueSubHeader?.map((header) => {
      //   const temp = mappingRes?.[header]
      //   ? mappingRes?.[header]?.name?.includes('_')
      //     ? mappingRes?.[header]?.name?.split('_')?.[1]
      //     : mappingRes?.[header]?.name
      //   : header;
      //   return {
      //     name: temp,
      //     value: temp,
      //   };
      // });
    }
  }, [selectedData?.id, selectedFilters, previewData]);

  useEffect(() => {
    const headers = [
      {
        colSpan: 1,
        columnName: 'Job Details',
        colBgCol: colorArrayShade2?.[9],
        id: '1',
      },
    ];
    ////
    // const mappingRes = res?.data?.mappings; // extract mapping from the response
    // const dataRes = res?.data?.data; // extract data from the response
    const allSUbHeaders = Object.keys(dataRes?.[0]?.uploadedJson ?? {}); // All subHeader
    const subHeadersWithParent = Object.keys(
      dataRes?.[0]?.uploadedJson ?? {},
    )?.filter(
      (key) =>
        mappingRes?.[key] && key?.includes('_') && isUUID(key?.split('_')?.[0]),
    ); // All header which Parents exist
    const subHeadersWithoutParent = Object.keys(
      dataRes?.[0]?.uploadedJson ?? {},
    )?.filter(
      (key) =>
        !mappingRes?.[key] ||
        !(key?.includes('_') && isUUID(key?.split('_')?.[0])),
    ); // All SubHeader which parent does not exits

    headers?.push({
      colSpan: allSUbHeaders?.length - subHeadersWithParent?.length - 1,
      columnName: '',
      colBgCol: colorArrayShade2?.[9],
      id: '2',
    }); // Pushing one header item without columnName of colSpan allSUbHeaders?.length - subHeadersWithParent?.length - 1
    const uniqueHeaders = new Map(); // Mapper for Unique Headers and their count
    const headerIdMap = new Map(); // Header Name to Id mapper
    subHeadersWithParent?.forEach((header) => {
      const nameWithoutBMUnderScoreAndPCUnderScore = splitAtLastUnderscore(
        mappingRes?.[header]?.name ?? '',
      )?.[0];
      const ele = nameWithoutBMUnderScoreAndPCUnderScore;
      if (uniqueHeaders?.has(ele)) {
        uniqueHeaders?.set(ele, uniqueHeaders?.get(ele) + 1);
      } else {
        uniqueHeaders?.set(ele, 1);
        headerIdMap?.set(ele, header?.split('_')?.[0]);
      }
    });

    let headersCnt = 0;
    for (let [key, value] of uniqueHeaders) {
      /// Constructing dynamic Header object
      if (selectedCompType?.includes(key)) {
        headers?.push({
          colSpan: value,
          columnName: removePrefixes(removeUntilLastDoubleColon(key)),
          colBgCol: colorArrayShade2?.[headersCnt % 9],
          id: headerIdMap?.get(key),
        });
        headersCnt++;
      }
    }

    const subHeadersWithoutParentWithColor = subHeadersWithoutParent?.map(
      (header) => {
        return {
          name: header,
          textColor: colorArrayShade1?.[9],
          bgColor: colorArrayShade4?.[9],
        };
      },
    ); // adding text and bg color property

    const newSubHeader = [...subHeadersWithoutParentWithColor];

    headers?.slice(2)?.forEach((header, index) => {
      subHeadersWithParent?.forEach((subHeader) => {
        if (subHeader?.includes(header?.id)) {
          newSubHeader.push({
            name: subHeader,
            textColor: colorArrayShade1?.[index % 9],
            bgColor: colorArrayShade4?.[index % 9],
          });
        }
      });
    }); // adding textColor and bgColor Property

    const tableData = dataRes?.map((ele) => {
      const dataArray = [];

      newSubHeader.forEach((subHeader) => {
        dataArray.push(ele?.uploadedJson?.[subHeader?.name]);
      });
      return {
        id: ele?.id,
        data: dataArray,
      };
    });
    setTableData(tableData);
    setBenchmarkWithColSpan(headers);
    const finalSubHeader = newSubHeader?.map((header, index) => {
      const nameSplitAtLastUnderscore = splitAtLastUnderscore(
        mappingRes?.[header?.name]?.name ?? '',
      );
      return {
        // name: mappingRes?.[header?.name] ? mappingRes?.[header?.name]?.name?.includes('_')? mappingRes?.[header?.name]?.name?.split('_')?.[1]: mappingRes?.[header?.name]?.name: header?.name,
        name:
          isUUID(header?.name?.split('_')?.[0]) && mappingRes?.[header?.name]
            ? nameSplitAtLastUnderscore?.[1]?.length > 1
              ? nameSplitAtLastUnderscore?.[1]
              : nameSplitAtLastUnderscore?.[0]
            : header?.name,
        value: header?.name,
        isFilter: index + 1 <= subHeadersWithoutParent?.length,
        textColor: header?.textColor,
        bgColor: header?.bgColor,
      };
    });
    setSubHeadings(finalSubHeader);
  }, [dataRes, mappingRes, selectedCompType]);

  useEffect(() => {
    refetchPreviewData();
  }, [selectedFilters, debouncedValue, pageSize]);

  const subHeaderWithoutParentLength = benchmarkWithColSpan?.[1]?.colSpan + 1;
  const uniqueSubHeaderWithParentLength = benchmarkWithColSpan?.[2]?.colSpan;

  return (
    <div className="bg-white h-full w-full">
      <div className="px-8 py-6 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div
            className="border border-gray-300 w-10 h-10 flex items-center justify-center rounded-lg shadow-sm cursor-pointer"
            onClick={() => setSelectedData(null)}
          >
            <ArrowLeftIcon className="w-5 h-5 text-gray-700" />
          </div>
          <div className="">
            <p className="font-semibold text-gray-900">{selectedData?.name}</p>
            <p className="text-gray-500 text-xs">{planName}</p>
          </div>
        </div>
      </div>
      {isPreviewDataLoading ? (
        <div
          className="flex w-full items-center justify-center"
          style={{
            height: 'calc(100vh - 195px)',
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <div className="space-y-4 pb-2.5">
            <div className={`flex items-center px-8 justify-end`}>
              <div className="flex items-center gap-5">
                <Search
                  searchedValue={searchedValue}
                  setSearchedValue={setSearchedValue}
                />
                <div className="flex items-center gap-[10px]">
                  <div className="relative">
                    <ArrowDownIcon
                      className="w-5 cursor-pointer h-5 text-gray-600 border-b border-gray-600"
                      onClick={() => {
                        if (!isDownloadDataLoading) {
                          const res = refetchDownloadExternalData();
                          downloadDataToCsv(res, `${planName}-JobMatchingData`);
                        }
                      }}
                    />
                    {isDownloadDataLoading && (
                      <div className="absolute -top-1 cursor-not-allowed">
                        <ButtonLoader />
                      </div>
                    )}
                  </div>
                  <ArrowUpIcon
                    className="w-5 h-5 cursor-pointer text-gray-600 border-t border-gray-600"
                    onClick={() => {
                      setIsUpdateReplaceMatching(true);
                      setActionType(ACTION_TYPE.Update);
                    }}
                  />
                  <TabularIcon className="cursor-pointer m-1" />
                </div>
              </div>
            </div>
            {selectedFilters?.length > 0 && (
              <div className={`flex items-center px-8`}>
                <div className="flex items-center gap-2.5">
                  {selectedFilters?.map((filter, index) => {
                    return (
                      <div
                        className="flex gap-2 px-2 text-sm font-medium gradient-border-and-text before:rounded-2xl before:border"
                        key={index}
                        onClick={() => {
                          setSelectedFilters(
                            selectedFilters?.filter(
                              (data) => data?.key !== filter?.key,
                            ),
                          );
                        }}
                      >
                        {filter?.value}{' '}
                        <span className="cursor-pointer">X</span>
                      </div>
                    );
                  })}
                  {selectedFilters?.length > 0 && (
                    <div
                      className="flex text-sm font-semibold text-gray-600 cursor-pointer"
                      onClick={() => {
                        setSelectedFilters([]);
                      }}
                    >
                      Clear All
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="border-y bg-gray-50 border-gray-200 px-8 py-1 flex items-center justify-between">
              <div className="flex items-center">
                <CompensationTypeFilter
                  typeList={compensationTypeList}
                  selectedType={selectedCompType}
                  setSelectedType={setSelectedCompType}
                />
                {/* <p className="text-gray-300 px-4">|</p> */}
              </div>
              {/* <CompensationUnitFilter
          isLakhsCompUnit={isLakhsCompUnit}
          setIsLakhsCompUnit={setIsLakhsCompUnit}
          /> */}
            </div>
          </div>
          <div className="flex bg-white w-full h-full px-8">
            <div className="rangeBuilder w-full h-fit relative">
              {isPreviewDataRefetching && (
                <div className="absolute z-100 left-1/2 top-1/2">
                  <div>
                    <Loader />
                  </div>
                </div>
              )}
              {/* toDo:: tableData?.length<1 show no data available */}
              <RangeTable
                benchmarkWithColSpan={benchmarkWithColSpan}
                subHeadings={subHeadings}
                tableData={tableData}
                dropDownFilters={filtersData}
                setSelectedFilters={(filter) => {
                  setSelectedFilters(filter);
                }}
                selectedFilters={selectedFilters}
                isViewDetailsPopUp={isViewDetailsPopUp}
                setIsViewDetailsPopUp={setIsViewDetailsPopUp}
                handleDelete={async (selectedId) => {
                  const data = {
                    rangeFormTypeId: selectedData?.id,
                    payload: {
                      dataIds: selectedId,
                    },
                  };
                  deleteExternalData(data)
                    .then((res) => {
                      onResponse('Deleted Successfully!');
                      refetchPreviewData();
                    })
                    .catch((err) => {
                      onResponse('Delete failed');
                    });
                }}
                selectedRowId={selectedRowId}
                setSelectedRowId={setSelectedRowId}
                setPageSize={setPageSize}
                isRefetchingTableData={isPreviewDataRefetching}
                maxRowCount={100} //ToDo:: needs to be changed by apiResponse once count included in api response
              />
            </div>
          </div>
          {isViewDetailsPopUp && (
            <ViewDetailsPopUp
              mappingRes={mappingRes}
              setIsViewDetailsPopUp={setIsViewDetailsPopUp}
              rangeFormTypeId={selectedData?.id}
              isViewDetailsPopUp={isViewDetailsPopUp}
              columns={[
                { name: 'Position', value: 'Position' },
                ...(subHeadings?.slice(
                  0,
                  benchmarkWithColSpan?.[1]?.colSpan +
                    1 +
                    benchmarkWithColSpan?.[2]?.colSpan,
                ) ?? []),
              ]}
              data={benchmarkWithColSpan
                ?.filter((data, index) => index > 1)
                ?.map((col, ind) => {
                  const detailsData = isViewDetailsPopUp?.data ?? [];
                  return {
                    id: col?.id,
                    data: [
                      col?.columnName,
                      ...(detailsData?.filter(
                        (data, i) => i < subHeaderWithoutParentLength,
                      ) ?? []),
                      ...(detailsData?.filter(
                        (data, i) =>
                          i >=
                            subHeaderWithoutParentLength +
                              ind * uniqueSubHeaderWithParentLength &&
                          i <
                            subHeaderWithoutParentLength +
                              (ind + 1) * uniqueSubHeaderWithParentLength,
                      ) ?? []),
                    ],
                  };
                })}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ViewExternalData;
