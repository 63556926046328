import {
  getAllInputFields,
  getInputFieldCriteria,
  getInputFieldValues,
} from 'apiClient/compensation/compensation';
import Select from 'components/atoms/FormElements/select/Select';
import { useCallback, useEffect, useRef, useState } from 'react';
import { QueryBuilder } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import { queryBuilderFormatterForAPI } from './queryBuilderUtils';

export default function QueryMaker() {
  const [fields, setFields] = useState([]);
  const [operators, setOperators] = useState([]);
  const [valueOptions, setValueOptions] = useState({});
  const [query, setQuery] = useState({
    combinator: 'and',
    rules: [],
  });

  const fetchedFieldsRef = useRef(new Set());
  useEffect(() => {
    const newFetchedFields = new Set(fetchedFieldsRef.current);

    query.rules.forEach(({ field }) => {
      if (field && !valueOptions[field] && !newFetchedFields.has(field)) {
        newFetchedFields.add(field);
        fetchValues(field);
      }
    });

    fetchedFieldsRef.current = newFetchedFields;
  }, [query.rules, valueOptions]);

  const fetchFields = () => {
    getAllInputFields()
      .then((res) => {
        setFields(res);
      })
      .catch((err) => console.log(err));
  };

  const fetchOperators = () => {
    getInputFieldCriteria('datatype', 'All?map=1')
      .then((res) => {
        setOperators(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchValues = (fieldName) => {
    //    const field = fields.find((f) => f.id === fieldName);
    if (fieldName) {
      getInputFieldValues(fieldName)
        .then((res) => {
          setValueOptions((prev) => ({
            ...prev,
            [fieldName]: res?.data,
          }));
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    fetchFields();
    fetchOperators();
  }, []);

  const formatFields = (data) => {
    return data.map((field) => ({
      ...field,
      label: field?.alias || field?.value,
      name: field?.id,
      value: field?.id,
      valueSources: ['field', 'value'],
    }));
  };
  const formatValueOptions = (data) => {
    return data.map((field) => ({
      ...field,
      label: field?.alias || field?.value,
      value: field?.id,
    }));
  };

  const formatOperators = (data) => {
    return data.map((field) => ({
      ...field,
      label: field?.displayName,
      name: field?.code,
    }));
  };

  const getFilteredOperators = (dataType) => {
    return operators[dataType] || [];
  };

  const handleQueryChange = useCallback((newQuery) => {
    setQuery(newQuery);
  }, []);
  console.log('query', query, queryBuilderFormatterForAPI(query));

  const CustomValueEditor = (props) => {
    const { field, operator, value, valueSource, handleOnChange } = props;

    const fieldData = fields.find((f) => f.id === field);
    const options = valueOptions[field] || [];
    // console.log('customValueEditor:', props, options);
    if (fieldData?.dataType === 'multioption') {
      //   return (
      //     <select value={value} onChange={(e) => handleOnChange(e.target.value)}>
      //       {options.map((opt) => (
      //         <option key={opt.value} value={opt.value}>
      //           {opt.label}
      //         </option>
      //       ))}
      //     </select>
      //   );
    }

    // if (fieldData?.dataType === 'date') {
    //   return (
    //     <input
    //       type="date"
    //       value={value}
    //       onChange={(e) => handleOnChange(e.target.value)}
    //     />
    //   );
    // }

    // return (
    //   <input
    //     type="text"
    //     value={value}
    //     onChange={(e) => handleOnChange(e.target.value)}
    //   />
    // );

    if (valueSource === 'field') {
      return (
        <Select
          value={{ label: value?.label, value: value?.id }}
          options={formatFields(fields)}
          onChange={(e) => handleOnChange(e)}
        />
      );
    } else if (options?.length > 0) {
      return (
        <Select
          value={{ label: value?.label, value: value?.id }}
          options={formatValueOptions(options)}
          onChange={(e) => handleOnChange(e)}
        />
        // <select value={value} onChange={(e) => handleOnChange(e.target.value)}>
        //   {options.map((opt) => (
        //     <option key={opt.value} value={opt.value}>
        //       {opt.label}
        //     </option>
        //   ))}
        // </select>
      );
    } else {
      return (
        <input
          type="text"
          value={value}
          onChange={(e) => handleOnChange(e.target.value)}
        />
      );
    }
  };

  return (
    <div>
      <QueryBuilder
        showCloneButtons
        showLockButtons
        showShiftActions
        showNotToggle
        controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
        query={query}
        onQueryChange={handleQueryChange}
        fields={formatFields(fields)}
        getOperators={(fieldName) => {
          const field = fields.find((f) => f.id === fieldName);
          if (field) {
            const filteredOperators = getFilteredOperators(field.dataType);
            if (filteredOperators.length > 0) {
              return formatOperators(filteredOperators);
            }
          }
          return [];
        }}
        controlElements={{
          valueEditor: (props) => CustomValueEditor(props),
        }}
      />
    </div>
  );
}
