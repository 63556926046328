import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import PopUp from 'components/atoms/popUp/PopUp';

export const PlanPopUp = ({ setPopUpVisible, setArchivePopUpVisible }) => {
  return (
    <PopUp right="5" closePopup={() => setPopUpVisible(false)}>
      <div className="w-64 p-2 px-4 space-y-2">
        <p
          className="cursor-pointer flex items-center text-sm font-medium text-gray-700 gap-2 py-1"
          onClick={() => setArchivePopUpVisible(true)}
        >
          <ArchiveBoxIcon className="w-5 h-5" />
          Archive
        </p>
      </div>
    </PopUp>
  );
};
