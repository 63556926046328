import { TrashIcon } from '@heroicons/react/24/outline';
import MoveIcon from 'assets/svg/moveIcon';
import Chart from 'components/molecules/chart/ChartComponent';
import ModalDialogue from 'components/atoms/modalDialogue/ModalDialogue';
import HeatmapChart from 'components/molecules/nivo/charts/HeatMapChart';
import { tableData } from 'pages/dashboard/createDashboard/Configuration/DashboadConfiguration/Chartlist';
import React, { useEffect, useState } from 'react';
import GridLayout from 'react-grid-layout';

const DashboardContent = ({ dashboardSettings, layout }) => {
  const [layoutData, setLayoutData] = useState([]);
  const [selectedChart, setSelectedChart] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const selectedPageData = layout.find((item) => item?.pageIndex === 1);
    setLayoutData(selectedPageData?.data);
  }, [layout]);

  const renderCards = () => {
    return layoutData?.map((card, index) => {
      if (card?.data?.type === 'separator') {
        return (
          <div
            key={card?.i}
            className={`rounded h-full flex flex-col justify-between w-full p-2 `}
          >
            {/* <div className="flex items-center gap-2.5 self-end pb-2">
                <div className="drag-handle cursor-move">
                  <MoveIcon />
                </div>
                <div
                  className="cursor-pointer"
                >
                  <TrashIcon className="w-6 h-6 text-gray-400" />
                </div>
              </div> */}
            <div className=" bg-black h-1 w-full"></div>
          </div>
        );
      } else if (card?.data?.type === 'text') {
        return (
          <div
            key={card?.i}
            className={`rounded flex h-full w-full flex-col justify-between p-2 min-w-24 `}
          >
            <div
              className="break-words text-wrap"
              style={{
                color: card?.data?.fontColor ? card?.data?.fontColor : 'black',
                fontSize: card?.data?.fontSize
                  ? `${card?.data?.fontSize}px`
                  : '16px',
                fontFamily: card?.data?.fontFamily
                  ? card?.data?.fontFamily
                  : 'Arial',
              }}
            >
              {card?.data?.description || 'Edit Text'}
            </div>
          </div>
        );
      } else if (card?.data?.type === 'table') {
        return (
          <div
            key={card?.i}
            className={` bg-white shadow rounded flex flex-col`}
          >
            <div className="px-4 pt-4 pb-1 flex items-center justify-between">
              <span className="text-base font-semibold text-black">
                {card?.data?.title || 'Edit Title'}
              </span>
            </div>
            <span className="text-sm font-medium text-gray-500 px-4 pb-4">
              {card?.data?.description || 'Edit Description'}
            </span>
            <div className="bg-white py-1 px-4 overflow-auto min-w-28">
              <table className="items-center w-full border-collapse text-blueGray-700">
                <thead className="thead-light">
                  <tr>
                    <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase  font-semibold text-left">
                      Program Name
                    </th>
                    <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase font-semibold text-left">
                      Program Status
                    </th>
                    <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase  font-semibold text-left">
                      Program Owner
                    </th>
                    <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase font-semibold text-left">
                      Total Budget
                    </th>
                    <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase font-semibold text-left">
                      Utilized
                    </th>
                    <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase font-semibold text-left">
                      Balance Amount
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm font-normal">
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="border px-6 align-middle text-xs py-4">
                        {row.programName}
                      </td>
                      <td className="border px-6 align-middle text-xs py-4">
                        {row.programStatus}
                      </td>
                      <td className="border px-6 align-middle text-xs py-4">
                        {row.programOwner}
                      </td>
                      <td className="border px-6 align-middle text-xs py-4">
                        {row.totalBudget}
                      </td>
                      <td className="border px-6 align-middle text-xs py-4">
                        {row.utilized}
                      </td>
                      <td className="border px-6 align-middle text-xs py-4">
                        {row.balanceAmount}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        );
      } else if (card?.data?.type === 'heatmap') {
        return (
          <div
            key={card?.i}
            className={` bg-white shadow rounded flex flex-col`}
          >
            <div className="px-4 pt-4 pb-1 flex items-center justify-between">
              <span className="text-base font-semibold text-black">
                {card?.data?.title}
              </span>
            </div>
            <span className="text-sm font-medium text-gray-500 px-4 pb-4">
              {card?.data?.description}
            </span>
            {card?.data?.chartData && (
              <div className="h-full px-4 pb-4">
                <HeatmapChart
                  keys={card?.data?.chartData?.labels}
                  data={card?.data?.chartData?.datasets}
                />
              </div>
            )}
          </div>
        );
      } else {
        return (
          <div
            key={card?.i}
            className={`flex flex-col bg-white shadow rounded min-h-60 min-w-52`}
          >
            <div className="px-4 pt-4 pb-1 flex items-center justify-between">
              <span className="text-base font-semibold text-black">
                {card?.data?.title}
              </span>
            </div>
            <span className="text-sm font-medium text-gray-500 px-4 pb-4">
              {card?.data?.description}
            </span>
            {card?.data?.chartData && (
              <div className="h-full px-4 pb-4 ">
                <Chart
                  displayLegend={true}
                  chartOptions={card?.data?.chartData?.chartOptions || null}
                  type={card?.data?.type}
                  data={card?.data?.chartData}
                />
              </div>
            )}
          </div>
        );
      }
    });
  };

  return (
    <div className="border bg-white shadow pt-4 rounded-lg flex flex-col gap-4">
      <div className="px-6">
        <span className="text-base text-gray-900 font-semibold">
          {dashboardSettings?.settings?.title}
        </span>
      </div>
      <hr className="w-full h-1"></hr>
      <div className="h-[calc(100vh_-_280px)] overflow-auto -mt-5 rounded-b-lg">
        <div
          style={{
            backgroundColor: dashboardSettings?.settings?.backgroundColor,
          }}
          className={`min-h-full h-max px-6 py-6`}
        >
          <GridLayout
            className="layout"
            layout={layoutData}
            cols={12}
            rowHeight={20}
            margin={[
              dashboardSettings?.settings?.spacing,
              dashboardSettings?.settings?.spacing,
            ]}
            width={document.documentElement.clientWidth - 155}
            height="100%"
            // onLayoutChange={handleLayoutChange}
            draggableHandle=".drag-handle"
            resizeHandles={['se']}
            autoSize={true}
          >
            {renderCards()}
          </GridLayout>
          {/* {selectedChart ? (
            <ModalDialogue
              title={selectedChart?.data?.title}
              onClose={() => {
                setIsModalOpen(false);
                setSelectedChart(null);
              }}
              isModalOpen={isModalOpen}
              width="70%"
              height="800px"
            >
              <div className="h-full">
                {selectedChart?.data?.type === 'heatmap' ? (
                  <div className="h-full px-4 pb-4">
                    <HeatmapChart
                      keys={selectedChart?.data?.chartData?.labels}
                      data={selectedChart?.data?.chartData?.datasets}
                    />
                  </div>
                ) : (
                  <Chart
                    displayLegend={true}
                    chartOptions={
                      selectedChart?.data?.chartData?.chartOptions || null
                    }
                    type={selectedChart?.data?.type}
                    data={selectedChart?.data?.chartData}
                  />
                )}
              </div>
            </ModalDialogue>
          ) : null} */}
        </div>
      </div>
    </div>
  );
};

export default DashboardContent;
