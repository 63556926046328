import TickIcon from 'assets/svg/tickIcon';
import React from 'react';

const Input = React.forwardRef(
  (
    {
      label,
      error,
      type,
      required,
      placeholder,
      className,
      readonly,
      min,
      max,
      labelClassName,
      showPercentIcon,
      showCurrency,

      ...props
    },
    ref,
  ) => (
    <div className="w-full">
      {label && (
        <label
          htmlFor={props.id}
          className={`text-sm font-medium leading-5 text-gray-700 my-1 mb-[6px] ${labelClassName}`}
        >
          {label} {required && <span className="text-red-600">*</span>}
        </label>
      )}
      <div className="flex">
        {showCurrency && (
          <span className="inline-flex items-center px-3  w-8 text-sm text-gray-900 bg-gray-100 border border-r-0 border-gray-300 rounded-l-md">
            {showCurrency}
          </span>
        )}
        <input
          className={`w-full px-[14px] py-[10px] ${
            props.disabled ? 'cursor-not-allowed' : 'cursor-auto'
          } text-gray-500 border ${
            showPercentIcon ? 'rounded-r-none border-r-0' : 'rounded-lg'
          } ${showCurrency ? 'rounded-l-none border-l-0' : 'rounded-lg'} ${
            error ? 'border-red-600' : 'border-gray-300'
          }  outline-none ${className}`}
          placeholder={placeholder}
          ref={ref}
          readOnly={readonly ? readonly : false}
          type={type}
          onWheel={(event) => event.target.blur()}
          min={min}
          max={max}
          {...props}
        />
        {showPercentIcon && (
          <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-100 border border-l-0 border-gray-300 rounded-r-md">
            %
          </span>
        )}
      </div>
      {error && (
        <p className="mt-1 text-red-500 rounded text-sm">{error?.message}</p>
      )}
    </div>
  ),
);
const Checkbox = React.forwardRef(
  ({ label, error, type, required, ...register }, ref) => (
    <>
      <div className="flex items-center gap-2 ">
        <input
          className={`w-4 h-4 border border-gray-300 rounded appearance-none cursor-pointer checked:bg-blue-600 checked:border-blue-600 ${
            error ? 'border-red-600' : ''
          }`}
          {...register}
          ref={ref}
          type={type}
        />
        <label
          htmlFor={register.id}
          className="text-sm font-medium leading-5 text-gray-700"
        >
          {label}
        </label>
      </div>
      {error && <p className="p-2   text-red-500 rounded">{error}</p>}
    </>
  ),
);
// const CheckBoxProgram = ({ label, error, value, name, onChange }) => {
const CheckBoxProgram = ({
  label,
  error,
  id,
  checked,
  icon,
  disabled,
  ...rest
}) => {
  // const handleOnChange = (e) => {
  //   onChange(e.target.checked);
  // };

  return (
    <>
      <label htmlFor={id} className="w-fit flex items-center">
        <input
          id={id}
          type="checkbox"
          className="checked:accent-primaryLeft invisible"
          checked={checked}
          disabled={disabled}
          {...rest}
        />
        <span
          className={`${
            disabled && '!bg-gray-100'
          } border cursor-pointer border-gray-300 h-5 w-5 rounded flex items-center justify-center -ml-3 z-30 ${
            checked && 'bg-gradient-to-br from-primaryLeft to-primaryRight '
          } ${disabled && 'opacity-35 '} `}
        >
          {checked && (icon || <TickIcon />)}
        </span>
        {label && <span className="px-2 text-sm text-gray-600">{label}</span>}
      </label>
      {error && (
        <p className="p-2 text-red-500 rounded">{error || error?.message}</p>
      )}
    </>
  );
};

const RadioButton = React.forwardRef(
  ({ alignment, label, id, checked, disabled, ...rest }, ref) => {
    return (
      <div
        onClick={() => ref?.current?.click()}
        className={`flex items-center ${
          alignment ? alignment : 'justify-start'
        }`}
      >
        <input
          id={id}
          type="radio"
          className="relative z-50 mr-1 cursor-pointer opacity-0"
          ref={ref}
          disabled={disabled}
          checked={checked}
          {...rest}
        />
        <label
          htmlFor={id}
          className="text-sm px-1 text-gray-600 flex gap-2 cursor-pointer items-center relative"
        >
          <span
            className={`${
              checked ? 'border-[var(--midColor)]' : 'border-slate-300'
            } border h-4 w-4 rounded-full transition absolute cursor-pointer left-0  ${
              disabled ? 'border-gray-300 ' : ''
            }`}
          >
            <span
              className={`${checked ? 'bg-[var(--midColor)]' : ''} ${
                disabled ? 'bg-gray-300' : ''
              } h-2 w-2 inline-block rounded-full transition-all absolute top-1/2 left-1/2 `}
              style={{
                transform: 'translate(-50%, -50%)',
              }}
            ></span>
          </span>

          <span className="ml-5">{label}</span>
        </label>
      </div>
    );
  },
);

export { CheckBoxProgram, Checkbox, Input, RadioButton };
