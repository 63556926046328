import { del, get, post } from 'apiClient/apiCalls';
import { AxiosError } from 'axios';
import * as CONSTANT from '../api.constant';

export const fetchAllPlans = async (type, searchedValue, pageIndex) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/range-plan/?skip=${
        pageIndex ? `${pageIndex}` : '0'
      }&take=10&status=${type}${searchedValue ? `&s=${searchedValue}` : ''}`,
    );

    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Plans fetch failed');
    }
  }
};

export const createPlan = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/range-plan`,
      body,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Create plan failed');
    }
  }
};

export const editPlan = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/range-plan/update`,
      body,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Create plan failed');
    }
  }
};

export const fetchPayStructureColumns = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/range-plan/get-pay-structure-columns`,
    );
    // const response = {
    //   status: 200,
    //   message: 'Ok',
    //   data: [
    //     {
    //       id: '607f19df-597d-477a-9d44-0cd2129abf75',
    //       columnName: 'Equity',
    //       description: 'Equity',
    //       isActive: true,
    //     },
    //     {
    //       id: 'fa7a9e69-cd6d-4b5a-b22b-afe0b99cbe4a',
    //       columnName: 'Base Pay',
    //       description: 'Base Pay',
    //       isActive: true,
    //     },
    //   ],
    // };
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Pay structure columns fetch failed');
    }
  }
};

export const createFixedPValues = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/range-plan/create-fixed-p-values`,
      body,
    );

    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Fixed P values create failed');
    }
  }
};

export const fetchFixedPValues = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/range-plan/get-fixed-p-values`,
    );

    // const response = {
    //   status: 200,
    //   message: 'Ok',
    //   data: [
    //     {
    //       id: '48b86e67-8a84-45bd-ba42-d4b6fdd50f0f',
    //       name: 'P25',
    //       value: 0.25,
    //     },
    //     {
    //       id: '629d6bfb-baa0-42b3-9aa3-b1527390ab29',
    //       name: 'P50',
    //       value: 0.5,
    //     },
    //   ],
    // };
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Fixed P values fetch failed');
    }
  }
};

export const fetchCustomPValues = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/range-plan/get-custom-p-values`,
    );

    // const response = {
    //   status: 200,
    //   message: 'Ok',
    //   data: [
    //     {
    //       id: '48b86e67-8a84-45bd-ba42-d4b6fdd50f0f',
    //       name: 'P25',
    //       value: 0.25,
    //     },
    //     {
    //       id: '629d6bfb-baa0-42b3-9aa3-b1527390ab29',
    //       name: 'P50',
    //       value: 0.5,
    //     },
    //   ],
    // };
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Custom P values fetch failed');
    }
  }
};

export const createCustomPValues = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/range-plan/create-custom-p-values`,
      body,
    );

    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Custom P values fetch failed');
    }
  }
};

export const downloadTemplate = async (planId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/range-plan/template/${planId}`,
    );

    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! download template failed');
    }
  }
};

export const getCalculationInternalRange = async (
  planId,
  searchedValue,
  filter,
) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${
        CONSTANT.API_RANGE_BUILDER_V3
      }/internal-range/get-calculations/${planId}?skip=0&take=1000${
        searchedValue ? `&s=${searchedValue}` : ''
      }`,
      filter ? { filter } : {},
    );

    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! download template failed');
    }
  }
};

export const fetchPlanById = async (planId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/range-plan/${planId}`,
    );

    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! download template failed');
    }
  }
};

export const fetchBenchmarkColumns = async (planId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/range-plan/get-benchmark-columns/${planId}`,
    );

    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! download template failed');
    }
  }
};

export const downloadData = async (planId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/internal-range/download-calculations/${planId}`,
    );

    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! download template failed');
    }
  }
};

export const uploadInternalData = async (body, planId) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/internal-range/upload-csv/${planId}`,
      body,
      'multipart/form-data',
    );

    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Custom P values fetch failed');
    }
  }
};

export const deleteInternalRangeRole = async (body) => {
  try {
    const response = await del(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/internal-range/delete-calculation`,
      body,
    );

    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! download template failed');
    }
  }
};

export const getInternalFilters = async (planId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/internal-range/get-filters/${planId}`,
    );

    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! download template failed');
    }
  }
};

export const archiveBenchmarkPlan = async (planId) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/range-plan/archive-plan/${planId}`,
      {},
    );

    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! download template failed');
    }
  }
};

export const fetchVersionHistory = async (roleId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/internal-range/calculation-history/${roleId}?skip=0&take=10`,
    );

    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! download template failed');
    }
  }
};

export const addRole = async (planId, body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/internal-range/add-role/${planId}`,
      body,
    );

    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! download template failed');
    }
  }
};

//-------------------------------EXTERNAL DATA APIS---------------------------------
export const getAllExternalRanges = async (
  planId,
  pageSize,
  pageIndex,
  query,
) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/external-range/ranges/${planId}`;
    if (pageSize) {
      url += `?pageSize=${pageSize}&pageIndex=${pageIndex}&s=${query}`;
    } else {
      url += `?s=${query}`;
    }
    const response = await get(url);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Get all ranges failed');
    }
  }
};

export const deleteExternalRanges = async (id) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/external-range/range/${id}`;
    const response = await del(url);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Get all ranges failed');
    }
  }
};

export const getTemplate = async (planId) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/external-range/template/${planId}`;
    const response = await get(url);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Get all ranges failed');
    }
  }
};

export const getUpdateTemplate = async (rangeFormTypeId) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/external-range/template/${rangeFormTypeId}?type=update`;
    const response = await get(url);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Get all ranges failed');
    }
  }
};

export const uploadExternalDataCsv = async (planId, formData) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/external-range/csv/${planId}`;
    const response = await post(url, formData, 'multipart/form-data');
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! External Data Upload failed');
    }
  }
};

export const replaceExternalDataCsv = async (rangeFormId, formData) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/external-range/csv/reset/${rangeFormId}`;
    const response = await post(url, formData, 'multipart/form-data');
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! External Data Upload failed');
    }
  }
};

export const updateExternalDataCsv = async (rangeFormId, formData) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/external-range/csv/data/update/${rangeFormId}`;
    const response = await post(url, formData, 'multipart/form-data');
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! External Data Upload failed');
    }
  }
};

export const getCsvHeadersAndData = async (planId, formData) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/external-range/csv/data/${planId}`;
    const response = await post(url, formData, 'multipart/form-data');
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! External Data Upload failed');
    }
  }
};

export const validateCsvData = async (planId, formData) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/external-range/csv/validate/${planId}`;
    const response = await post(url, formData, 'multipart/form-data');
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! External Data Upload failed');
    }
  }
};

export const getPreviewData = async (
  rangeFormTypeId,
  pageSize,
  pageIndex,
  selectedFilters,
  query,
) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/external-range/data/${rangeFormTypeId}?s=${query}`;
    if (pageSize) {
      url += `&pageSize=${pageSize}&pageIndex=${pageIndex}`;
    }
    const response = await post(url, { filter: selectedFilters });
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! External Data Upload failed');
    }
  }
};

export const downloadExternalData = async (rangeFormTypeId) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/external-range/data/download/${rangeFormTypeId}`;
    const response = await post(url);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Download External Data Upload failed');
    }
  }
};

export const updateDetailsOfCsv = async (rangeFormTypeId, payload) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/external-range/details/final/${rangeFormTypeId}`;
    const response = await post(url, payload);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! External Data Upload failed');
    }
  }
};

export const getExternalDataFilters = async (rangeFormTypeId) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/external-range/filters/${rangeFormTypeId}`;
    const response = await get(url);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! External Data filters failed');
    }
  }
};

// ######################### FINAL RANGES ###########################

export const getFinalRangesGraphData = async (
  planId,
  pageSize,
  pageIndex,
  query,
) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/final-range/get-calculations/${planId}?s=${query}`;
    if (pageSize) {
      url += `&skip=${pageIndex}&take=${pageSize}`;
    }
    const response = await post(url);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Get final ranges calculation failed.');
    }
  }
};

export const getVersionHistory = async (rangeFormTypeId, rangeDataId) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/external-range/history/${rangeFormTypeId}/${rangeDataId}`;
    const response = await get(url);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Version history failed');
    }
  }
};

export const deleteExternalRangeData = async (rangeFormTypeId, payload) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/external-range/data/${rangeFormTypeId}`;
    const response = await del(url, payload);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Delete External Data Upload failed');
    }
  }
};

//-------------------------------JOB MATCHING APIS---------------------------------

export const downloadJobMatchingTemplate = async (planId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/job-matching/template/${planId}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Job Matching template download failed!');
    }
  }
};

export const uploadJobMatchingData = async (planId, type, body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/job-matching/csv/validate/${planId}?type=${type}`,
      body,
      'multipart/form-data',
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Job Matching template download failed!');
    }
  }
};

export const getJobMatchingData = async (
  planId,
  pageSize,
  pageIndex,
  selectedFilters,
  query,
) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/job-matching/data/${planId}?s=${query}`;
    if (pageSize) {
      url += `&pageSize=${pageSize}&pageIndex=${pageIndex}`;
    }
    const response = await post(url, { filter: selectedFilters });
    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Job Matching template download failed!');
    }
  }
};

export const downloadJobMatchingData = async (planId) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/job-matching/data/download/${planId}`;
    const response = await get(url);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Download Job Matching Data Upload failed');
    }
  }
};

export const getJobMatchingFilter = async (planId) => {
  try {
    const url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/job-matching/filters/${planId}`;
    const response = await get(url);
    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Job Matching filter failed!');
    }
  }
};

export const getJobMatchingVersionHistory = async (
  rangeFormTypeId,
  rangeDataId,
) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/job-matching/history/${rangeFormTypeId}/${rangeDataId}`;
    const response = await get(url);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Version history failed');
    }
  }
};

export const deleteJobMatchingData = async (formId, payload) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/job-matching/data/${formId}`;
    const response = await del(url, payload);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Delete Job Matching failed');
    }
  }
};

export const getAgingFrequencyDropDownValue = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/job-matching/aging/frequencies`,
    );
    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Aging frequencies drop down value failed!');
    }
  }
};

export const downloadAgingTemplate = async (planId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/job-matching/template/aging/${planId}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! aging template download failed!');
    }
  }
};

export const agingValidateCsvData = async (planId, formData) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/job-matching/csv/validate/aging/${planId}`;
    const response = await post(url, formData, 'multipart/form-data');
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Aging Upload failed');
    }
  }
};

export const getAgingConfig = async (planId, rangeDataId) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/job-matching/aging/config/${planId}/${rangeDataId}`;
    const response = await get(url);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Aging config failed');
    }
  }
};

export const individualAgingUpload = async (planId, payload) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/job-matching/aging/${planId}`;
    const response = await post(url, payload);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Individual Aging upload failed');
    }
  }
};

//-------------------------------FINAL RANGE APIS---------------------------------

export const getfinalRangeData = async (planId, searchedValue, filter) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${
        CONSTANT.API_RANGE_BUILDER_V3
      }/final-range/get-calculations/${planId}${
        searchedValue ? `&s=${searchedValue}` : ''
      }`,
      filter ? { filter } : {},
    );
    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Job Matching template download failed!');
    }
  }
};

export const getfinalRangeEmployeesData = async (
  planId,
  searchedValue,
  body,
) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/final-range/get-employees/${planId}?s=${searchedValue}`,
      body,
    );
    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Job Matching template download failed!');
    }
  }
};

export const getVersionHistoryFinalRange = async (rowId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/final-range/get-history/${rowId}`,
    );
    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Version History fetch failed');
    }
  }
};

export const downloadFinalRangeData = async (planId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/final-range/download-calculations/${planId}`,
    );

    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! download template failed');
    }
  }
};

export const getFinalRangeBenchmarks = async (planId, rowId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/final-range/get-benchmarks/${planId}/${rowId}`,
    );

    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! download template failed');
    }
  }
};

export const deleteFinalRangeCalculation = async (body) => {
  try {
    const response = await del(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/final-range/delete-calculation`,
      body,
    );

    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! download template failed');
    }
  }
};

export const getFinalRangeFilters = async (planId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/final-range/get-filters/${planId}`,
    );

    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! download template failed');
    }
  }
};

export const getFinalRangeSources = async (planId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/final-range/get-sources/${planId}`,
    );

    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! download template failed');
    }
  }
};

export const getJobCode = async (planId, sourceId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/final-range/get-job-codes-from-source/${planId}/${sourceId}`,
    );

    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! download template failed');
    }
  }
};

export const getFinalBenchmark = async (planId, rowId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/final-range/get-benchmarks/${planId}/${rowId}`,
    );

    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! download template failed');
    }
  }
};

export const updateFinalCalculation = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/final-range/update-final-range-data`,
      body,
    );

    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! download template failed');
    }
  }
};

// ---------------------------- Employees api -----------------------------

export const getEmployeesData = async (
  planId,
  pageSize,
  pageIndex,
  status,
  query,
) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/employees/${planId}`;
    if (pageSize) {
      url += `?take=${pageSize}&skip=${pageIndex}`;
    }
    if (status && pageSize) {
      url += `&status=${status}`;
    } else if (status) {
      url += `?status=${status}`;
    }
    if (pageSize || status) {
      url += `&s=${query}`;
    } else {
      url += `?s=${query}`;
    }
    const response = await get(url);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Get employee data failed.');
    }
  }
};

export const getEmployeesDataFilter = async (planId, benchmarkId) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/employees/get-filter-values/${planId}?benchmarkId=${benchmarkId}`;
    const response = await get(url);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Get employee data filter failed.');
    }
  }
};

export const getEmployeesDataByCategory = async (
  planId,
  benchmarkId,
  pageSize,
  pageIndex,
  category,
  query,
) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RANGE_BUILDER_V3}/employees/get-filter-values/${planId}?benchmarkId=${benchmarkId}`;
    if (pageSize) {
      url += `&take=${pageSize}&skip=${pageIndex}`;
    }
    if (category) {
      url += `&category=${category}`;
    }
    if (query) {
      url += `&s=${query}`;
    }
    const response = await get(url);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Get employee data by category failed.');
    }
  }
};
